import CustomError from '@shared/lib/customError';
export const validate = (data) => {
    for (const [key, value] of Object.entries(data)) {
        const hasMessage = !(value === null || value === void 0 ? void 0 : value.length) && key === 'message';
        const hasRating = !value && key === 'rating';
        if (hasMessage) {
            throw new CustomError(key, 'Напишите отзыв');
        }
        if (hasRating) {
            throw new CustomError(key, 'Поставьте оценку');
        }
    }
};
