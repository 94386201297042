import { AgencyOrder } from '@entities/agencies';
import { AgenciesMetricHashes } from '@shared/config/metrics';
export const sortOptions = [
    {
        title: 'Больше всего сделок',
        value: AgencyOrder.TopDeals
    },
    {
        title: 'Больше всего отзывов',
        value: AgencyOrder.TopReviews
    },
    {
        title: 'Лучший рейтинг',
        value: AgencyOrder.TopRating
    }
];
export const SortElementId = {
    [AgencyOrder.TopDeals]: AgenciesMetricHashes.ClickSortTopDeals,
    [AgencyOrder.TopReviews]: AgenciesMetricHashes.ClickSortTopReviews,
    [AgencyOrder.TopRating]: AgenciesMetricHashes.ClickSortTopRating
};
