export const Periods = [
    {
        label: 'За всё время',
        value: 'all'
    },
    {
        label: 'За 12 месяцев',
        value: 'year'
    }
];
