export const getIntersectionObserver = (callback, options = {}) => {
    const observerOptions = {
        root: options.root || null,
        rootMargin: options.rootMargin || '0px',
        threshold: options.threshold || 0
    };
    const observerCallback = (entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                callback();
                observer.unobserve(entry.target);
            }
        });
    };
    return new IntersectionObserver(observerCallback, observerOptions);
};
