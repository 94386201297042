import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelStandardRegular } from '@front-core/typography';
import { clsx } from 'clsx';
import { memo } from 'react';
import { agentsSlice } from '@entities/agents';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsListStickyHeader.scss';
const AgentsListStickyHeader = () => {
    const isSearchDistrict = useAppSelector(agentsSlice.selectIsSearchDistrict);
    return _jsx("div", { className: styles.agentsListStickyHeader, "data-sentry-component": "AgentsListStickyHeader", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: clsx(styles.row, {
                [styles.isSearchDistrict]: isSearchDistrict
            }), children: [_jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0420\u0438\u0435\u043B\u0442\u043E\u0440" }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433" }), _jsxs(LabelStandardRegular, { tag: "span", wrapper: false, className: clsx(styles.header, styles.textCenter), "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: ["\u0421\u0434\u0435\u043B\u043A\u0438 ", isSearchDistrict ? 'в районе' : null] }), !isSearchDistrict ? _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: clsx(styles.header, styles.textCenter), children: "\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F" }) : null, _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F" })] }) });
};
export default memo(AgentsListStickyHeader);
