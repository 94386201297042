import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent } from '@front-core/button';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment, useState, memo } from 'react';
import { reviewsSlice, TypesModal } from '@entities/reviews';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import AgentReviewUpdateBottomSheet from './components/bottomSheet';
import AgentReviewUpdateForm from './components/form';
import AgentReviewUpdateModal from './components/modal';
const AgentReviewUpdate = ({ review }) => {
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const breakpoints = useBreakpoint(media === 'mobile' ? BreakPoints.MOBILE : BreakPoints.TABLET_LANDSCAPE);
    const isTabletLandscapeAndMore = breakpoints !== BreakPoints.MOBILE && breakpoints !== BreakPoints.TABLET_PORTRAIT;
    const updateWebViewTopBar = (isVisible) => {
        if (userAgent && isWebView) {
            setWebViewVisibilityTopBar(userAgent, isVisible);
        }
    };
    const handleOpenModal = () => {
        updateWebViewTopBar(true);
        setIsModalVisible(true);
        sendMetrics({
            eventType: 'click',
            elementId: AgentMetricHashes.ClickButtonAgentReviewEdit,
            elementType: 'Button',
            eventSense: {
                reviewId: review.id,
                agentCasId: agent === null || agent === void 0 ? void 0 : agent.casId,
                regionId
            }
        });
    };
    const handleSubmit = () => {
        setIsModalVisible(false);
        updateWebViewTopBar(false);
        dispatch(reviewsSlice.toggleModals(TypesModal.List));
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        updateWebViewTopBar(false);
    };
    return _jsxs(Fragment, { children: [_jsx(Button, { intent: Intent.Secondary, text: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C", onClick: handleOpenModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewUpdateModal, { isOpen: isModalVisible, onClose: handleCloseModal, children: _jsx(AgentReviewUpdateForm, { review: review, onCancel: handleCloseModal, onSubmit: handleCloseModal }) }) : null, !isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewUpdateBottomSheet, { isOpen: isModalVisible, onClose: handleCloseModal, children: _jsx(AgentReviewUpdateForm, { review: review, onCancel: handleCloseModal, onSubmit: handleSubmit }) }) : null] });
};
export default memo(AgentReviewUpdate);
