import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertStatus, AlertList } from '@front-core/alert';
import { Paper } from '@front-core/box';
import { Button, Intent, Size } from '@front-core/button';
import { Input } from '@front-core/input';
import { MultipleButtonSelect, ButtonSelectSize } from '@front-core/multiple-button-select';
import { Picture } from '@front-core/picture';
import { HeadingSmall } from '@front-core/typography';
import { useState, useRef, Fragment } from 'react';
import { AgentMetricHashes } from '@shared/config/metrics';
import { YandexMetricaSections } from '@shared/config/yandexMetrica/sections';
import { YandexMetricaTargets } from '@shared/config/yandexMetrica/targets';
import { YandexMetricaHelper } from '@shared/lib/helpers/yandexMetrica';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { QuickMessageElementId, quickMessageOptions } from '../../config/quickMessageOptions';
import { useCreateChat } from '../../lib/useCreateChat';
import sendMessageSvg from '../images/send-message.svg';
import styles from './agentChatQuickForm.scss';
const AgentChatQuickForm = () => {
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const userCasIdSigned = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casIdSigned; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const queryParams = useAppSelector(state => state.agents.queryParams);
    const [message, setMessage] = useState('');
    const [alertList, setAlertList] = useState([]);
    const buttonRef = useRef(null);
    const isSubmitYM = useRef(false);
    const { isLoading, isFetching, triggerChatOptions } = useCreateChat();
    const isAuthUser = userCasId || userCasIdSigned;
    const isPending = isLoading || isFetching;
    const agentCasId = agent === null || agent === void 0 ? void 0 : agent.casId;
    const updateAlertList = error => {
        setAlertList([{
                id: '1',
                showCloseIcon: true,
                title: error === null || error === void 0 ? void 0 : error.message,
                duration: 4,
                status: AlertStatus.ERROR
            }]);
    };
    const showLoginForm = () => {
        if (!isAuthUser && !isWebView) {
            window === null || window === void 0 ? void 0 : window.domclickGlobal.domclickLayout.showLoginForm({
                loginFormProps: {
                    onEntry: () => setTimeout(() => { var _a; return (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, 500)
                }
            });
        }
    };
    const sendYM = () => {
        if (userCasId && !isSubmitYM.current) {
            YandexMetricaHelper.reachGoal({
                targetName: YandexMetricaTargets.AgentsChat,
                params: {
                    casId: userCasId,
                    section: YandexMetricaSections.Agent
                }
            });
            isSubmitYM.current = true;
        }
    };
    const handleClick = () => {
        const metricsParams = {
            eventType: 'click',
            elementId: AgentMetricHashes.ClickButtonChat,
            elementType: 'Button',
            eventSense: {
                agentCasId,
                regionId,
                queryParams
            }
        };
        sendYM();
        if (!SSR && triggerChatOptions) {
            triggerChatOptions({
                metricsParams,
                messageStub: message
            }).then(() => {
                showLoginForm();
            }).catch(error => {
                updateAlertList(error);
            });
        }
    };
    const handleChangeInput = (value) => {
        setMessage(value);
    };
    const handleClickQuickMessage = (item) => {
        const metricsParams = {
            eventType: 'click',
            elementId: QuickMessageElementId[item.value],
            elementType: 'button',
            eventSense: {
                agentCasId,
                regionId
            }
        };
        sendYM();
        if (!SSR && triggerChatOptions) {
            triggerChatOptions({
                metricsParams,
                messageStub: item.value
            }).then(() => {
                showLoginForm();
            }).catch(error => {
                updateAlertList(error);
            });
        }
    };
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgentMetricHashes.ClickInputChat,
            elementType: 'Input',
            eventSense: {
                agentCasId,
                regionId
            }
        });
    };
    if (isWebView) {
        return null;
    }
    return _jsx(Paper, { className: styles.agentChatQuickForm, "data-sentry-element": "Paper", "data-sentry-component": "AgentChatQuickForm", "data-sentry-source-file": "index.tsx", children: _jsxs(Fragment, { children: [_jsx(HeadingSmall, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "HeadingSmall", "data-sentry-source-file": "index.tsx", children: "\u0421\u043F\u0440\u043E\u0441\u0438\u0442\u0435 \u0443 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430" }), _jsxs("div", { className: styles.message, children: [_jsx(Input, { label: "\u041D\u0430\u043F\u0438\u0448\u0438\u0442\u0435 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u0435", fluid: true, value: message, onValueChange: handleChangeInput, onFocus: handleFocusInput, showClearIcon: true, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" }), _jsx(Button, { icon: _jsx(Picture, { src: sendMessageSvg, alt: "send" }), intent: Intent.Secondary, size: Size.Large, buttonRef: buttonRef, onClick: handleClick, loading: isPending, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" })] }), _jsx(MultipleButtonSelect, { size: ButtonSelectSize.Small, wrapperClassName: styles.quickMessages, items: quickMessageOptions, value: [], onSelect: handleClickQuickMessage, "data-sentry-element": "MultipleButtonSelect", "data-sentry-source-file": "index.tsx" }), _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" })] }) });
};
export default AgentChatQuickForm;
