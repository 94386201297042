import { jsx as _jsx } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { Modal } from '@front-core/modal';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useEffect } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
const LayoutModal = ({ children, modalProps, bottomSheetProps }) => {
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => state.ui.isWebView);
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    useEffect(() => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, true);
        }
        return () => {
            if (isWebView && userAgent) {
                setWebViewVisibilityTopBar(userAgent, false);
            }
        };
    }, []);
    if (isMobile) {
        return _jsx(BottomSheet, { ...bottomSheetProps, children: children });
    }
    return _jsx(Modal, { ...modalProps, verticalCentering: true, "data-sentry-element": "Modal", "data-sentry-component": "LayoutModal", "data-sentry-source-file": "index.tsx", children: children });
};
export default LayoutModal;
