import { jsx as _jsx } from "react/jsx-runtime";
import { MultipleButtonSelect } from '@front-core/multiple-button-select';
import { useMemo, useState } from 'react';
import { MapDealTypes } from '@shared/config/mapDealTypes';
import { getDefaultMapDealsType } from '@shared/lib/getDefaultMapDealsType';
const MapFilters = ({ salesTotal, rentsTotal, onChange }) => {
    const [selectedValue, setSelectedValue] = useState(getDefaultMapDealsType({
        rentsTotal,
        salesTotal
    }));
    const items = useMemo(() => {
        const currentItems = [];
        if (salesTotal > 0) {
            currentItems.push({
                title: `Продажа ${salesTotal}`,
                value: MapDealTypes.Sale
            });
        }
        if (rentsTotal > 0) {
            currentItems.push({
                title: `Аренда ${rentsTotal}`,
                value: MapDealTypes.Rent
            });
        }
        return currentItems;
    }, [salesTotal, rentsTotal]);
    const handleValueChange = (_selectedValue, value) => {
        setSelectedValue(value);
        onChange(value);
    };
    if ((salesTotal === 0 || rentsTotal === 0) && items.length < 2) {
        return null;
    }
    return _jsx(MultipleButtonSelect, { items: items, defaultValue: [selectedValue], value: [selectedValue], onValueChange: handleValueChange, "data-sentry-element": "MultipleButtonSelect", "data-sentry-component": "MapFilters", "data-sentry-source-file": "index.tsx" });
};
export default MapFilters;
