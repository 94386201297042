import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgenciesListSkeletonDesktop from './desktop';
import AgenciesListSkeletonMobile from './mobile';
import styles from './skeleton.scss';
const cards = Array.from({
    length: 10
}, (_value, index) => index + 1);
const AgenciesListSkeleton = ({ isShowSkeleton }) => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint !== BreakPoints.MOBILE;
    if (!isShowSkeleton) {
        return null;
    }
    return _jsxs("div", { className: styles.container, "data-sentry-component": "AgenciesListSkeleton", "data-sentry-source-file": "index.tsx", children: [_jsx("div", { children: cards.map(card => isDesktop ? _jsx(AgenciesListSkeletonDesktop, {}, card) : _jsx(AgenciesListSkeletonMobile, {}, card)) }), _jsxs("div", { className: styles.pagination, children: [_jsx(Skeleton, { type: SkeletonType.Circle, width: "20px", height: "20px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Circle, width: "20px", height: "20px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" })] })] });
};
export default AgenciesListSkeleton;
