import { useState, useEffect, useRef } from 'react';
export const useAnimatedNumber = (target, options = {}) => {
    const { duration = 250 } = options;
    const [animatedValue, setAnimatedValue] = useState(target);
    const prevTargetRef = useRef();
    const animationFrameRef = useRef();
    const startValueRef = useRef(0);
    const startTimeRef = useRef(0);
    useEffect(() => {
        if (prevTargetRef.current === target) {
            return undefined;
        }
        startValueRef.current = prevTargetRef.current !== undefined
            ? animatedValue
            : 0;
        startTimeRef.current = performance.now();
        prevTargetRef.current = target;
        const animate = (timestamp) => {
            const elapsed = timestamp - startTimeRef.current;
            const progress = Math.min(elapsed / duration, 1);
            const current = startValueRef.current + ((target - startValueRef.current) * progress);
            setAnimatedValue(current);
            if (progress < 1) {
                animationFrameRef.current = requestAnimationFrame(animate);
            }
            else {
                setAnimatedValue(target);
            }
        };
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        animationFrameRef.current = requestAnimationFrame(animate);
        return () => {
            if (animationFrameRef.current) {
                cancelAnimationFrame(animationFrameRef.current);
            }
        };
    }, [target, duration]);
    return Math.round(animatedValue);
};
