import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, BadgeType, BadgeSize } from '@front-core/badge';
import CloseIcon from '@front-core/icon/dist/v2/Close';
import { LabelStandardMedium } from '@front-core/typography';
import { useMemo, useState, useCallback } from 'react';
import { agentsSlice, AgentOrder } from '@entities/agents';
import { geoSlice } from '@entities/geo';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgentsSearchDistrictModal from '../modal';
import styles from './agentsSearchDistrictSecondary.scss';
const AgentsSearchDistrictSecondary = () => {
    const districtIds = useAppSelector(state => state.agents.queryParams.districtIds) || [];
    const flatDistrictList = useAppSelector(geoSlice.selectFlatDistricts);
    const dispatch = useAppDispatch();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const currentDistrict = useMemo(() => {
        if (districtIds.length >= 1) {
            const findDistrict = flatDistrictList.find(district => districtIds[0] === district.id);
            if (findDistrict) {
                return findDistrict;
            }
        }
        return null;
    }, [districtIds, flatDistrictList]);
    const districtIdsLength = districtIds.length;
    const handleToggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    const handleRemoveDistrict = useCallback(() => {
        dispatch(agentsSlice.setQueryParams({
            districtIds: null,
            order: AgentOrder.Default
        }));
    }, [districtIds]);
    if (districtIdsLength === 0 || !currentDistrict) {
        return null;
    }
    return _jsxs("div", { className: styles.agentsSearchDistrictSecondary, "data-sentry-component": "AgentsSearchDistrictSecondary", "data-sentry-source-file": "index.tsx", children: [_jsx(LabelStandardMedium, { tag: "button", wrapper: false, className: styles.district, onClick: handleToggleModal, "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: currentDistrict.name }), _jsx("button", { className: styles.removeHandler, onClick: handleRemoveDistrict, children: _jsx(CloseIcon, { "data-sentry-element": "CloseIcon", "data-sentry-source-file": "index.tsx" }) }), districtIdsLength > 1 ? _jsx("button", { className: styles.modalHandler, onClick: handleToggleModal, children: _jsx(Badge, { text: `${districtIdsLength - 1}+`, type: BadgeType.Secondary, size: BadgeSize.Small }) }) : null, isModalOpen ? _jsx(AgentsSearchDistrictModal, { onToggleModal: handleToggleModal, isModalOpen: isModalOpen }) : null] });
};
export default AgentsSearchDistrictSecondary;
