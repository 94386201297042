import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingLarge, LabelLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { FAQAppHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import AppAndroid from '@shared/ui/appAndroid';
import AppStore from '@shared/ui/appStore';
import SVGQrApps from '@shared/ui/svg/qrApps';
import styles from './faqAppsInstall.scss';
const FAQAppsInstall = () => {
    const media = useAppSelector(state => state.ui.media);
    const regionId = useAppSelector(state => state.geo.region.guid);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const isDesktop = breakpoint !== BreakPoints.MOBILE;
    const getTitle = () => {
        if (isMobile) {
            return 'Установите приложение';
        }
        return 'Чтобы установить приложение, наведите камеру смартфона на QR-код';
    };
    const sendAnalytics = (elementId) => {
        sendMetrics({
            elementId,
            eventType: 'click',
            elementType: 'Link',
            eventSense: {
                regionId
            }
        });
    };
    const handleClickAppStore = () => {
        sendAnalytics(FAQAppHashes.ClickFooterAppStoreButton);
    };
    const handleClickAppAndroid = () => {
        sendAnalytics(FAQAppHashes.ClickFooterAndroidButton);
    };
    const renderContent = () => _jsxs(Fragment, { children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: getTitle() }), isMobile ? _jsx(LabelLargeRegular, { tag: "p", wrapper: false, className: styles.description, children: "\u041F\u043E\u0441\u043C\u043E\u0442\u0440\u0438\u0442\u0435 \u0438\u043D\u0441\u0442\u0440\u0443\u043A\u0446\u0438\u044E \u043F\u043E \u0441\u043A\u0430\u0447\u0438\u0432\u0430\u043D\u0438\u044E" }) : null, _jsxs("div", { className: styles.apps, children: [_jsx(AppStore, { onClick: handleClickAppStore, "data-sentry-element": "AppStore", "data-sentry-source-file": "index.tsx" }), _jsx(AppAndroid, { onClick: handleClickAppAndroid, "data-sentry-element": "AppAndroid", "data-sentry-source-file": "index.tsx" })] })] });
    return _jsxs(Paper, { tag: "section", className: styles.faqAppsInstall, "data-sentry-element": "Paper", "data-sentry-component": "FAQAppsInstall", "data-sentry-source-file": "index.tsx", children: [isMobile ? renderContent() : _jsx("div", { className: styles.container, children: renderContent() }), isDesktop ? _jsx(SVGQrApps, { width: 156, height: 156, className: styles.qr }) : null] });
};
export default FAQAppsInstall;
