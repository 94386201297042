import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GalleryViewOverlay } from '@front-core/gallery-view';
import { Picture, ObjectFit } from '@front-core/picture';
import { useState, Fragment } from 'react';
import styles from './achievementsTablet.scss';
const AchievementsTabletItem = ({ src, title, index, slots }) => {
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const handleToggleGallery = () => {
        setIsShowGallery(prevState => !prevState);
        setActiveSlide(index || 0);
    };
    const handleChangeSlide = (activeIndex) => {
        setActiveSlide(activeIndex);
    };
    return _jsxs(Fragment, { children: [_jsx("button", { className: styles.button, onClick: handleToggleGallery, children: _jsx(Picture, { height: 69, src: src, alt: title, objectFit: ObjectFit.Cover, objectPosition: "top", "data-sentry-element": "Picture", "data-sentry-source-file": "item.tsx" }) }), isShowGallery ? _jsx(GalleryViewOverlay, { slots: slots, open: isShowGallery, active: activeSlide, onClose: handleToggleGallery, onChangeSlide: handleChangeSlide }) : null] });
};
export default AchievementsTabletItem;
