import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination } from '@front-core/pagination';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agencyOfficeListPagination.scss';
const AgencyOfficeListPagination = ({ pagination, onChange }) => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const total = (pagination === null || pagination === void 0 ? void 0 : pagination.total) || 1;
    const limit = (pagination === null || pagination === void 0 ? void 0 : pagination.limit) || 5;
    const offset = (pagination === null || pagination === void 0 ? void 0 : pagination.offset) || 0;
    const count = Math.ceil(total / limit);
    const isHidePagination = pagination ? limit > total : false;
    const isMobileDevice = breakpoint === BreakPoints.MOBILE;
    if (isHidePagination) {
        return null;
    }
    return _jsx("div", { className: styles.pagination, "data-sentry-component": "AgencyOfficeListPagination", "data-sentry-source-file": "index.tsx", children: _jsx(Pagination, { visibleCount: isMobileDevice ? 3 : 7, count: count, currentPage: offset === 0 ? 1 : offset / limit + 1, onChange: onChange, "data-sentry-element": "Pagination", "data-sentry-source-file": "index.tsx" }) });
};
export default AgencyOfficeListPagination;
