import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { clsx } from 'clsx';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentInfo.scss';
import AgentInfoAvatar from './components/avatar';
import AgentInfoMain from './components/main';
import AgentInfoProgress from './components/progress';
import AgentInfoWork from './components/work';
const AgentInfo = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const agentSubtype = agent === null || agent === void 0 ? void 0 : agent.subtype;
    if (!agent) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", className: styles.agentInfo, "data-sentry-element": "Paper", "data-sentry-component": "AgentInfo", "data-sentry-source-file": "index.tsx", children: [_jsx(AgentInfoAvatar, { avatarUrl: agent.avatarUrl, name: agent.name, agentCasId: agent.casId, isMobile: isMobile, "data-sentry-element": "AgentInfoAvatar", "data-sentry-source-file": "index.tsx" }), _jsx(AgentInfoMain, { name: agent.name, region: agent.region, isVerified: agent.verified, ratingAvg: agent.rating.avg, "data-sentry-element": "AgentInfoMain", "data-sentry-source-file": "index.tsx" }), _jsx(AgentInfoWork, { agentSubtype: agentSubtype, agentType: agent.type, company: agent.company, "data-sentry-element": "AgentInfoWork", "data-sentry-source-file": "index.tsx" }), agent.stats || agent.registeredAt ? _jsx(AgentInfoProgress, { wrapperClassName: clsx({
                    [styles.progressWrapper]: !agent.verified
                }), offersCount: agent.stats.offersCount, dealsCompleted: agent.stats.dealsCount, registeredAt: agent.registeredAt }) : null] });
};
export default AgentInfo;
