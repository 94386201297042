import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag, TagSize } from '@front-core/badge';
import { SkeletonType, Skeleton } from '@front-core/skeleton';
import { LabelStandardMedium, LabelStandardRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { AgenciesSort } from '@features/agencies/sort';
import { agenciesSlice } from '@entities/agencies';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agenciesListHeader.scss';
const AgenciesListHeader = ({ totalCount, isLoading }) => {
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const cityName = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city.name; });
    const cityNameLocIn = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city.nameLocIn; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = !isWebView && breakpoint !== BreakPoints.MOBILE;
    const isMobile = isWebView || breakpoint === BreakPoints.MOBILE;
    const getResultText = () => {
        const findType = StringHelper.getPluralForm(totalCount, ['агентство', 'агентства', 'агентств']);
        const count = NumberHelper.getNumberLocaleFormat(totalCount);
        if (isWebView) {
            const findText = StringHelper.getPluralForm(totalCount, ['найден', 'найдено', 'найдено']);
            return `${cityNameLocIn || cityName} ${findText} ${count} ${findType}`;
        }
        const findText = StringHelper.getPluralForm(totalCount, ['Найден', 'Найдено', 'Найдено']);
        return `${findText} ${count} ${findType}`;
    };
    const handleCloseClick = () => {
        dispatch(agenciesSlice.setQueryParams({
            search: null
        }));
    };
    return _jsxs(Fragment, { children: [_jsxs("div", { className: styles.header, children: [isLoading ? _jsxs("div", { className: styles.headerTexts, children: [_jsx(Skeleton, { type: SkeletonType.Text, width: "255px", height: isMobile ? '18px' : '24px' }), isDesktop ? _jsx(LabelStandardRegular, { tag: "p", wrapper: false, className: styles.headerSubtitle, children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433 \u0438 \u0441\u0434\u0435\u043B\u043A\u0438 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u044E\u0442\u0441\u044F \u0437\u0430 12 \u043C\u0435\u0441\u044F\u0446\u0435\u0432" }) : null] }) : _jsxs("div", { className: styles.headerTexts, children: [_jsx(LabelStandardMedium, { tag: "h2", className: styles.headerDescription, wrapper: false, children: getResultText() }), _jsx(LabelStandardRegular, { tag: "p", wrapper: false, className: styles.headerSubtitle, children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433 \u0438 \u0441\u0434\u0435\u043B\u043A\u0438 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u044E\u0442\u0441\u044F \u0437\u0430 12 \u043C\u0435\u0441\u044F\u0446\u0435\u0432" })] }), isDesktop ? _jsx("div", { className: styles.headerSort, children: _jsx(AgenciesSort, {}) }) : null] }), isMobile && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) && queryParams.search.length > 0 ? _jsx("div", { className: styles.headerTags, children: _jsx(Tag, { size: TagSize.Large, text: queryParams.search, onCloseClick: handleCloseClick }) }) : null] });
};
export default AgenciesListHeader;
