import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Size } from '@front-core/button';
import CloseIcon from '@front-core/icon/dist/Close';
import { Modal, ModalHeaderType } from '@front-core/modal';
import { HeadingXSmall } from '@front-core/typography';
import styles from './agentsFiltersModal.scss';
const AgentsFiltersModal = ({ isModalOpen, onClose, children }) => {
    const handleClickCloseModal = () => {
        onClose();
    };
    return _jsx(Modal, { header: _jsxs("div", { className: styles.header, children: [_jsx("div", { className: styles.headerTitle, children: _jsx(HeadingXSmall, { tag: "h3", wrapper: false, className: styles.title, children: "\u0424\u0438\u043B\u044C\u0442\u0440\u044B" }) }), _jsx("button", { type: "button", title: "\u0417\u0430\u043A\u0440\u044B\u0442\u044C \u043F\u043E\u0438\u0441\u043A", className: styles.headerButton, onClick: handleClickCloseModal, children: _jsx(CloseIcon, { className: styles.icon }) })] }), headerType: ModalHeaderType.Banner, footer: _jsx(Button, { onClick: handleClickCloseModal, fluid: true, size: Size.Large, text: "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C" }), showCloseIcon: false, open: isModalOpen, onClose: handleClickCloseModal, "data-sentry-element": "Modal", "data-sentry-component": "AgentsFiltersModal", "data-sentry-source-file": "index.tsx", children: _jsx("div", { className: styles.container, children: children }) });
};
export default AgentsFiltersModal;
