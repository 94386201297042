import { jsx as _jsx } from "react/jsx-runtime";
import { Group } from '@front-core/accordion';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import FaqItem from './components/item';
import styles from './faq.scss';
const Faq = ({ items, sendAnalytics }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    return _jsx("section", { className: styles.faq, "data-sentry-component": "Faq", "data-sentry-source-file": "index.tsx", children: _jsx(Group, { defaultBreakpoint: breakpoint, mobileOffset: false, "data-sentry-element": "Group", "data-sentry-source-file": "index.tsx", children: items.map(({ id, title, content }) => _jsx(FaqItem, { title: title, sendAnalytics: sendAnalytics, content: content, questionId: id }, id)) }) });
};
export default Faq;
