import { Unicode } from '@shared/config/unicode';
import item2JPG from '@shared/images/faq/item-2.jpg';
import item2WebP from '@shared/images/faq/item-2.webp';
import item2JPG2x from '@shared/images/faq/item-2@2x.jpg';
import item2WebP2x from '@shared/images/faq/item-2@2x.webp';
import item3JPG from '@shared/images/faq/item-3.jpg';
import item3WebP from '@shared/images/faq/item-3.webp';
import item3JPG2x from '@shared/images/faq/item-3@2x.jpg';
import item3WebP2x from '@shared/images/faq/item-3@2x.webp';
import item4JPG from '@shared/images/faq/item-4.jpg';
import item4WebP from '@shared/images/faq/item-4.webp';
import item4JPG2x from '@shared/images/faq/item-4@2x.jpg';
import item4WebP2x from '@shared/images/faq/item-4@2x.webp';
import item5PNG from '@shared/images/faq/item-5.png';
import item5WebP from '@shared/images/faq/item-5.webp';
import item5PNG2x from '@shared/images/faq/item-5@2x.png';
import item5WebP2x from '@shared/images/faq/item-5@2x.webp';
import attention1JPG from '../images/attention-1.jpg';
import attention1WebP from '../images/attention-1.webp';
import attention1JPG2x from '../images/attention-1@2x.jpg';
import attention1WebP2x from '../images/attention-1@2x.webp';
import attention2JPG from '../images/attention-2.jpg';
import attention2WebP from '../images/attention-2.webp';
import attention2JPG2x from '../images/attention-2@2x.jpg';
import attention2WebP2x from '../images/attention-2@2x.webp';
import attention3JPG from '../images/attention-3.jpg';
import attention3WebP from '../images/attention-3.webp';
import attention3JPG2x from '../images/attention-3@2x.jpg';
import attention3WebP2x from '../images/attention-3@2x.webp';
import attention4JPG from '../images/attention-4.jpg';
import attention4WebP from '../images/attention-4.webp';
import attention4JPG2x from '../images/attention-4@2x.jpg';
import attention4WebP2x from '../images/attention-4@2x.webp';
export const faqClientAttentions = [
    {
        title: 'Оценка и отзывы',
        description: `Отзывам на Домклик можно доверять: их оставляют клиенты, у которых прошла сделка.${Unicode.NextLine}Ответы риелтора показывают, что ему важно мнение клиентов.`,
        primaryImage: {
            src: attention1JPG,
            src2x: attention1JPG2x,
            webpSrc: attention1WebP,
            webpSrc2x: attention1WebP2x
        },
        secondaryImage: {
            src: item2JPG,
            src2x: item2JPG2x,
            webpSrc: item2WebP,
            webpSrc2x: item2WebP2x
        }
    },
    {
        title: 'Профиль',
        description: 'В разделе «Риелторы» в первую очередь отображаются специалисты с подтверждёнными и заполненными профилями. В них можно найти подробную информацию об опыте работы.',
        primaryImage: {
            src: attention2JPG,
            src2x: attention2JPG2x,
            webpSrc: attention2WebP,
            webpSrc2x: attention2WebP2x
        },
        secondaryImage: {
            src: item5PNG,
            src2x: item5PNG2x,
            webpSrc: item5WebP,
            webpSrc2x: item5WebP2x
        }
    },
    {
        title: 'Объявления',
        description: `Количество объявлений говорит об активности риелтора.${Unicode.NextLine}Вы можете открыть информацию о риелторе, посмотреть его объявления и найти подходящее.`,
        primaryImage: {
            src: attention3JPG,
            src2x: attention3JPG2x,
            webpSrc: attention3WebP,
            webpSrc2x: attention3WebP2x
        },
        secondaryImage: {
            src: item3JPG,
            src2x: item3JPG2x,
            webpSrc: item3WebP,
            webpSrc2x: item3WebP2x
        }
    },
    {
        title: 'Сделки',
        description: `Чем больше риелтор провёл сделок за последние 12${Unicode.NoBreakSpace}месяцев, тем актуальнее его знания о рынке недвижимости.`,
        primaryImage: {
            src: attention4JPG,
            src2x: attention4JPG2x,
            webpSrc: attention4WebP,
            webpSrc2x: attention4WebP2x
        },
        secondaryImage: {
            src: item4JPG,
            src2x: item4JPG2x,
            webpSrc: item4WebP,
            webpSrc2x: item4WebP2x
        }
    }
];
