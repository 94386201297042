import { jsx as _jsx } from "react/jsx-runtime";
import { Loader, LoaderSize } from '@front-core/loader';
import { ModalSize } from '@front-core/modal';
import { LabelStandardRegular } from '@front-core/typography';
import { useMemo, useCallback, memo } from 'react';
import { agentsSlice, AgentOrder } from '@entities/agents';
import { geoApi } from '@entities/geo';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutModal from '@shared/ui/layout/modal';
import { getSearchDistrictDefaultIds } from '../../../lib/getSearchDistrictDefaultIds';
import { getSearchDistrictResultIds } from '../../../lib/getSearchDistrictResultIds';
import { useAgentsCount } from '../../../lib/useAgentsCount';
import { useDistrictSelection } from '../../../lib/useDistrictSelection';
import styles from './agentsSearchDistrictModal.scss';
import AgentsSearchDistrictCheckBoxList from './checkBoxList';
import AgentsSearchDistrictModalFooter from './footer';
import AgentsSearchDistrictModalHeader from './header';
const AgentsListSearchDistrictModalForm = ({ isModalOpen, onToggleModal }) => {
    const regionGuid = useAppSelector(state => state.geo.region.guid);
    const queryParams = useAppSelector(state => state.agents.queryParams);
    const districtList = useAppSelector(state => state.geo.district.list);
    const dispatch = useAppDispatch();
    const { isFetching: isFetchingDistricts, isLoading: isLoadingDistricts } = geoApi.useGetDistrictsQuery({
        guid: regionGuid
    }, {
        skip: !regionGuid || SSR
    });
    const { districtIds, districtIdsSet, searchQuery, filteredDistricts, prevDistrictIds, setSearchQuery, updateParentDistrict, updateChildDistrict, resetSelection } = useDistrictSelection(getSearchDistrictDefaultIds(districtList, queryParams.districtIds), districtList);
    const { agentsCount, isLoading: isLoadingAgentsCount } = useAgentsCount(districtIds, queryParams, isModalOpen);
    const isPending = isLoadingDistricts || isFetchingDistricts || isLoadingAgentsCount;
    const submitForm = () => {
        var _a;
        const result = getSearchDistrictResultIds(districtList, districtIds);
        const hasDistrictIdsDiff = JSON.stringify(prevDistrictIds) !== JSON.stringify(districtIds);
        if (result.length && hasDistrictIdsDiff) {
            dispatch(agentsSlice.setQueryParams({
                districtIds: result,
                order: AgentOrder.TopDeals,
                offset: 0
            }));
        }
        if (((_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.districtIds) === null || _a === void 0 ? void 0 : _a.length) && !result.length) {
            dispatch(agentsSlice.putQueryParams({
                districtIds: null
            }));
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const handleChangeInput = useCallback((value) => {
        setSearchQuery(value);
    }, []);
    const handleParentChange = useCallback((event) => {
        const { checked, id } = event.target;
        updateParentDistrict(Number(id), checked);
    }, [districtList]);
    const handleChildChange = useCallback((event) => {
        const { checked, id } = event.target;
        updateChildDistrict(Number(id), checked);
    }, [districtList]);
    const handleResetForm = useCallback(() => {
        resetSelection();
    }, []);
    const handleSubmitForm = useCallback((event) => {
        event === null || event === void 0 ? void 0 : event.preventDefault();
        submitForm();
        onToggleModal();
    }, [districtList, districtIds, queryParams, dispatch, onToggleModal]);
    const layoutModalProps = useMemo(() => ({
        onClose: onToggleModal,
        open: isModalOpen,
        header: _jsx(AgentsSearchDistrictModalHeader, { searchQuery: searchQuery, onChange: handleChangeInput }),
        footer: _jsx(AgentsSearchDistrictModalFooter, { onReset: handleResetForm, onSubmit: handleSubmitForm, agentsCount: agentsCount, isLoading: isPending })
    }), [isPending, districtIds, prevDistrictIds, isModalOpen, onToggleModal, searchQuery, handleChangeInput, handleResetForm, agentsCount, handleSubmitForm]);
    return _jsx(LayoutModal, { bottomSheetProps: {
            ...layoutModalProps,
            fixedHeight: true,
            topIndent: 20
        }, modalProps: {
            ...layoutModalProps,
            size: ModalSize.Large,
            height: '100%',
            footerDivider: false
        }, "data-sentry-element": "LayoutModal", "data-sentry-component": "AgentsListSearchDistrictModalForm", "data-sentry-source-file": "index.tsx", children: isPending ? _jsx(Loader, { size: LoaderSize.ExtraLarge, className: styles.loader }) : _jsx("form", { onSubmit: handleSubmitForm, className: styles.agentsSearchDistrictModal, children: filteredDistricts.length ? _jsx(AgentsSearchDistrictCheckBoxList, { districts: filteredDistricts, districtIdsSet: districtIdsSet, onParentChange: handleParentChange, onChildChange: handleChildChange }) : _jsx(LabelStandardRegular, { wrapper: false, className: styles.emptyListText, children: "\u0422\u0430\u043A\u043E\u0433\u043E \u0440\u0430\u0439\u043E\u043D\u0430 \u043D\u0435\u0442, \u0443\u043A\u0430\u0436\u0438\u0442\u0435 \u0434\u0440\u0443\u0433\u043E\u0439" }) }) });
};
export default memo(AgentsListSearchDistrictModalForm);
