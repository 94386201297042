import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertList, AlertStatus } from '@front-core/alert';
import { Button, Intent } from '@front-core/button';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment, useState, useEffect, memo } from 'react';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { YandexMetricaSections } from '@shared/config/yandexMetrica/sections';
import { YandexMetricaTargets } from '@shared/config/yandexMetrica/targets';
import { YandexMetricaHelper } from '@shared/lib/helpers/yandexMetrica';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { useAgencySubstitutePhone } from '../../lib/useAgencySubstitutePhone';
import CreateSubstituteModal from './modal';
var AlertListIds;
(function (AlertListIds) {
    AlertListIds["SubstitutePhoneError"] = "1";
})(AlertListIds || (AlertListIds = {}));
const AgencyCreateSubstitutePhoneModal = ({ agencyId }) => {
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const media = useAppSelector(state => state.ui.media);
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const [alertList, setAlertList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const { errorMessage, isPending, substitutePhone, phoneNumber } = useAgencySubstitutePhone({
        agencyId
    });
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const handleToggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    const handleClick = () => {
        substitutePhone().then(() => {
            setIsModalOpen(true);
            sendMetrics({
                eventType: 'click',
                elementId: AgencyMetricHashes.ClickButtonCreateSubstitute,
                elementType: 'button',
                eventSense: {
                    agencyId,
                    regionId
                }
            });
        });
        if (userCasId) {
            YandexMetricaHelper.reachGoal({
                targetName: YandexMetricaTargets.AgentsCall,
                params: {
                    casId: userCasId,
                    section: YandexMetricaSections.Agencies
                }
            });
        }
    };
    useEffect(() => {
        if (errorMessage) {
            setAlertList([{
                    id: AlertListIds.SubstitutePhoneError,
                    showCloseIcon: true,
                    title: errorMessage,
                    description: 'Попробуйте ещё раз',
                    duration: 3,
                    status: AlertStatus.ERROR
                }]);
        }
    }, [errorMessage]);
    return _jsxs(Fragment, { children: [_jsx(Button, { fluid: true, intent: Intent.Secondary, text: isMobile ? 'Позвонить' : 'Показать телефон', loading: isPending, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" }), isModalOpen && phoneNumber ? _jsx(CreateSubstituteModal, { agencyId: agencyId, isModalOpen: isModalOpen, phoneNumber: phoneNumber, onClose: handleToggleModal }) : null] });
};
export default memo(AgencyCreateSubstitutePhoneModal);
