import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { Size } from '@front-core/button';
import { LabelLargeMedium } from '@front-core/typography';
import { AgentReviewCreate } from '@features/agent/review/create';
import { AgentReviewsPeriod } from '@entities/agents';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgentReviewsControls from '../controls';
import AgentReviewsList from '../list';
import styles from './reviewBottomSheetList.scss';
const ReviewBottomSheetList = ({ isOpen, onClose }) => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const reviews = useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent) === null || _b === void 0 ? void 0 : _b.reviews) === null || _c === void 0 ? void 0 : _c.list; }) || [];
    const agentReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.queryParams; });
    const hasReviewsEmptyListForTheYear = !reviews.length && agentReviewQueryParams.period === AgentReviewsPeriod.Year;
    return _jsx(BottomSheet, { fixedHeight: true, topIndent: isWebView ? 0 : 8, open: isOpen, onClose: onClose, footerButtons: hasReviewsEmptyListForTheYear ? [_jsx(AgentReviewCreate, { fluidButton: true, sizeButton: Size.Large }, "BottomSheetAgentReviewCreate")] : undefined, header: _jsxs("div", { className: styles.reviewBottomSheetList, children: [_jsx(LabelLargeMedium, { tag: "strong", wrapper: false, children: "\u041E\u0442\u0437\u044B\u0432\u044B \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u0421\u0431\u0435\u0440\u0411\u0430\u043D\u043A\u0430" }), _jsx(AgentReviewsControls, {})] }), "data-sentry-element": "BottomSheet", "data-sentry-component": "ReviewBottomSheetList", "data-sentry-source-file": "reviewBottomSheetList.tsx", children: _jsx(AgentReviewsList, { hasBottomSheetListContainer: true, "data-sentry-element": "AgentReviewsList", "data-sentry-source-file": "reviewBottomSheetList.tsx" }) });
};
export default ReviewBottomSheetList;
