import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Size } from '@front-core/button';
import { Picture } from '@front-core/picture';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { FAQChoiceAgentHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import CustomLink from '@shared/ui/customLink';
import LayoutBox from '@shared/ui/layout/box';
import desktopFoldersPNG from '../images/desktop.folders.png';
import desktopFoldersWebP from '../images/desktop.folders.webp';
import desktopFoldersPNG2x from '../images/desktop.folders@2x.png';
import desktopFoldersWebP2x from '../images/desktop.folders@2x.webp';
import mobileFoldersPNG from '../images/mobile.folders.png';
import mobileFoldersWebP from '../images/mobile.folders.webp';
import mobileFoldersPNG2x from '../images/mobile.folders@2x.png';
import mobileFoldersWebP2x from '../images/mobile.folders@2x.webp';
import styles from './faqAgentCooperation.scss';
const FAQAgentCooperation = () => {
    const media = useAppSelector(state => state.ui.media);
    const geo = useAppSelector(state => state.geo);
    const { region: { guid: regionId }, slug: regionSlug } = geo;
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const routeToAgents = generatePath(PATH.AGENTS, {
        regionSlug
    });
    const isMobile = breakpoint !== BreakPoints.DESKTOP;
    const handleClickButton = () => {
        sendMetrics({
            elementId: FAQChoiceAgentHashes.ClickSearchAgentsButton,
            eventType: 'click',
            elementType: 'Button',
            eventSense: {
                regionId
            }
        });
        window.scrollTo(0, 0);
    };
    return _jsx(LayoutBox, { className: styles.faqAgentCooperation, classNameContent: styles.content, classNameTitle: styles.title, title: "280 000 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432 \u0441\u043E\u0442\u0440\u0443\u0434\u043D\u0438\u0447\u0430\u044E\u0442 \u0441 \u0414\u043E\u043C\u043A\u043B\u0438\u043A", image: _jsx(Picture, { src: mobileFoldersPNG, src2x: mobileFoldersPNG2x, sources: [{
                    type: 'image/webp',
                    media: '(min-width: 1280px)',
                    srcSet: `${desktopFoldersWebP} 1x, ${desktopFoldersWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 1280px)',
                    srcSet: `${desktopFoldersPNG} 1x, ${desktopFoldersPNG2x} 2x`
                }, {
                    type: 'image/webp',
                    srcSet: `${mobileFoldersWebP} 1x, ${mobileFoldersWebP2x} 2x`
                }], alt: "", width: isMobile ? 342 : 500, height: isMobile ? 246 : 256, loading: "lazy" }), "data-sentry-element": "LayoutBox", "data-sentry-component": "FAQAgentCooperation", "data-sentry-source-file": "index.tsx", children: _jsx(Button, { fluid: true, text: "\u041F\u043E\u0434\u043E\u0431\u0440\u0430\u0442\u044C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", size: Size.Large, routerLink: _jsx(CustomLink, { to: routeToAgents }), wrapperClassName: styles.button, onClick: handleClickButton, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }) });
};
export default FAQAgentCooperation;
