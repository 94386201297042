import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge, BadgeType, BadgeColor } from '@front-core/badge';
import SuccessIcon from '@front-core/icon/dist/Success';
import { HeadingXSmall, LabelStandardRegular } from '@front-core/typography';
import clsx from 'clsx';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingFontSize, RatingType } from '@shared/ui/layout/rating/config';
import styles from './agentInfoMain.scss';
const AgentInfoMain = ({ name, region, ratingAvg, isVerified }) => {
    const regionNameLoc = region === null || region === void 0 ? void 0 : region.nameLoc;
    const location = region ? regionNameLoc || (region === null || region === void 0 ? void 0 : region.name) : null;
    return _jsxs("div", { className: styles.agentInfoMain, "data-sentry-component": "AgentInfoMain", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: clsx(styles.container, {
                    [styles.isGrid]: Boolean(region && location)
                }), children: [_jsx(HeadingXSmall, { tag: "h1", className: styles.title, wrapper: false, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: name.trim() }), ratingAvg > 0 ? _jsx(LayoutRating, { hasGrade: true, rating: ratingAvg, fontSize: RatingFontSize.Medium, type: RatingType.Secondary, containerClassName: styles.rating }) : null, region && location ? _jsxs(LabelStandardRegular, { className: styles.workLocation, wrapper: false, children: ["\u0420\u0430\u0431\u043E\u0442\u0430\u0435\u0442", regionNameLoc ? ' в ' : ' в городе ', regionNameLoc ? location.replace('в', '') : location] }) : null] }), isVerified ? _jsx(Badge, { text: _jsx("span", { children: "\u041F\u0440\u043E\u0444\u0438\u043B\u044C \u043F\u043E\u0434\u0442\u0432\u0435\u0440\u0436\u0434\u0451\u043D" }), icon: _jsx(SuccessIcon, {}), type: BadgeType.Primary, color: BadgeColor.Success, wrapperClassName: styles.badge }) : null] });
};
export default AgentInfoMain;
