import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { AgentChatQuickForm } from '@features/agent/chat';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgentActionButtons from '../actionButtons';
import AgentRecommendations from '../recommendations';
import styles from './agentAside.scss';
const AgentAside = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    if (!isDesktop) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsx(Paper, { className: styles.agentAside, "data-sentry-element": "Paper", "data-sentry-source-file": "index.tsx", children: _jsxs(Fragment, { children: [_jsx(AgentActionButtons, { "data-sentry-element": "AgentActionButtons", "data-sentry-source-file": "index.tsx" }), _jsx(AgentChatQuickForm, { "data-sentry-element": "AgentChatQuickForm", "data-sentry-source-file": "index.tsx" })] }) }), _jsx(AgentRecommendations, { "data-sentry-element": "AgentRecommendations", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentAside;
