import vars from '@shared/config/vars';
import { ObjectHelper } from '@shared/lib/helpers/object';
const { YM_DC_COUNTER } = vars();
const reachGoal = ({ targetName, params }) => {
    if (!SSR && typeof ym !== 'undefined') {
        ym(YM_DC_COUNTER, 'reachGoal', targetName, ObjectHelper.convertCamelCaseToSnakeCase(params));
    }
};
export const YandexMetricaHelper = {
    reachGoal
};
