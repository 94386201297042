import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { QueryStatus } from '@reduxjs/toolkit/query';
import { clsx } from 'clsx';
import { useEffect, Fragment } from 'react';
import { useSearchParams } from 'react-router-dom';
import { agentsSlice, agentsApi } from '@entities/agents';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { NumberHelper } from '@shared/lib/helpers/number';
import { UrlHelper } from '@shared/lib/helpers/url';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agentsList.scss';
import AgentsListEmpty from './components/emptyScreen';
import AgentsListHeader from './components/header';
import AgentsListResult from './components/result';
import AgentsListSkeleton from './components/skeleton';
import AgentsListStickyHeader from './components/stickyHeader';
const AgentsList = () => {
    var _a;
    const agents = useAppSelector(state => { var _a; return ((_a = state.agents) === null || _a === void 0 ? void 0 : _a.list) || []; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const sort = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.sort; });
    const isPendingList = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.isPendingList; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const pagination = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.pagination; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const dispatch = useAppDispatch();
    const [triggerAgents, { isFetching, isLoading }] = agentsApi.useLazyGetAgentsQuery();
    const [searchParams] = useSearchParams();
    const sortingOptions = ((_a = sort === null || sort === void 0 ? void 0 : sort.order) === null || _a === void 0 ? void 0 : _a.options) || [];
    const sortingStatus = sort === null || sort === void 0 ? void 0 : sort.status;
    const totalCount = (pagination === null || pagination === void 0 ? void 0 : pagination.total) || 0;
    const totalInRegion = (pagination === null || pagination === void 0 ? void 0 : pagination.totalInRegion) || 0;
    const isPending = sortingStatus === QueryStatus.pending || isLoading || isFetching || isPendingList;
    const isShowEmptyContainer = !agents.length && !isPending && !isPendingList;
    const isLoadingList = !SSR && isPending;
    const isEmptyList = !SSR && isShowEmptyContainer;
    const onlyCurrentRegion = agents.every(agent => agent.inSelectedRegion);
    const onlyOtherRegions = agents.every(agent => !agent.inSelectedRegion);
    const isFirstInRegion = !onlyCurrentRegion && !onlyOtherRegions;
    const initialized = async () => {
        const params = getQueryParams();
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.order) {
            await triggerAgents({
                ...params,
                regionId: (params === null || params === void 0 ? void 0 : params.regionId) || regionId,
                order: params.order
            });
            return;
        }
        if (sortingOptions[0]) {
            await triggerAgents({
                ...params,
                regionId: (params === null || params === void 0 ? void 0 : params.regionId) || regionId,
                order: sortingOptions[0].value
            });
        }
    };
    const getQueryParams = () => {
        let params = {
            ...queryParams
        };
        for (const queryParam in queryParams) {
            if (searchParams.has(queryParam)) {
                params = {
                    ...params,
                    [queryParam]: searchParams.get(queryParam)
                };
            }
            else if (searchParams.has('page')) {
                params = {
                    ...params,
                    offset: NumberHelper.getOffset(Number(searchParams.get('page')), pagination.limit)
                };
            }
        }
        return params;
    };
    const handleChangePage = (page) => {
        const offset = NumberHelper.getOffset(page, pagination.limit);
        sendMetrics({
            eventType: 'click',
            elementId: AgentsMetricHashes.ClickAgentListPagination,
            elementType: 'Pagination',
            eventSense: {
                page
            }
        });
        dispatch(agentsSlice.setQueryParams({
            offset
        }));
        if (!SSR) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 0);
        }
    };
    const handlePopState = () => {
        const urlParams = UrlHelper.getUrlParams();
        if (urlParams === null || urlParams === void 0 ? void 0 : urlParams.page) {
            const offset = NumberHelper.getOffset(Number(urlParams.page), pagination.limit);
            dispatch(agentsSlice.setQueryParams({
                offset
            }));
        }
        else {
            dispatch(agentsSlice.setQueryParams({
                offset: 0
            }));
        }
    };
    useEffect(() => {
        if (!SSR && !isWebView) {
            window.addEventListener('popstate', handlePopState);
        }
        return () => {
            if (!SSR && !isWebView) {
                window.removeEventListener('popstate', handlePopState);
            }
        };
    }, []);
    useEffect(() => {
        if (isEmptyList && sortingStatus === QueryStatus.fulfilled) {
            initialized().then();
        }
    }, [sortingStatus]);
    return _jsxs(Paper, { tag: "section", className: clsx(styles.container, {
            [styles.isEmpty]: isShowEmptyContainer
        }, {
            [styles.isWebView]: isWebView
        }), "data-sentry-element": "Paper", "data-sentry-component": "AgentsList", "data-sentry-source-file": "index.tsx", children: [isShowEmptyContainer ? _jsx(AgentsListEmpty, { regionId: queryParams.regionId || regionId }) : _jsxs(Fragment, { children: [_jsx(AgentsListHeader, { isLoading: isLoadingList, totalCount: totalCount, totalInRegion: totalInRegion, onlyOtherRegions: onlyOtherRegions }), _jsx(AgentsListStickyHeader, {})] }), _jsx(AgentsListResult, { agents: agents, isFirstInRegion: isFirstInRegion, isShow: !isLoadingList && !isShowEmptyContainer, onChangePage: handleChangePage, pagination: pagination, "data-sentry-element": "AgentsListResult", "data-sentry-source-file": "index.tsx" }), _jsx(AgentsListSkeleton, { isShowSkeleton: !isShowEmptyContainer && isLoadingList, "data-sentry-element": "AgentsListSkeleton", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentsList;
