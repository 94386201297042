import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownSelect, Size } from '@front-core/dropdown-select';
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useRef, memo, useMemo } from 'react';
import { agentsSlice, agentsApi } from '@entities/agents';
import { AgentsSortScope } from '@shared/config/common';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
const AgentsSort = () => {
    var _a, _b, _c, _d, _e;
    const agents = useAppSelector(state => state.agents.list);
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const storeSort = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.sort; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const isSearchDistrict = useAppSelector(agentsSlice.selectIsSearchDistrict);
    const dispatch = useAppDispatch();
    const hasSortOptions = useRef(Boolean((_b = (_a = storeSort.order) === null || _a === void 0 ? void 0 : _a.options) === null || _b === void 0 ? void 0 : _b.length)).current;
    const { data, isFetching, isLoading } = agentsApi.useGetAgentsSortQuery({
        scope: AgentsSortScope.List
    }, {
        skip: SSR || hasSortOptions
    });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = !isWebView && breakpoint !== BreakPoints.MOBILE;
    const sortOrder = storeSort === null || storeSort === void 0 ? void 0 : storeSort.order;
    const sortOptions = ((_c = data === null || data === void 0 ? void 0 : data.result.order) === null || _c === void 0 ? void 0 : _c.options) || (sortOrder === null || sortOrder === void 0 ? void 0 : sortOrder.options) || [];
    const currentSortOptions = useMemo(() => {
        const copyOptions = [...sortOptions];
        if (isSearchDistrict) {
            copyOptions.splice(0, 1);
            return copyOptions;
        }
        return copyOptions;
    }, [isSearchDistrict, sortOptions]);
    const handleChangeValue = (optionValue) => {
        const order = (typeof optionValue === 'string' && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.order) !== optionValue ? optionValue : null);
        if (order) {
            dispatch(agentsSlice.setQueryParams({
                order,
                offset: 0
            }));
            sendMetrics({
                eventType: 'select',
                elementId: sortOrder === null || sortOrder === void 0 ? void 0 : sortOrder.analyticsId,
                elementType: 'DropdownSelect',
                eventSense: {
                    regionId,
                    value: order
                }
            });
        }
    };
    const handleToggleDropDown = (isOpen) => {
        if (isOpen) {
            setTimeout(() => {
                sendMetrics({
                    eventType: 'click',
                    elementId: sortOrder === null || sortOrder === void 0 ? void 0 : sortOrder.analyticsId,
                    elementType: 'DropdownSelect',
                    eventSense: {
                        regionId
                    }
                });
            }, 0);
        }
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, isOpen);
        }
    };
    if (!SSR && (isFetching || isLoading)) {
        return _jsx(Skeleton, { type: SkeletonType.Text, width: "257px", height: "40px" });
    }
    return _jsx(DropdownSelect, { fluid: true, disabled: isRegionChangedEmptyList || agents.length === 0, value: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.order) || ((_d = currentSortOptions[0]) === null || _d === void 0 ? void 0 : _d.value), items: currentSortOptions, defaultValue: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.order) || ((_e = currentSortOptions[0]) === null || _e === void 0 ? void 0 : _e.value), onToggle: handleToggleDropDown, size: isDesktop ? Size.Medium : Size.Large, onValueChange: handleChangeValue, "data-sentry-element": "DropdownSelect", "data-sentry-component": "AgentsSort", "data-sentry-source-file": "index.tsx" });
};
export default memo(AgentsSort);
