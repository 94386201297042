import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Size, Intent } from '@front-core/button';
import { EmptyScreen } from '@front-core/empty-screen';
import { Illustration, Illustrations, IllustrationSize, IllustrationFormat } from '@front-core/illustration';
import { usePrevious, useBreakpoint, BreakPoints } from '@front-core/utils';
import { clsx } from 'clsx';
import { Fragment, useEffect } from 'react';
import { AgenciesSearchRegion } from '@features/agencies/searchRegion';
import { agenciesSlice } from '@entities/agencies';
import { UrlHelper } from '@shared/lib/helpers/url';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agenciesListEmptyScreen.scss';
const AgenciesListEmpty = ({ regionId }) => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const dispatch = useAppDispatch();
    const prevRegionId = usePrevious(regionId);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const urlSearchParams = UrlHelper.getUrlParams();
    const isChangedRegionId = String(prevRegionId) !== String(regionId);
    const isEmptySearchParams = Object.keys(urlSearchParams).length === 0;
    const getTitle = () => {
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) {
            return `${queryParams.search} нет на Домклик`;
        }
        return 'Такого агентства недвижимости нет на Домклик';
    };
    const handleClickResetSearchButton = () => {
        dispatch(agenciesSlice.resetQueryParams());
    };
    useEffect(() => {
        if (isChangedRegionId && isEmptySearchParams || isEmptySearchParams) {
            dispatch(agenciesSlice.setIsRegionChangedEmptyList(true));
        }
        return () => {
            dispatch(agenciesSlice.setIsRegionChangedEmptyList(false));
        };
    }, []);
    if (isChangedRegionId && isEmptySearchParams || isEmptySearchParams) {
        return _jsxs(Fragment, { children: [_jsx("div", { className: clsx({
                        [styles.agenciesListEmptyScreen]: isWebView
                    }), children: _jsx(EmptyScreen, { title: "\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043D\u0430\u0439\u0442\u0438 \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430", text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", image: _jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box" }), buttons: isMobile && !isWebView ? [_jsx(AgenciesSearchRegion, { buttonText: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", buttonIntent: Intent.Secondary, buttonSize: Size.Large }, "empty-screen-change-region")] : undefined }) }), isWebView ? _jsx(AgenciesSearchRegion, { buttonText: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", buttonIntent: Intent.Secondary, buttonSize: Size.Large }, "empty-screen-change-region") : null] });
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: clsx({
                    [styles.agenciesListEmptyScreen]: isWebView
                }), children: _jsx(EmptyScreen, { title: getTitle(), text: "\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435, \u043D\u0435\u0442 \u043B\u0438 \u043E\u0448\u0438\u0431\u043E\u043A \u0432 \u0434\u0430\u043D\u043D\u044B\u0445, \u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441", image: _jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box" }), buttons: !isWebView ? [_jsx(Button, { text: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A", onClick: handleClickResetSearchButton, size: Size.Large, intent: Intent.Secondary }, "search-reset-button")] : undefined, "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "index.tsx" }) }), isWebView ? _jsx("div", { className: styles.button, children: _jsx(Button, { fluid: true, text: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A", onClick: handleClickResetSearchButton, size: Size.Large, intent: Intent.Secondary }, "search-reset-button") }) : null] });
};
export default AgenciesListEmpty;
