import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { AgencyAgentsReviewsPeriod } from '@entities/agencies';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agencyReviews.scss';
import AgencyReviewsList from './components/list';
import AgencyReviewsMobile from './components/mobile';
import AgencyReviewsTitle from './components/title';
const AgencyReviews = () => {
    const reviews = useAppSelector(state => state.agencies.agency.reviews.list);
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const rating = useAppSelector(state => state.agencies.agency.details.rating);
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    const isPendingReview = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.isPending; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const hasReviewsEmptyList = !isPendingReview && !reviews.length && agencyReviewQueryParams.period === AgencyAgentsReviewsPeriod.All;
    if (hasReviewsEmptyList) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgencyReviews", "data-sentry-source-file": "index.tsx", children: [_jsx(AgencyReviewsTitle, { activeCount: rating === null || rating === void 0 ? void 0 : rating.votes, rating: rating === null || rating === void 0 ? void 0 : rating.avg, "data-sentry-element": "AgencyReviewsTitle", "data-sentry-source-file": "index.tsx" }), isMobile ? _jsx(AgencyReviewsMobile, {}) : _jsx(AgencyReviewsList, {})] });
};
export default AgencyReviews;
