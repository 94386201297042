import people1WebP from '../images/people-1.webp';
import people1WebP2x from '../images/people-1@2x.webp';
import people2WebP from '../images/people-2.webp';
import people2WebP2x from '../images/people-2@2x.webp';
import people3WebP from '../images/people-3.webp';
import people3WebP2x from '../images/people-3@2x.webp';
import people4WebP from '../images/people-4.webp';
import people4WebP2x from '../images/people-4@2x.webp';
export const AgentsSearchDistrictImages = [
    {
        src: people4WebP,
        src2x: people4WebP2x
    },
    {
        src: people3WebP,
        src2x: people3WebP2x
    },
    {
        src: people2WebP,
        src2x: people2WebP2x
    },
    {
        src: people1WebP,
        src2x: people1WebP2x
    }
];
