import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { setTopAppBarTitle } from '@rcf/webview-interfaces';
import { Fragment, useEffect, useRef, useState } from 'react';
import { generatePath, useMatch } from 'react-router-dom';
import { AgentAboutMe } from '@widgets/agent/aboutMe';
import { AgentAgency } from '@widgets/agent/agency';
import { AgentCurrentUserReview } from '@widgets/agent/currentUserReview';
import { AgentDeals } from '@widgets/agent/deals';
import { AgentDealsStats } from '@widgets/agent/dealsStats';
import { AgentInfo } from '@widgets/agent/info';
import { AgentMap } from '@widgets/agent/map';
import { AgentOffersRent } from '@widgets/agent/offers/rent';
import { AgentOffersSale } from '@widgets/agent/offers/sale';
import { AgentReviews } from '@widgets/agent/reviews';
import { AgentSpecializations } from '@widgets/agent/specializations';
import CSIAgent from '@widgets/csi/agent';
import { SEO } from '@widgets/seo';
import { AgentChatQuickForm } from '@features/agent/chat';
import { agentsApi, agentsSlice } from '@entities/agents';
import { PATH } from '@shared/config/constants';
import { ErrorCodes } from '@shared/config/errorCodes';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import Breadcrumbs from '@shared/ui/breadcrumbs';
import ErrorFallback from '@shared/ui/errorFallback';
import LayoutGridWithStickyAside from '@shared/ui/layout/gridWithStickyAside';
import styles from './agent.scss';
import AgentActionButtons from './components/actionButtons';
import AgentAside from './components/aside';
import AgentDisabled from './components/disabled';
import AgentFaq from './components/faq';
import AgentRecommendations from './components/recommendations';
import AgentPageSkeleton from './components/skeleton';
const { API_ENV } = vars();
const AgentPage = () => {
    var _a;
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const canonical = useAppSelector(state => { var _a; return (_a = state.url) === null || _a === void 0 ? void 0 : _a.canonical; });
    const agentErrors = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent) === null || _b === void 0 ? void 0 : _b.errors; });
    const geoCity = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city; });
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const isRobot = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isRobot; });
    const isUpdateAgentPage = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.isUpdateAgentPage; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const agentReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.queryParams; });
    const dispatch = useAppDispatch();
    const [triggerAgent, { isError }] = agentsApi.useLazyGetAgentQuery();
    const [triggerAgentReviews] = agentsApi.useLazyGetAgentReviewsQuery();
    const [triggerAgentDealsStats] = agentsApi.useLazyGetDealsStatsQuery();
    const [triggerAgentReviewsMe] = agentsApi.useLazyGetAgentReviewsMeQuery();
    const [isLoading, setIsLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const matchPath = useMatch(PATH.AGENT);
    const isFirstRenderRef = useRef(true);
    const currentAgentCasId = String(agent === null || agent === void 0 ? void 0 : agent.casId);
    const agentRegion = agent === null || agent === void 0 ? void 0 : agent.region;
    const geoLocation = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}`;
    const robotLocation = (agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.nameLoc) || `в городе ${agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.name}`;
    const locationName = isRobot ? robotLocation : geoLocation;
    const agentName = agent === null || agent === void 0 ? void 0 : agent.name;
    const slug = isRobot && (agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.slug) ? agentRegion.slug : regionSlug;
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint !== BreakPoints.DESKTOP && breakpoint !== BreakPoints.TABLET_LANDSCAPE;
    const isLoadingContent = isLoading || isUpdateAgentPage;
    const isShowResult = !isUpdateAgentPage && !isLoading && (agent === null || agent === void 0 ? void 0 : agent.casId);
    const isShowError = !((_a = matchPath === null || matchPath === void 0 ? void 0 : matchPath.params) === null || _a === void 0 ? void 0 : _a.agentCasId) || isError;
    const initialize = async () => {
        var _a;
        isFirstRenderRef.current = false;
        if (!((_a = matchPath === null || matchPath === void 0 ? void 0 : matchPath.params) === null || _a === void 0 ? void 0 : _a.agentCasId)) {
            return;
        }
        setIsLoading(true);
        try {
            const responseAgent = await triggerAgent({
                agentCasId: matchPath.params.agentCasId,
                companyRegionId: regionId
            });
            if (responseAgent === null || responseAgent === void 0 ? void 0 : responseAgent.data) {
                const agentCasId = String(responseAgent.data.result.casId);
                await triggerAgentReviews({
                    ...agentReviewQueryParams,
                    agentCasId,
                    offset: 0
                });
                await triggerAgentDealsStats(agentCasId);
                await triggerAgentReviewsMe({
                    agentCasId
                });
                dispatch(agentsSlice.setAgentReviewsQueryParams({
                    agentCasId,
                    offset: 0
                }));
            }
            if ((responseAgent === null || responseAgent === void 0 ? void 0 : responseAgent.error) && 'errors' in responseAgent.error) {
                const error = 'status' in responseAgent.error ? responseAgent.error.data.errors[0] : null;
                if (error) {
                    setIsDisabled(error.code === ErrorCodes.ProfileDisabled);
                }
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    };
    const getSeoTitle = () => {
        var _a;
        const name = agentName && ((_a = agent === null || agent === void 0 ? void 0 : agent.company) === null || _a === void 0 ? void 0 : _a.name) ? `${agent === null || agent === void 0 ? void 0 : agent.name}, ${agent === null || agent === void 0 ? void 0 : agent.company.name}` : agent.name;
        if (name) {
            return `Риэлтор ${name} ${locationName}: отзывы о риэлторе, контакты, объекты - Домклик`;
        }
        return '';
    };
    useEffect(() => {
        if (isWebView) {
            setTopAppBarTitle('Риелтор');
        }
        if (!SSR) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0
                });
            }, 0);
        }
    }, []);
    useEffect(() => {
        if ((matchPath === null || matchPath === void 0 ? void 0 : matchPath.params.agentCasId) !== (agent === null || agent === void 0 ? void 0 : agent.slug) && !SSR && isFirstRenderRef.current) {
            initialize().then();
        }
    }, [matchPath]);
    if (isDisabled || (agentErrors === null || agentErrors === void 0 ? void 0 : agentErrors.details)) {
        return _jsx(AgentDisabled, { regionSlug: slug, agentErrors: agentErrors });
    }
    return _jsxs(Fragment, { children: [_jsx(SEO, { title: getSeoTitle(), description: `${agentName} - риэлтор по недвижимости ${locationName}  🏆 Рейтинг риэлтора и актуальные данные по совершенным сделкам 💬 Отзывы от проверенных клиентов  📞 Контакты и актуальные объекты риэлтора`, keywords: `${agentName} риэлтор`, breadcrumbScript: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "item":
                   {
                     "@id": "${URL.MAIN[API_ENV]}",
                     "name": "Домклик"
                   }
              },
              {
                "@type": "ListItem",
                "position": "2",
                "item":
                   {
                     "@id": "${URL.AGENTS[API_ENV].replace('/:regionSlug', '')}",
                     "name": "🔸 АГЕНТЫ"
                   }
              },
              {
                "@type": "ListItem",
                "position": "3",
                "item":
                   {
                     "@id": "${generatePath(PATH.AGENTS, {
                    regionSlug: slug
                })}",
                     "name": "Поиск риелтора ${locationName}"
                   }
              },
              {
                "@type": "ListItem",
                "position": "4",
                "item":
                   {
                     "@id": "${canonical}",
                     "name": "Риелтор ${agentName}"
                   }
              }
            ]
          }`, "data-sentry-element": "SEO", "data-sentry-source-file": "index.tsx" }), _jsx(AgentPageSkeleton, { isPending: isLoadingContent, "data-sentry-element": "AgentPageSkeleton", "data-sentry-source-file": "index.tsx" }), _jsx(ErrorFallback, { isShow: isShowError, "data-sentry-element": "ErrorFallback", "data-sentry-source-file": "index.tsx" }), isShowResult ? _jsx(LayoutGridWithStickyAside, { mainContent: _jsxs(Fragment, { children: [_jsx(AgentInfo, {}), _jsx(AgentAboutMe, {}), _jsx(AgentSpecializations, {}), _jsx(AgentCurrentUserReview, {}), _jsx(AgentReviews, {}), _jsx(AgentAgency, {}), _jsx(AgentMap, {}), _jsx(AgentOffersSale, {}), _jsx(AgentOffersRent, {}), _jsx(AgentDeals, {}), isMobile && !isWebView ? _jsx(AgentChatQuickForm, {}) : null, _jsx(AgentDealsStats, {}), _jsx(CSIAgent, {}), _jsx(AgentFaq, { cityNameLocIn: locationName, regionId: regionId, agentCasId: currentAgentCasId }), isMobile || isWebView ? _jsxs(Fragment, { children: [_jsx(AgentRecommendations, {}), _jsx(AgentActionButtons, {})] }) : null] }), asideContent: _jsx(AgentAside, {}) }) : null, _jsx("div", { className: styles.breadcrumbs, children: _jsx(Breadcrumbs, { "data-sentry-element": "Breadcrumbs", "data-sentry-source-file": "index.tsx" }) })] });
};
export default AgentPage;
