import { jsx as _jsx } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { useEffect, useState } from 'react';
import { offersApi, OffersDealType, OffersList, OffersTitle } from '@entities/offers';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgencyOffersSale = () => {
    const media = useAppSelector(state => state.ui.media);
    const companyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [sales, setSales] = useState([]);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobileDevice = breakpoint === BreakPoints.MOBILE;
    const [triggerAgencyOffersSale, { isSuccess, isError, isLoading, data }] = offersApi.useLazyGetAgencyOffersSaleQuery();
    const pagination = data === null || data === void 0 ? void 0 : data.pagination;
    const fetchOffersSale = async (lastSeenId) => {
        const currentSales = structuredClone(sales);
        const response = await triggerAgencyOffersSale({
            queryParams: {
                companyId,
                dealType: OffersDealType.Sale,
                lastSeenId
            },
            isMobileDevice
        });
        if ('data' in response && response.data) {
            currentSales.push(...response.data.result);
        }
        setSales(currentSales);
    };
    const handleShowMore = () => {
        if (pagination) {
            fetchOffersSale(pagination.lastSeenId).then();
        }
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickButtonAgencySaleShowMore,
            elementType: 'Button',
            eventSense: {
                companyId,
                regionId
            }
        });
    };
    const sendItemAnalytics = (offerId) => {
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickLinkAgencySaleItem,
            elementType: 'Link',
            eventSense: {
                companyId,
                regionId,
                offerId
            }
        });
    };
    useEffect(() => {
        if (!SSR) {
            fetchOffersSale().then();
        }
    }, []);
    if (isSuccess && sales.length === 0 || isError) {
        return null;
    }
    return _jsx(OffersList, { offers: sales, title: OffersTitle.Sale, isLoading: isLoading, pagination: pagination, onLoadMore: handleShowMore, sendItemAnalytics: sendItemAnalytics, "data-sentry-element": "OffersList", "data-sentry-component": "AgencyOffersSale", "data-sentry-source-file": "index.tsx" });
};
export default AgencyOffersSale;
