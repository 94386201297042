import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import LocationIcon from '@front-core/icon/dist/Location';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgenciesSearchCityDesktop = () => {
    const cityName = useAppSelector(state => state.geo.city.name);
    const handleTriggerModal = () => {
        if (!SSR) {
            sendMetrics({
                eventType: 'click',
                elementId: AgenciesMetricHashes.ClickSearchCity,
                elementType: 'Button'
            });
            window.domclickGlobal.domclickLayout.showRegion();
        }
    };
    return _jsx(Button, { icon: _jsx(LocationIcon, {}), size: Size.Large, intent: Intent.Secondary, text: cityName, onClick: handleTriggerModal, "data-sentry-element": "Button", "data-sentry-component": "AgenciesSearchCityDesktop", "data-sentry-source-file": "index.tsx" });
};
export default AgenciesSearchCityDesktop;
