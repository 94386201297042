import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownSelect, Size } from '@front-core/dropdown-select';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
const AgencyAgentListSort = ({ isDisabled, orderValue, onChangeOrder }) => {
    var _a;
    const options = useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.agents) === null || _b === void 0 ? void 0 : _b.sort.order) === null || _c === void 0 ? void 0 : _c.options; });
    const agencyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const handleClick = (open) => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, open);
        }
        if (open) {
            sendMetrics({
                eventType: 'click',
                elementId: AgencyMetricHashes.ClickSort,
                elementType: 'DropdownSelect',
                eventSense: {
                    agencyId,
                    regionId
                }
            });
        }
    };
    return _jsx(DropdownSelect, { fluid: true, disabled: isDisabled, value: orderValue, items: options || [], defaultValue: (_a = options[0]) === null || _a === void 0 ? void 0 : _a.value, size: Size.Large, onToggle: handleClick, onValueChange: onChangeOrder, "data-sentry-element": "DropdownSelect", "data-sentry-component": "AgencyAgentListSort", "data-sentry-source-file": "index.tsx" });
};
export default AgencyAgentListSort;
