import { jsx as _jsx } from "react/jsx-runtime";
import SearchIcon from '@front-core/icon/dist/Search';
import { Input, Size, Type } from '@front-core/input';
import { debounce, usePrevious } from '@front-core/utils';
import { useEffect, useRef } from 'react';
import { agentsSlice } from '@entities/agents';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { EventHelper } from '@shared/lib/helpers/event';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const DELAY_IN_MS = 500;
const AgentsSearch = () => {
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const prevSearch = usePrevious(queryParams === null || queryParams === void 0 ? void 0 : queryParams.search);
    const inputRef = useRef(null);
    const handleChangeValue = debounce(DELAY_IN_MS, (value) => {
        const search = value.length > 0 ? value : null;
        const includeOtherRegions = Boolean(value.length);
        sendMetrics({
            eventType: 'input',
            elementId: AgentsMetricHashes.InputSearchNameOrPhone,
            elementType: 'Input',
            eventSense: {
                regionId,
                type: StringHelper.isPhoneNumber(value) ? 'phone' : 'name'
            }
        });
        if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) !== search) {
            dispatch(agentsSlice.setQueryParams({
                search,
                includeOtherRegions,
                offset: 0
            }));
        }
    });
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgentsMetricHashes.ClickSearchNameOrPhone,
            elementType: 'Input'
        });
    };
    useEffect(() => {
        if ((prevSearch === null || prevSearch === void 0 ? void 0 : prevSearch.length) && !(queryParams === null || queryParams === void 0 ? void 0 : queryParams.search)) {
            EventHelper.clearInput(inputRef);
        }
    }, [queryParams.search]);
    return _jsx(Input, { fluid: true, showClearIcon: true, disabled: isRegionChangedEmptyList, inputRef: inputRef, defaultValue: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) || undefined, leftSection: _jsx(SearchIcon, {}), label: "\u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", type: Type.Search, size: Size.Large, onValueChange: handleChangeValue, onFocus: handleFocusInput, "data-sentry-element": "Input", "data-sentry-component": "AgentsSearch", "data-sentry-source-file": "index.tsx" });
};
export default AgentsSearch;
