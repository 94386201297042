import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingLarge } from '@front-core/typography';
import { StringHelper } from '@shared/lib/helpers/string';
import advantage1PNG from '../images/advantage-1.png';
import advantage1WebP from '../images/advantage-1.webp';
import advantage1PNG2x from '../images/advantage-1@2x.png';
import advantage1WebP2x from '../images/advantage-1@2x.webp';
import advantage2PNG from '../images/advantage-2.png';
import advantage2WebP from '../images/advantage-2.webp';
import advantage2PNG2x from '../images/advantage-2@2x.png';
import advantage2WebP2x from '../images/advantage-2@2x.webp';
import advantage3PNG from '../images/advantage-3.png';
import advantage3WebP2x from '../images/advantage-3.webp';
import advantage3PNG2x from '../images/advantage-3@2x.png';
import advantage3WebP from '../images/advantage-3@2x.webp';
import FAQAgentAdvantagesItem from './components/item';
import styles from './faqAgentAdvantages.scss';
const FAQAgentAdvantages = () => _jsxs(Paper, { tag: "section", className: styles.faqAgentAdvantages, "data-sentry-element": "Paper", "data-sentry-component": "FAQAgentAdvantages", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Преимущества работы с риелтором') }), _jsxs("div", { className: styles.list, children: [_jsx(FAQAgentAdvantagesItem, { title: "\u0411\u0435\u0437\u043E\u043F\u0430\u0441\u043D\u043E\u0441\u0442\u044C", description: "\u042E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u0430\u044F \u0447\u0438\u0441\u0442\u043E\u0442\u0430 \u0441\u0434\u0435\u043B\u043E\u043A \u0441 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u044C\u044E", image: {
                        src: advantage1PNG,
                        src2x: advantage1PNG2x,
                        webpSrc: advantage1WebP,
                        webpSrc2x: advantage1WebP2x
                    }, "data-sentry-element": "FAQAgentAdvantagesItem", "data-sentry-source-file": "index.tsx" }), _jsx(FAQAgentAdvantagesItem, { title: "\u0421\u043F\u043E\u043A\u043E\u0439\u0441\u0442\u0432\u0438\u0435", description: "\u0412\u0441\u0451 \u043F\u043E\u0434 \u043A\u043E\u043D\u0442\u0440\u043E\u043B\u0435\u043C \u043D\u0430 \u0432\u0441\u0435\u0445 \u044D\u0442\u0430\u043F\u0430\u0445 \u0441\u0434\u0435\u043B\u043A\u0438", image: {
                        src: advantage2PNG,
                        src2x: advantage2PNG2x,
                        webpSrc: advantage2WebP,
                        webpSrc2x: advantage2WebP2x
                    }, "data-sentry-element": "FAQAgentAdvantagesItem", "data-sentry-source-file": "index.tsx" }), _jsx(FAQAgentAdvantagesItem, { title: "\u042D\u043A\u043E\u043D\u043E\u043C\u0438\u044F \u0432\u0440\u0435\u043C\u0435\u043D\u0438", description: "\u041E\u0442 \u043F\u043E\u0438\u0441\u043A\u0430 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438 \u0434\u043E \u043F\u043E\u0434\u0433\u043E\u0442\u043E\u0432\u043A\u0438 \u0434\u043E\u043A\u0443\u043C\u0435\u043D\u0442\u043E\u0432", image: {
                        src: advantage3PNG,
                        src2x: advantage3PNG2x,
                        webpSrc: advantage3WebP,
                        webpSrc2x: advantage3WebP2x
                    }, "data-sentry-element": "FAQAgentAdvantagesItem", "data-sentry-source-file": "index.tsx" })] })] });
export default FAQAgentAdvantages;
