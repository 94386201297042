import { jsx as _jsx } from "react/jsx-runtime";
import { useRef, useEffect, useState } from 'react';
import { dealsApi, DealsList } from '@entities/deals';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
const AgencyDeals = () => {
    const agencyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const [deals, setDeals] = useState([]);
    const buttonRef = useRef(null);
    const [triggerAgencyDeals, { isSuccess, isError, isLoading, data }] = dealsApi.useLazyGetAgencyDealsQuery();
    const pagination = data === null || data === void 0 ? void 0 : data.pagination;
    const fetchDeals = async (offsetValue = 0) => {
        const currentDeals = structuredClone(deals);
        const response = await triggerAgencyDeals({
            companyId: agencyId,
            offset: offsetValue
        });
        if ('data' in response && response.data) {
            currentDeals.push(...response.data.result);
        }
        setDeals(currentDeals);
    };
    const handleShowMore = () => {
        if (pagination) {
            fetchDeals(pagination.offset + pagination.limit).then();
        }
    };
    useEffect(() => {
        if (!SSR) {
            fetchDeals().then();
        }
    }, []);
    if (isSuccess && deals.length === 0 || isError) {
        return null;
    }
    return _jsx(DealsList, { deals: deals, isLoading: isLoading, pagination: pagination, buttonRef: buttonRef, onLoadMore: handleShowMore, "data-sentry-element": "DealsList", "data-sentry-component": "AgencyDeals", "data-sentry-source-file": "index.tsx" });
};
export default AgencyDeals;
