import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import { agentsSlice } from '@entities/agents';
import { ReviewEventTypes, reviewsApi, reviewsSlice, TypesModal } from '@entities/reviews';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgentReviewUpdateDeleteButton = ({ reviewId }) => {
    const agentCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const [deleteReview, { isLoading }] = reviewsApi.useDeleteReviewMutation();
    const handleClick = () => {
        deleteReview(reviewId).then(() => {
            dispatch(agentsSlice.finishAgentCreateReview());
            dispatch(reviewsSlice.toggleModals(TypesModal.List));
            sendMetrics({
                eventType: ReviewEventTypes.ReviewDeleted,
                elementId: AgentMetricHashes.SuccessResponseReview,
                eventSense: {
                    agentCasId,
                    regionId,
                    reviewId
                }
            });
        });
        sendMetrics({
            eventType: 'click',
            elementId: AgentMetricHashes.ClickButtonAgentReviewDelete,
            elementType: 'Button',
            eventSense: {
                reviewId,
                agentCasId,
                regionId
            }
        });
    };
    return _jsx(Button, { text: "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u043E\u0442\u0437\u044B\u0432", intent: Intent.DangerAlternative, size: Size.Medium, loading: isLoading, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-component": "AgentReviewUpdateDeleteButton", "data-sentry-source-file": "index.tsx" });
};
export default AgentReviewUpdateDeleteButton;
