import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button } from '@front-core/button';
import { Region } from '@front-core/region';
import { useState, Fragment } from 'react';
import { generatePath } from 'react-router-dom';
import { agentsSlice } from '@entities/agents';
import { geoApi } from '@entities/geo';
import { GEO } from '@shared/config/constants';
import { AgentsMetricHashes } from '@shared/config/metrics';
import vars from '@shared/config/vars';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
const { API_ENV } = vars();
const AgentsSearchRegion = ({ buttonText, buttonIcon, buttonSize, buttonIntent }) => {
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const urlPathPattern = useAppSelector(state => { var _a, _b; return (_b = (_a = state.url) === null || _a === void 0 ? void 0 : _a.path) === null || _b === void 0 ? void 0 : _b.pattern; });
    const agentCasId = useAppSelector(state => { var _a; return (_a = state.agents.agent.details) === null || _a === void 0 ? void 0 : _a.casId; });
    const companyId = useAppSelector(state => { var _a; return (_a = state.agencies.agency.details) === null || _a === void 0 ? void 0 : _a.id; });
    const dispatch = useAppDispatch();
    const [triggerRegion] = geoApi.useLazyGetRegionQuery();
    const [isOpen, setIsOpen] = useState(false);
    const updateRegion = async (guid) => {
        try {
            const response = await triggerRegion(guid);
            if ('data' in response && response.data) {
                const regionSlug = response.data.result.slug;
                const path = generatePath(urlPathPattern, {
                    regionSlug,
                    agentCasId,
                    companyId
                });
                history.replaceState({}, '', path);
                dispatch(agentsSlice.putQueryParams({
                    regionId: response.data.result.uid
                }));
            }
        }
        catch (error) {
            console.info(error);
        }
    };
    const handleChangeRegion = options => {
        updateRegion(options.localityGuid || options.regionGuid || GEO.GUID).then();
    };
    const handleTriggerModal = () => {
        if (!SSR) {
            sendMetrics({
                eventType: 'click',
                elementId: AgentsMetricHashes.ClickSearchCity,
                elementType: 'Button'
            });
            if (isWebView && userAgent) {
                setIsOpen(true);
                setWebViewVisibilityTopBar(userAgent, true);
            }
            else {
                window.domclickGlobal.domclickLayout.showRegion();
            }
        }
    };
    const handleCloseModal = () => {
        setIsOpen(false);
        if (userAgent) {
            setWebViewVisibilityTopBar(userAgent, false);
        }
    };
    return _jsxs(Fragment, { children: [_jsx(Button, { "aria-label": "\u041E\u0442\u043A\u0440\u044B\u0442\u044C \u043F\u043E\u0438\u0441\u043A \u043F\u043E \u0440\u0435\u0433\u0438\u043E\u043D\u0430\u043C", text: buttonText, icon: buttonIcon, size: buttonSize, intent: buttonIntent, onClick: handleTriggerModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isWebView ? _jsx(Region, { env: API_ENV, open: isOpen, onChangeRegion: handleChangeRegion, callBackCloseRegion: handleCloseModal }) : null] });
};
export default AgentsSearchRegion;
