import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag, TagSize } from '@front-core/badge';
import { SkeletonType, Skeleton } from '@front-core/skeleton';
import { LabelStandardMedium, LabelStandardRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { memo } from 'react';
import { AgentsSearchDistrict, SearchDistrictTypes } from '@features/agents/search/district';
import { AgentsSort } from '@features/agents/sort';
import { agentsSlice } from '@entities/agents';
import { Unicode } from '@shared/config/unicode';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsListHeader.scss';
const AgentsListHeader = ({ totalCount, totalInRegion, isLoading, onlyOtherRegions }) => {
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const cityNameLocIn = useAppSelector(state => { var _a, _b; return (_b = (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.nameLocIn; });
    const cityName = useAppSelector(state => { var _a, _b; return (_b = (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city) === null || _b === void 0 ? void 0 : _b.name; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint !== BreakPoints.MOBILE;
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const getResultText = () => {
        if (onlyOtherRegions) {
            return 'Риелторы с такими данными есть в других регионах';
        }
        const count = NumberHelper.getNumberLocaleFormat((queryParams === null || queryParams === void 0 ? void 0 : queryParams.includeOtherRegions) && totalInRegion && totalInRegion < totalCount ? totalInRegion : totalCount);
        const countWithoutSpaces = count === null || count === void 0 ? void 0 : count.replace(Unicode.NoBreakSpace, '');
        const findType = StringHelper.getPluralForm(Number(countWithoutSpaces), ['риелтор', 'риелтора', 'риелторов']);
        const findText = StringHelper.getPluralForm(Number(countWithoutSpaces), ['Найден', 'Найдено', 'Найдено']);
        if (isWebView) {
            return `${findText} ${count} ${findType} ${cityNameLocIn || cityName}`;
        }
        return `${findText} ${count} ${findType}`;
    };
    const handleCloseClick = () => {
        dispatch(agentsSlice.setQueryParams({
            search: null
        }));
    };
    return _jsxs("div", { className: styles.agentsListHeader, "data-sentry-component": "AgentsListHeader", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: styles.header, children: [_jsxs("div", { className: styles.headerTexts, children: [isLoading ? _jsx(Skeleton, { type: SkeletonType.Text, width: "255px", height: "24px" }) : _jsx(LabelStandardMedium, { tag: "h2", className: styles.headerDescription, wrapper: false, children: getResultText() }), _jsx(AgentsSearchDistrict, { type: SearchDistrictTypes.Secondary, "data-sentry-element": "AgentsSearchDistrict", "data-sentry-source-file": "index.tsx" }), _jsx(LabelStandardRegular, { tag: "p", wrapper: false, className: styles.headerSubtitle, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433 \u0438 \u0441\u0434\u0435\u043B\u043A\u0438 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u044E\u0442\u0441\u044F \u0437\u0430 12 \u043C\u0435\u0441\u044F\u0446\u0435\u0432" })] }), isDesktop ? _jsx("div", { className: styles.headerSort, children: _jsx(AgentsSort, {}) }) : null] }), isMobile && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) && queryParams.search.length > 0 ? _jsx("div", { className: styles.headerTags, children: _jsx(Tag, { size: TagSize.Large, text: queryParams.search, onCloseClick: handleCloseClick }) }) : null] });
};
export default memo(AgentsListHeader);
