import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingSmall } from '@front-core/typography';
import { AgentReviewAnswerUpdate, AgentReviewAnswerUpdateDropDown } from '@features/agent/review/answer/update';
import { AgentReviewUpdate } from '@features/agent/review/update';
import { ReviewsCard, ReviewsScope } from '@entities/reviews';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentCurrentUserReview.scss';
const AgentCurrentUserReview = () => {
    const reviewsMe = useAppSelector(state => state.agents.agent.reviews.me);
    const userCasId = useAppSelector(state => state.user.casId);
    const agentId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const checkCurrentUser = (id) => Boolean(id && userCasId && userCasId === id);
    if (!reviewsMe.length) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", "data-sentry-element": "Paper", "data-sentry-component": "AgentCurrentUserReview", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingSmall, { "data-sentry-element": "HeadingSmall", "data-sentry-source-file": "index.tsx", children: "\u0412\u044B \u043E\u0446\u0435\u043D\u0438\u043B\u0438 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430" }), _jsx("ul", { className: styles.reviewCards, children: reviewsMe.map(review => {
                    var _a;
                    return _jsx("li", { className: styles.reviewCard, children: _jsx(ReviewsCard, { answer: review.answer, review: review, scope: ReviewsScope.Agent, reviewEditFeature: checkCurrentUser((_a = review.author) === null || _a === void 0 ? void 0 : _a.id) ? _jsx(AgentReviewUpdate, { review: review }) : null, answerToReviewFeature: checkCurrentUser(agentId) && !review.answer ? _jsx(AgentReviewAnswerUpdate, { review: review }) : null, answerToReviewDropDown: checkCurrentUser(agentId) && review.answer ? _jsx(AgentReviewAnswerUpdateDropDown, { review: review }) : null }) }, review.id);
                }) })] });
};
export default AgentCurrentUserReview;
