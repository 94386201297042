import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import { useState, Fragment, useEffect } from 'react';
import { AgentReviewAnswerUpdate } from '@features/agent/review/answer/update';
import { AgentReviewUpdate } from '@features/agent/review/update';
import { agenciesSlice, AgencyAgentsReviewsPeriod } from '@entities/agencies';
import { reviewsSlice, TypesModal, ReviewsCardReview, ReviewsScope, ReviewsListSkeleton } from '@entities/reviews';
import { ContextActiveReviewDataId } from '@shared/lib/contexts/agent/activeReview';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import AgencyReviewsEmptyListForTheYear from '../emptyListForTheYear';
import ReviewBottomSheetList from '../review/reviewBottomSheetList';
import styles from './agencyReviewsMobile.scss';
const AgencyReviewsMobile = () => {
    const reviews = useAppSelector(state => { var _a; return (_a = state.agencies.agency) === null || _a === void 0 ? void 0 : _a.reviews.list; });
    const modals = useAppSelector(state => { var _a; return (_a = state.reviews) === null || _a === void 0 ? void 0 : _a.modals; });
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    const isPendingReview = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.isPending; });
    const previewReviews = useAppSelector(agenciesSlice.selectPreviewReviewsList);
    const dispatch = useAppDispatch();
    const [activeCardDataId, setActiveCardDataId] = useState(null);
    const isReviewListModalVisibility = Boolean(modals === null || modals === void 0 ? void 0 : modals.list);
    const hasReviewsEmptyListForTheYear = !reviews.length && !isReviewListModalVisibility && agencyReviewQueryParams.period === AgencyAgentsReviewsPeriod.Year;
    const closeModal = () => {
        if (isReviewListModalVisibility) {
            dispatch(reviewsSlice.toggleModals(TypesModal.List));
        }
    };
    const checkCurrentUser = (id) => Boolean(id && userCasId && userCasId === id);
    const handleToggleReviewModal = () => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, !(modals === null || modals === void 0 ? void 0 : modals.list));
        }
        setActiveCardDataId(null);
        dispatch(reviewsSlice.toggleModals(TypesModal.List));
    };
    const handleClick = (id) => {
        setActiveCardDataId(id);
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, !(modals === null || modals === void 0 ? void 0 : modals.list));
        }
        dispatch(reviewsSlice.toggleModals(TypesModal.List));
    };
    useEffect(() => {
        if (!SSR && isWebView) {
            closeModal();
        }
        return () => {
            if (!SSR && isWebView) {
                closeModal();
            }
        };
    }, []);
    if (hasReviewsEmptyListForTheYear) {
        return _jsx(AgencyReviewsEmptyListForTheYear, {});
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: styles.agencyReviewsMobile, children: isPendingReview && !isReviewListModalVisibility ? _jsx(ReviewsListSkeleton, { length: 3 }) : previewReviews.map(previewReview => { var _a, _b; return _jsx(ReviewsCardReview, { isPreview: true, scope: ReviewsScope.Agency, review: previewReview, onClick: handleClick, reviewEditFeature: checkCurrentUser((_a = previewReview.author) === null || _a === void 0 ? void 0 : _a.id) ? _jsx(AgentReviewUpdate, { review: previewReview }) : null, answerToReviewFeature: checkCurrentUser((_b = previewReview.agent) === null || _b === void 0 ? void 0 : _b.casId) && !previewReview.answer ? _jsx(AgentReviewAnswerUpdate, { review: previewReview }) : null }, previewReview.id); }) }), _jsx(Button, { size: Size.Large, intent: Intent.Secondary, text: "\u0412\u0441\u0435 \u043E\u0442\u0437\u044B\u0432\u044B", onClick: handleToggleReviewModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isReviewListModalVisibility ? _jsx(ContextActiveReviewDataId.Provider, { value: activeCardDataId, children: _jsx(ReviewBottomSheetList, { isOpen: isReviewListModalVisibility, onClose: handleToggleReviewModal }) }) : null] });
};
export default AgencyReviewsMobile;
