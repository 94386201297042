import { jsx as _jsx } from "react/jsx-runtime";
import { Tabs, TabsTheme, TabsSize } from '@front-core/tabs';
import { memo } from 'react';
import { AgencyAgentsReviewsPeriod, agenciesSlice, agenciesApi } from '@entities/agencies';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { Periods } from '../config/periods';
const AgencyReviewPeriodFilter = () => {
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    const dispatch = useAppDispatch();
    const [triggerAgencyReviews] = agenciesApi.useLazyGetAgencyReviewsQuery();
    const handleChangeValue = async (value) => {
        const reviewPeriod = value;
        if (agencyReviewQueryParams.period !== reviewPeriod) {
            sendMetrics({
                elementId: AgencyMetricHashes.ClickFilterPeriodReview,
                eventType: 'click',
                elementType: 'tab',
                eventSense: {
                    period: reviewPeriod
                }
            });
            dispatch(agenciesSlice.setAgencyReviewsQueryParams({
                period: reviewPeriod,
                offset: 0
            }));
            await triggerAgencyReviews({
                ...agencyReviewQueryParams,
                period: reviewPeriod,
                offset: 0
            });
        }
    };
    return _jsx(Tabs, { fluid: true, defaultValue: AgencyAgentsReviewsPeriod.All, size: TabsSize.Medium, tabs: Periods, value: agencyReviewQueryParams.period, onValueChange: handleChangeValue, theme: TabsTheme.Alternative, "data-sentry-element": "Tabs", "data-sentry-component": "AgencyReviewPeriodFilter", "data-sentry-source-file": "index.tsx" });
};
export default memo(AgencyReviewPeriodFilter);
