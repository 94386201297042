import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
const useGoBack = (defaultRoute) => {
    const navigation = useNavigate();
    const location = useLocation();
    return useCallback(() => {
        var _a;
        if ((_a = location.state) === null || _a === void 0 ? void 0 : _a.canGoBack) {
            navigation(-1);
        }
        else {
            navigation(defaultRoute, { replace: true });
        }
    }, [location, defaultRoute]);
};
export default useGoBack;
