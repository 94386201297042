import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Intent } from '@front-core/button';
import { HeadingXSmall, LabelStandardRegular } from '@front-core/typography';
import { AgentReviewCreate } from '@features/agent/review/create';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingType, RatingFontSize } from '@shared/ui/layout/rating/config';
import AgentReviewsControls from '../controls';
import styles from './agentReviewsTitle.scss';
const AgentReviewsTitle = ({ activeCount, rating }) => {
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const agentCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const isUser = Number(userCasId) !== Number(agentCasId);
    const hasActiveReviews = activeCount && activeCount > 0;
    return _jsxs("div", { className: styles.agentReviewsTitle, "data-sentry-component": "AgentReviewsTitle", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: styles.container, children: [_jsx(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E\u0442\u0437\u044B\u0432\u044B \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u0421\u0431\u0435\u0440\u0411\u0430\u043D\u043A\u0430" }), hasActiveReviews ? _jsxs("div", { className: styles.rating, children: [_jsx(LayoutRating, { hasGrade: true, fontSize: RatingFontSize.Medium, type: RatingType.Secondary, rating: rating || 0 }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.totalRating, children: "\u0438\u0437 5" })] }) : null, isUser ? _jsx("div", { className: styles.buttonWrapper, children: _jsx(AgentReviewCreate, { intentButton: Intent.Primary }) }) : null] }), _jsx(AgentReviewsControls, { "data-sentry-element": "AgentReviewsControls", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentReviewsTitle;
