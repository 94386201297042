import { openAuth, AuthType, openComplementaryRoom } from '@rcf/webview-interfaces';
import { useLocation } from 'react-router-dom';
import { agentsApi } from '@entities/agents';
import { userSlice } from '@entities/user';
import { PATH } from '@shared/config/constants';
import { AgentMetricHashes, AgentsMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { webViewAuth } from '@shared/lib/webViewAuth';
export const useCreateChat = (params) => {
    const agentDetailsCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const userCasIdSigned = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casIdSigned; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const urlPathPattern = useAppSelector(state => state.url.path.pattern);
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const { pathname } = useLocation();
    const [triggerAgentChatOptions, { isLoading, isFetching }] = agentsApi.useLazyGetAgentChatOptionsQuery();
    const currentAgentCasId = (params === null || params === void 0 ? void 0 : params.agentCasId) || agentDetailsCasId;
    const fetchOptions = async (messageStub) => {
        const response = await triggerAgentChatOptions(currentAgentCasId);
        if (response === null || response === void 0 ? void 0 : response.data) {
            const { result } = response.data;
            if (isWebView) {
                openWebViewRoom(result);
            }
            else {
                const chat = window === null || window === void 0 ? void 0 : window.chat;
                const complementaryRoomParams = messageStub && messageStub.length > 0 ? {
                    ...result,
                    blank: {
                        ...result.blank,
                        messageStub,
                        messageStubRoomExists: messageStub
                    }
                } : result;
                chat === null || chat === void 0 ? void 0 : chat.setComplementaryRoom(complementaryRoomParams);
                chat === null || chat === void 0 ? void 0 : chat.open().then();
            }
        }
        if (response === null || response === void 0 ? void 0 : response.error) {
            const responseError = response.error;
            throw 'status' in responseError ? responseError.data.errors[0] : null;
        }
    };
    const triggerChatOptions = async (chatParams) => {
        var _a;
        const currentMetricsParams = structuredClone({
            elementId: urlPathPattern === PATH.AGENTS ? AgentsMetricHashes.ClickButtonWrite : AgentMetricHashes.ClickButtonWrite,
            ...chatParams.metricsParams
        });
        try {
            const isAuthUser = currentAgentCasId && (userCasId || userCasIdSigned);
            if (isAuthUser) {
                await fetchOptions(chatParams === null || chatParams === void 0 ? void 0 : chatParams.messageStub);
            }
            if (isWebView && !userCasIdSigned) {
                openAuth(AuthType.STANDARD, pathname);
                webViewAuth(casIdSigned => {
                    dispatch(userSlice.setCasIdSigned(casIdSigned));
                });
            }
            currentMetricsParams.eventSense.regionId = regionId;
        }
        catch (error) {
            const currentError = error;
            currentMetricsParams.eventType = 'error';
            currentMetricsParams.eventSense.code = currentError === null || currentError === void 0 ? void 0 : currentError.code;
            currentMetricsParams.eventSense.period = ((_a = currentError === null || currentError === void 0 ? void 0 : currentError.details) === null || _a === void 0 ? void 0 : _a.period) ? currentError.details.period : undefined;
            throw error;
        }
        finally {
            sendMetrics(currentMetricsParams);
        }
    };
    const openWebViewRoom = (result) => {
        var _a, _b, _c;
        const { blank } = result;
        const blankBanner = (blank === null || blank === void 0 ? void 0 : blank.banner)
            ? {
                photo: ((_a = blank.banner) === null || _a === void 0 ? void 0 : _a.imgUrl) || '',
                link: ((_b = blank.banner) === null || _b === void 0 ? void 0 : _b.redirectUrl) || '',
                subtitle: ((_c = blank.banner) === null || _c === void 0 ? void 0 : _c.subtitle) || '',
                title: blank.banner.title
            } : {
            title: '',
            subtitle: '',
            photo: '',
            link: ''
        };
        openComplementaryRoom(result.tags, blank.roomName, (blank === null || blank === void 0 ? void 0 : blank.messageStub) || '', blankBanner, result.request);
    };
    return {
        triggerChatOptions,
        isLoading,
        isFetching
    };
};
