import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import FilterIcon from '@front-core/icon/dist/Filter';
import { Fragment, useState, useEffect } from 'react';
import { AgentsDealType } from '@features/agents/dealType';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import AgentsSearchCityMobile from '../search/city/mobile';
import AgentsFiltersModal from './modal';
const AgentsFilters = () => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleTriggerModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    useEffect(() => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, isModalOpen);
        }
    }, [isModalOpen]);
    return _jsxs(Fragment, { children: [_jsx(Button, { icon: _jsx(FilterIcon, {}), size: Size.Large, intent: Intent.Secondary, onClick: handleTriggerModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isModalOpen ? _jsx(AgentsFiltersModal, { isModalOpen: isModalOpen, onClose: handleTriggerModal, children: _jsxs(Fragment, { children: [_jsx(AgentsSearchCityMobile, {}), _jsx(AgentsDealType, {})] }) }) : null] });
};
export default AgentsFilters;
