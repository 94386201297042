import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useState } from 'react';
import { agenciesApi } from '@entities/agencies';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agencyOffices.scss';
import AgencyOfficeListResult from './components/result';
import AgencyOfficeListSkeleton from './components/skeleton';
const defaultParams = {
    offset: 0,
    limit: 5
};
const AgencyOffices = () => {
    const offices = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.offices.list; });
    const pagination = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.offices) === null || _b === void 0 ? void 0 : _b.pagination; });
    const cityNameLocIn = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city.nameLocIn; });
    const cityName = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city.name; });
    const regionId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region) === null || _b === void 0 ? void 0 : _b.guid; });
    const companyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency) === null || _b === void 0 ? void 0 : _b.details.id; });
    const [triggerAgencyOffices, { isFetching, isLoading }] = agenciesApi.useLazyGetAgenciesOfficesQuery();
    const [queryParams, setQueryParams] = useState({
        ...defaultParams,
        regionId,
        companyId
    });
    const isPending = isLoading || isFetching;
    const updateOffices = async (params) => {
        try {
            await triggerAgencyOffices({
                ...queryParams,
                ...params
            });
        }
        catch (error) {
            console.info(error);
        }
    };
    const getOfficesCount = () => {
        const locationName = cityNameLocIn || cityName;
        if (pagination === null || pagination === void 0 ? void 0 : pagination.total) {
            return `${StringHelper.getPluralForm(pagination.total, ['офис', 'офиса', 'офисов'], true)} ${locationName}`;
        }
        return `${StringHelper.getPluralForm(0, ['офис', 'офиса', 'офисов'], true)} ${locationName}`;
    };
    const handleChangePage = (page) => {
        const offset = NumberHelper.getOffset(page, queryParams.limit);
        setQueryParams(prevState => ({
            ...prevState,
            offset
        }));
        updateOffices({
            offset
        }).then();
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickOfficesListPagination,
            elementType: 'Pagination',
            eventSense: {
                page,
                agencyId: companyId,
                regionId
            }
        });
    };
    if (!(offices === null || offices === void 0 ? void 0 : offices.length)) {
        return null;
    }
    return _jsxs(Paper, { className: styles.agencyOffices, "data-sentry-element": "Paper", "data-sentry-component": "AgencyOffices", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: getOfficesCount() }), _jsx(AgencyOfficeListResult, { isShow: !isPending, offices: offices, pagination: pagination, onChangePage: handleChangePage, "data-sentry-element": "AgencyOfficeListResult", "data-sentry-source-file": "index.tsx" }), _jsx(AgencyOfficeListSkeleton, { isShowSkeleton: isPending, "data-sentry-element": "AgencyOfficeListSkeleton", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyOffices;
