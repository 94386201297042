import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GalleryPreview } from '@front-core/gallery-preview';
import { GalleryViewModal, ModalSize } from '@front-core/gallery-view';
import ArrowDownIcon from '@front-core/icon/dist/ArrowDown';
import { Picture } from '@front-core/picture';
import { LabelStandardMedium } from '@front-core/typography';
import { useState, Fragment } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import hatPng from '../../../images/hat.png';
import hatWebp from '../../../images/hat.webp';
import hat2xPng from '../../../images/hat@2x.png';
import hat2xWebp from '../../../images/hat@2x.webp';
import styles from './achievementsMobile.scss';
const AchievementsMobile = ({ slots }) => {
    const ui = useAppSelector(state => state.ui);
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const slicedSlots = slots.length > 6 ? slots.slice(0, 6) : slots;
    const handleToggleGallery = () => {
        setIsShowGallery(prevState => {
            var _a;
            if (ui.isWebView && (ui === null || ui === void 0 ? void 0 : ui.userAgent)) {
                setWebViewVisibilityTopBar((_a = ui.userAgent) === null || _a === void 0 ? void 0 : _a.ua, !prevState);
            }
            return !prevState;
        });
    };
    const handleChangeSlide = (index) => {
        setActiveSlide(index);
    };
    if (slicedSlots.length === 0) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsxs("button", { className: styles.achievementsMobile, onClick: handleToggleGallery, children: [_jsx(Picture, { src: hatPng, src2x: hat2xPng, webpSrc: hatWebp, webpSrc2x: hat2xWebp, alt: "", width: 40, height: 40, "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" }), _jsxs(LabelStandardMedium, { wrapper: false, className: styles.title, tag: "strong", "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: ["\u0414\u0438\u043F\u043B\u043E\u043C\u044B, \u0430\u0442\u0442\u0435\u0441\u0442\u0430\u0442\u044B, \u043D\u0430\u0433\u0440\u0430\u0434\u044B ", _jsx("span", { className: styles.count, children: slicedSlots.length })] }), _jsx(ArrowDownIcon, { className: styles.arrowIcon, "data-sentry-element": "ArrowDownIcon", "data-sentry-source-file": "index.tsx" })] }), isShowGallery ? _jsx(GalleryViewModal, { slots: slicedSlots, open: isShowGallery, active: activeSlide, modalProps: {
                    size: ModalSize.Large
                }, onClose: handleToggleGallery, onChangeSlide: handleChangeSlide, renderFooter: (activeSlot, activeIndex) => _jsx(GalleryPreview, { activeSlide: activeIndex, items: activeSlot, onValueChange: handleChangeSlide }) }) : null] });
};
export default AchievementsMobile;
