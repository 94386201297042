import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Picture } from '@front-core/picture';
import { PromoSmall } from '@front-core/typography';
import { clsx } from 'clsx';
import { StringHelper } from '@shared/lib/helpers/string';
import styles from './layoutHero.scss';
const LayoutHero = ({ image, title, children, isFullWidthTitle, className, classNameTitle, classNameContent, classNameImage }) => _jsxs(Paper, { tag: "section", className: clsx(styles.layoutHero, {
        [styles.isFullWidthTitle]: isFullWidthTitle
    }, className), "data-sentry-element": "Paper", "data-sentry-component": "LayoutHero", "data-sentry-source-file": "index.tsx", children: [isFullWidthTitle ? _jsx(PromoSmall, { tag: "h1", wrapper: false, className: clsx(styles.title, classNameTitle), children: StringHelper.addNbsp(title) }) : null, _jsxs("div", { className: clsx(styles.content, classNameContent), children: [!isFullWidthTitle ? _jsx(PromoSmall, { tag: "h1", wrapper: false, className: styles.title, children: StringHelper.addNbsp(title) }) : null, children] }), _jsx(Picture, { src: image.src, srcSet: image.srcSet, src2x: image.src2x, webpSrc: image.webpSrc2x, webpSrc2x: image.webpSrc2x, sources: image.sources, className: clsx(styles.picture, classNameImage), width: image.width, height: image.height, alt: "", "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" })] });
export default LayoutHero;
