import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Size } from '@front-core/button';
import { Picture } from '@front-core/picture';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { FAQAgentHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import CustomLink from '@shared/ui/customLink';
import LayoutBox from '@shared/ui/layout/box';
import desktopPhonePNG from '../images/desktop.phone.png';
import desktopPhoneWebP from '../images/desktop.phone.webp';
import desktopPhonePNG2x from '../images/desktop.phone@2x.png';
import desktopPhoneWebP2x from '../images/desktop.phone@2x.webp';
import mobilePhonePNG from '../images/mobile.phone.png';
import mobilePhoneWebP from '../images/mobile.phone.webp';
import mobilePhonePNG2x from '../images/mobile.phone@2x.png';
import mobilePhoneWebP2x from '../images/mobile.phone@2x.webp';
import tabletPhonePNG from '../images/tablet.phone.png';
import tabletPhoneWebP from '../images/tablet.phone.webp';
import tabletPhonePNG2x from '../images/tablet.phone@2x.png';
import tabletPhoneWebP2x from '../images/tablet.phone@2x.webp';
import styles from './faqHowMatchFindAgent.scss';
const FAQHowMatchFindAgent = () => {
    const media = useAppSelector(state => state.ui.media);
    const geo = useAppSelector(state => state.geo);
    const { region: { guid: regionId }, slug: regionSlug } = geo;
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const routeToAgents = generatePath(PATH.AGENTS, {
        regionSlug
    });
    const getImageSize = () => {
        switch (breakpoint) {
            case BreakPoints.MOBILE:
                return {
                    width: 343,
                    height: 276
                };
            case BreakPoints.TABLET_PORTRAIT:
                return {
                    width: 342,
                    height: 287
                };
            default:
                return {
                    width: 500,
                    height: 276
                };
        }
    };
    const handleClickButton = () => {
        sendMetrics({
            elementId: FAQAgentHashes.ClickSearchAgentsButton,
            eventType: 'click',
            elementType: 'Button',
            eventSense: {
                regionId
            }
        });
        window.scrollTo(0, 0);
    };
    return _jsx(LayoutBox, { className: styles.faqHowMatchFindAgent, classNameContent: styles.content, classNameTitle: styles.title, classNameImageContainer: styles.imageContainer, title: "300 000 \u0447\u0435\u043B\u043E\u0432\u0435\u043A \u0438\u0449\u0443\u0442 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432 \u043D\u0430 \u0414\u043E\u043C\u043A\u043B\u0438\u043A \u0435\u0436\u0435\u043C\u0435\u0441\u044F\u0447\u043D\u043E", image: _jsx(Picture, { src: mobilePhonePNG, src2x: mobilePhonePNG2x, sources: [{
                    type: 'image/webp',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopPhoneWebP} 1x, ${desktopPhoneWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopPhonePNG} 1x, ${desktopPhonePNG2x} 2x`
                }, {
                    type: 'image/webp',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletPhoneWebP} 1x, ${tabletPhoneWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletPhonePNG} 1x, ${tabletPhonePNG2x} 2x`
                }, {
                    type: 'image/webp',
                    srcSet: `${mobilePhoneWebP} 1x, ${mobilePhoneWebP2x} 2x`
                }], alt: "", width: getImageSize().width, height: getImageSize().height, loading: "lazy" }), "data-sentry-element": "LayoutBox", "data-sentry-component": "FAQHowMatchFindAgent", "data-sentry-source-file": "index.tsx", children: _jsx(Button, { fluid: true, text: "\u041A \u0440\u0430\u0437\u0434\u0435\u043B\u0443 \u00AB\u0420\u0438\u0435\u043B\u0442\u043E\u0440\u044B\u00BB", size: Size.Large, routerLink: _jsx(CustomLink, { to: routeToAgents }), wrapperClassName: styles.button, onClick: handleClickButton, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }) });
};
export default FAQHowMatchFindAgent;
