export const getSearchDistrictDefaultIds = (districts, selectedIds) => {
    if (!(selectedIds === null || selectedIds === void 0 ? void 0 : selectedIds.length)) {
        return [];
    }
    return districts.reduce((acc, district) => {
        var _a;
        const childrenIds = ((_a = district.children) === null || _a === void 0 ? void 0 : _a.map(child => child.id)) || [];
        const isParentWithChildren = childrenIds.length > 0;
        if (isParentWithChildren) {
            const isParentSelected = selectedIds.includes(district.id);
            if (isParentSelected) {
                acc.push(district.id);
                acc.push(...childrenIds);
            }
            else {
                const selectedChildren = childrenIds.filter(id => selectedIds.includes(id));
                acc.push(...selectedChildren);
            }
        }
        else if (selectedIds.includes(district.id)) {
            acc.push(district.id);
        }
        return acc;
    }, []);
};
