import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import qs from 'qs';
import { useState, useRef, useEffect } from 'react';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { getIntersectionObserver } from '@shared/lib/getIntersectionObserver';
import { ObjectHelper } from '@shared/lib/helpers/object';
import styles from './layoutMap.scss';
import MapLoader from './loader';
const { API_ENV } = vars();
const TIMEOUT_IN_MS = 500;
const LayoutMap = ({ mapParams, isLoading }) => {
    const [isMapVisibly, setIsMapVisibly] = useState(false);
    const [isInitialized, setIsInitialized] = useState(false);
    const mapRef = useRef(null);
    const mapLoadingObserver = useRef(null);
    const mapQueryParams = qs.stringify(ObjectHelper.convertCamelCaseToSnakeCase(mapParams));
    const handleMapIntersection = () => {
        setIsMapVisibly(true);
    };
    const renderMapIframe = () => _jsx("iframe", { sandbox: "allow-top-navigation allow-scripts allow-same-origin allow-popups", className: styles.iframe, width: "100%", height: "100%", src: `${URL.MAIN[API_ENV]}/map?${mapQueryParams}`, title: "\u041A\u0430\u0440\u0442\u0430", "data-test-id": "mapIframe", "data-sentry-component": "renderMapIframe", "data-sentry-source-file": "index.tsx" });
    useEffect(() => {
        var _a;
        if (mapRef === null || mapRef === void 0 ? void 0 : mapRef.current) {
            mapLoadingObserver.current = getIntersectionObserver(handleMapIntersection, {
                threshold: 0.1
            });
            (_a = mapLoadingObserver.current) === null || _a === void 0 ? void 0 : _a.observe(mapRef.current);
        }
        return () => {
            var _a;
            (_a = mapLoadingObserver.current) === null || _a === void 0 ? void 0 : _a.disconnect();
        };
    }, []);
    useEffect(() => {
        if (isLoading && isInitialized) {
            setIsInitialized(false);
        }
        else {
            setTimeout(() => {
                setIsInitialized(true);
            }, TIMEOUT_IN_MS);
        }
    }, [isLoading, isInitialized]);
    return _jsxs("div", { ref: mapRef, className: styles.container, "data-sentry-component": "LayoutMap", "data-sentry-source-file": "index.tsx", children: [isMapVisibly && !isLoading ? renderMapIframe() : null, !isInitialized ? _jsx(MapLoader, {}) : null] });
};
export default LayoutMap;
