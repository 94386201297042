import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HeadingXSmall, BodyStandardRegular } from '@front-core/typography';
import { AgentReviewCreate } from '@features/agent/review/create';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentReviewsEmptyList.scss';
const AgentReviewsEmptyList = () => {
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const agentCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const isUser = Number(userCasId) !== Number(agentCasId);
    return _jsxs("div", { className: styles.agentReviewsEmptyList, "data-sentry-component": "AgentReviewsEmptyList", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041F\u043E\u043A\u0430 \u043D\u0435\u0442 \u043E\u0442\u0437\u044B\u0432\u043E\u0432" }), _jsx(BodyStandardRegular, { tag: "p", wrapper: false, className: styles.description, "data-sentry-element": "BodyStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0415\u0441\u043B\u0438 \u0440\u0438\u0435\u043B\u0442\u043E\u0440 \u043F\u0440\u043E\u0432\u043E\u0434\u0438\u043B \u0432\u0430\u0448\u0443 \u0441\u0434\u0435\u043B\u043A\u0443, \u043F\u043E\u0434\u0435\u043B\u0438\u0442\u0435\u0441\u044C, \u043A\u0430\u043A \u0432\u0441\u0451 \u043F\u0440\u043E\u0448\u043B\u043E" }), isUser ? _jsx("div", { className: styles.buttonContainer, children: _jsx(AgentReviewCreate, {}) }) : null] });
};
export default AgentReviewsEmptyList;
