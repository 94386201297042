import { jsx as _jsx } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { useEffect, useState } from 'react';
import { offersApi, OffersDealType, OffersList, OffersTitle } from '@entities/offers';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgencyOffersRent = () => {
    const media = useAppSelector(state => state.ui.media);
    const companyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [rents, setRents] = useState([]);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobileDevice = breakpoint === BreakPoints.MOBILE;
    const [triggerAgencyOffersRent, { isSuccess, isError, isLoading, data }] = offersApi.useLazyGetAgencyOffersRentQuery();
    const pagination = data === null || data === void 0 ? void 0 : data.pagination;
    const fetchOffersRent = async (lastSeenId) => {
        const currentRents = structuredClone(rents);
        const response = await triggerAgencyOffersRent({
            queryParams: {
                companyId,
                dealType: OffersDealType.Rent,
                lastSeenId
            },
            isMobileDevice
        });
        if ('data' in response && response.data) {
            currentRents.push(...response.data.result);
        }
        setRents(currentRents);
    };
    const handleShowMore = () => {
        if (pagination) {
            fetchOffersRent(pagination.lastSeenId).then();
        }
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickButtonAgencyRentShowMore,
            elementType: 'Button',
            eventSense: {
                companyId,
                regionId
            }
        });
    };
    const sendItemAnalytics = (offerId) => {
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickLinkAgencyRentItem,
            elementType: 'Link',
            eventSense: {
                companyId,
                regionId,
                offerId
            }
        });
    };
    useEffect(() => {
        if (!SSR) {
            fetchOffersRent().then();
        }
    }, []);
    if (isSuccess && rents.length === 0 || isError) {
        return null;
    }
    return _jsx(OffersList, { offers: rents, title: OffersTitle.Rent, isLoading: isLoading, pagination: pagination, onLoadMore: handleShowMore, sendItemAnalytics: sendItemAnalytics, "data-sentry-element": "OffersList", "data-sentry-component": "AgencyOffersRent", "data-sentry-source-file": "index.tsx" });
};
export default AgencyOffersRent;
