import { jsx as _jsx } from "react/jsx-runtime";
import { BodyXLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutHero from '@shared/ui/layout/hero';
import desktopBooksPNG from '../../../images/desktop.books.png';
import desktopBooksWebP from '../../../images/desktop.books.webp';
import desktopBooksPNG2x from '../../../images/desktop.books@2x.png';
import desktopBooksWebP2x from '../../../images/desktop.books@2x.webp';
import mobileBooksPNG from '../../../images/mobile.books.png';
import mobileBooksWebP from '../../../images/mobile.books.webp';
import mobileBooksPNG2x from '../../../images/mobile.books@2x.png';
import mobileBooksWebP2x from '../../../images/mobile.books@2x.webp';
import tabletBooksPNG from '../../../images/tablet.books.png';
import tabletBooksWebP from '../../../images/tablet.books.webp';
import tabletBooksPNG2x from '../../../images/tablet.books@2x.png';
import tabletBooksWebP2x from '../../../images/tablet.books@2x.webp';
import styles from './faqAgentHero.scss';
const FAQAgentHero = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const getImageSize = () => {
        switch (breakpoint) {
            case BreakPoints.MOBILE:
                return {
                    width: 343,
                    height: 204
                };
            case BreakPoints.TABLET_PORTRAIT:
                return {
                    width: 336,
                    height: 264
                };
            default:
                return {
                    width: 525,
                    height: 312
                };
        }
    };
    const getImage = () => {
        const { width, height } = getImageSize();
        return {
            src: mobileBooksPNG,
            src2x: mobileBooksPNG2x,
            width,
            height,
            sources: [{
                    type: 'image/webp',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopBooksWebP} 1x, ${desktopBooksWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopBooksPNG} 1x, ${desktopBooksPNG2x} 2x`
                }, {
                    type: 'image/webp',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletBooksWebP} 1x, ${tabletBooksWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletBooksPNG} 1x, ${tabletBooksPNG2x} 2x`
                }, {
                    type: 'image/webp',
                    srcSet: `${mobileBooksWebP} 1x, ${mobileBooksWebP2x} 2x`
                }]
        };
    };
    return _jsx(LayoutHero, { title: "\u041A\u0430\u043A \u0441\u0442\u0430\u0442\u044C \u0443\u0441\u043F\u0435\u0448\u043D\u044B\u043C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u043C", image: getImage(), "data-sentry-element": "LayoutHero", "data-sentry-component": "FAQAgentHero", "data-sentry-source-file": "index.tsx", children: _jsx(BodyXLargeRegular, { tag: "p", wrapper: false, className: styles.text, "data-sentry-element": "BodyXLargeRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Рассказываем о важных параметрах, которые учитываются на Домклик') }) });
};
export default FAQAgentHero;
