import { jsx as _jsx } from "react/jsx-runtime";
import SearchIcon from '@front-core/icon/dist/Search';
import { Input, Size, Type } from '@front-core/input';
import { debounce } from '@front-core/utils';
import { useRef, useEffect } from 'react';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { EventHelper } from '@shared/lib/helpers/event';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const DELAY_IN_MS = 500;
const AgencyAgentsSearchDesktop = ({ defaultValue, onChange }) => {
    const agencyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const inputRef = useRef(null);
    const handleChangeValue = debounce(DELAY_IN_MS, (value) => {
        onChange(value);
    });
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgencyMetricHashes.ClickInputSearchName,
            elementType: 'Input',
            eventSense: {
                agencyId,
                regionId
            }
        });
    };
    useEffect(() => {
        if (defaultValue === null) {
            EventHelper.clearInput(inputRef);
        }
    }, [defaultValue]);
    return _jsx(Input, { fluid: true, showClearIcon: true, inputRef: inputRef, defaultValue: defaultValue || undefined, leftSection: _jsx(SearchIcon, {}), label: "\u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", type: Type.Search, size: Size.Large, onFocus: handleFocusInput, onValueChange: handleChangeValue, "data-sentry-element": "Input", "data-sentry-component": "AgencyAgentsSearchDesktop", "data-sentry-source-file": "index.tsx" });
};
export default AgencyAgentsSearchDesktop;
