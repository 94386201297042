const columnize = (array, columnCount) => {
    if (columnCount <= 0) {
        throw new Error('Количество колонок не может быть меньше или равно 0');
    }
    if (array.some(Array.isArray)) {
        throw new Error('Передан неплоский массив');
    }
    if (array.length) {
        return array.reduce((accumulator, currentValue, index) => {
            const columnIndex = index % columnCount;
            if (!accumulator[columnIndex]) {
                accumulator[columnIndex] = [];
            }
            accumulator[columnIndex].push(currentValue);
            return accumulator;
        }, []);
    }
    return array;
};
export const ArrayHelper = {
    columnize
};
