import { jsx as _jsx } from "react/jsx-runtime";
import { Accordion, AccordionTitle } from '@front-core/accordion';
import { LabelLargeSemibold } from '@front-core/typography';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './faqItem.scss';
const FaqItem = ({ title, sendAnalytics, content, questionId }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const renderTitle = isMobile ? _jsx(LabelLargeSemibold, { children: title }) : _jsx(AccordionTitle, { title: title, defaultBreakpoint: breakpoint, headingLevel: 2 });
    const handleToggleAccordion = (open) => {
        if (open) {
            sendAnalytics(questionId);
        }
    };
    return _jsx(Accordion, { divider: false, title: renderTitle, wrapperClassName: styles.faqItem, onToggle: handleToggleAccordion, "data-sentry-element": "Accordion", "data-sentry-component": "FaqItem", "data-sentry-source-file": "index.tsx", children: content });
};
export default FaqItem;
