import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { AgenciesList } from '@widgets/agencies/list';
import { AgenciesListControls } from '@widgets/agencies/listControls';
import { AgenciesRegions } from '@widgets/agencies/regions';
import CSIAgencies from '@widgets/csi/agencies';
import { SEO } from '@widgets/seo';
import { TopBanners } from '@widgets/topBanners';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import Breadcrumbs from '@shared/ui/breadcrumbs';
import styles from './agenciesPage.scss';
import AgenciesFaq from './components/faq';
const { API_ENV } = vars();
const AgenciesPage = () => {
    const canonical = useAppSelector(state => { var _a; return (_a = state.url) === null || _a === void 0 ? void 0 : _a.canonical; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const geoCity = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city; });
    const locationName = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity.name}`;
    return _jsxs("div", { className: clsx(styles.page, {
            [styles.isWebView]: isWebView
        }), "data-sentry-component": "AgenciesPage", "data-sentry-source-file": "index.tsx", children: [_jsx(SEO, { title: `Агентства недвижимости ${locationName} - рейтинг риэлторских компаний: контакты, отзывы клиентов - Домклик`, description: `Подберите лучшее агентство по недвижимости ${locationName} с Домклик  🏆 Рейтинг риэлторских компаний и актуальные данные по совершенным сделкам 💬 Отзывы от проверенных клиентов  📞 Контакты агентов и агентств `, keywords: `Агентства недвижимости ${locationName}, выбрать агентство недвижимости, риэлторы агентства, офисы агентства`, breadcrumbScript: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "item":
                   {
                     "@id": "${URL.MAIN[API_ENV]}",
                     "name": "Домклик"
                   }
              },
              {
                "@type": "ListItem",
                "position": "2",
                "item":
                   {
                     "@id": "${URL.AGENCIES[API_ENV].replace('/:regionSlug', '')}",
                     "name": "🔸 АГЕНТСТВА"
                   }
              },
              {
                "@type": "ListItem",
                "position": "3",
                "item":
                   {
                     "@id": "${canonical}",
                     "name": "🏡 Агентства недвижимости ${locationName}"
                   }
              }
            ]
          }`, "data-sentry-element": "SEO", "data-sentry-source-file": "index.tsx" }), _jsx(AgenciesListControls, { "data-sentry-element": "AgenciesListControls", "data-sentry-source-file": "index.tsx" }), _jsx(AgenciesList, { "data-sentry-element": "AgenciesList", "data-sentry-source-file": "index.tsx" }), !isWebView ? _jsxs(Fragment, { children: [_jsx(TopBanners, {}), _jsx(CSIAgencies, {}), _jsx(AgenciesFaq, { cityNameLocIn: locationName }), _jsx(AgenciesRegions, {})] }) : null, _jsx(Breadcrumbs, { "data-sentry-element": "Breadcrumbs", "data-sentry-source-file": "index.tsx" })] });
};
export default AgenciesPage;
