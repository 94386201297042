import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { AgentsDealType } from '@features/agents/dealType';
import { AgentsSort } from '@features/agents/sort';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsListControls.scss';
import AgentsListControlsFAQ from './components/faq';
import AgentsFilters from './components/filters';
import AgentsSearch from './components/search';
import AgentsSearchCityDesktop from './components/search/city/desktop';
import AgentsSearchMobile from './components/search/mobile';
import AgentsTitle from './components/title';
const AgentsListControls = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsxs("section", { className: styles.container, "data-sentry-component": "AgentsListControls", "data-sentry-source-file": "index.tsx", children: [_jsx(AgentsTitle, { "data-sentry-element": "AgentsTitle", "data-sentry-source-file": "index.tsx" }), !isWebView ? _jsx(AgentsListControlsFAQ, {}) : null, _jsx("div", { className: clsx(styles.controlsContainer, {
                    [styles.isWebView]: isWebView
                }), children: isWebView || isMobile ? _jsxs(Fragment, { children: [_jsx(AgentsFilters, {}), _jsx(AgentsSearchMobile, {}), _jsx(AgentsSort, {})] }) : _jsxs(Fragment, { children: [_jsx(AgentsSearchCityDesktop, {}), _jsx(AgentsDealType, {}), _jsx(AgentsSearch, {})] }) })] });
};
export default AgentsListControls;
