import { jsx as _jsx } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Intent, Size, Button } from '@front-core/button';
import { EmptyScreen } from '@front-core/empty-screen';
import { Picture } from '@front-core/picture';
import { generatePath } from 'react-router-dom';
import { AgentReviewCreate } from '@features/agent/review/create';
import { PATH } from '@shared/config/constants';
import safeHomePNG from '../../images/safe-home.png';
import safeHomeWebP from '../../images/safe-home.webp';
import safeHome2xPNG from '../../images/safe-home@2x.png';
import safeHome2xWebP from '../../images/safe-home@2x.webp';
import styles from './agentDisabled.scss';
const AgentDisabled = ({ regionSlug, agentErrors }) => {
    var _a, _b;
    return _jsx(Paper, { className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgentDisabled", "data-sentry-source-file": "index.tsx", children: _jsx(EmptyScreen, { title: (_b = (_a = agentErrors === null || agentErrors === void 0 ? void 0 : agentErrors.details) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.name, text: "\u0415\u0441\u043B\u0438 \u0440\u0438\u0435\u043B\u0442\u043E\u0440 \u043F\u0440\u043E\u0432\u043E\u0434\u0438\u043B \u0432\u0430\u0448\u0443 \u0441\u0434\u0435\u043B\u043A\u0443, \u043C\u043E\u0436\u0435\u0442\u0435 \u043E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u0437\u044B\u0432", image: _jsx(Picture, { alt: "", src: safeHomePNG, src2x: safeHome2xPNG, webpSrc: safeHomeWebP, webpSrc2x: safeHome2xWebP, width: 180, height: 180 }), buttons: [_jsx(AgentReviewCreate, { fluidButton: true, textButton: "\u041E\u0441\u0442\u0430\u0432\u0438\u0442\u044C \u043E\u0442\u0437\u044B\u0432", intentButton: Intent.Primary, sizeButton: Size.Large }, "create-review"), _jsx(Button, { fluid: true, text: "\u041F\u0435\u0440\u0435\u0439\u0442\u0438 \u0432 \u043F\u043E\u0438\u0441\u043A \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432", size: Size.Large, intent: Intent.Secondary, href: generatePath(PATH.AGENTS, {
                        regionSlug
                    }) }, "link-agents")], "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "index.tsx" }) });
};
export default AgentDisabled;
