import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import SearchIcon from '@front-core/icon/dist/Search';
import { Input, Size } from '@front-core/input';
import { HeadingStandard } from '@front-core/typography';
import { memo } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsSearchDistrictModalHeader.scss';
const AgentsSearchDistrictModalHeader = ({ searchQuery, onChange }) => {
    const cityName = useAppSelector(state => state.geo.city.name);
    return _jsxs("div", { className: styles.agentsSearchDistrictModalHeader, "data-sentry-component": "AgentsSearchDistrictModalHeader", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingStandard, { "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: cityName }), _jsx(Input, { fluid: true, size: Size.Medium, placeholder: "\u0420\u0430\u0439\u043E\u043D, \u043E\u043A\u0440\u0443\u0433", showClearIcon: true, value: searchQuery, leftSection: _jsx(SearchIcon, {}), onValueChange: onChange, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" })] });
};
export default memo(AgentsSearchDistrictModalHeader);
