import { CookieHelper } from '@shared/lib/helpers/cookie';
export const webViewAuth = (authCallback) => {
    if (typeof window !== 'undefined') {
        window.onAuthCancel = () => false;
        window.onAuthorized = () => {
            const casIdSigned = CookieHelper.get('CAS_ID_SIGNED');
            if (casIdSigned) {
                authCallback(casIdSigned);
            }
        };
    }
};
