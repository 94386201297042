import { jsx as _jsx } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent } from '@front-core/button';
import UserIcon from '@front-core/icon/dist/User';
import { generatePath } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agentRecommendations.scss';
const AgentRecommendations = () => {
    var _a, _b, _c;
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const geoCity = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city; });
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const isRobot = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isRobot; });
    const geoLocation = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}`;
    const robotLocation = ((_a = agent === null || agent === void 0 ? void 0 : agent.region) === null || _a === void 0 ? void 0 : _a.nameLoc) || `в городе ${(_b = agent === null || agent === void 0 ? void 0 : agent.region) === null || _b === void 0 ? void 0 : _b.name}`;
    const locationName = isRobot ? robotLocation : geoLocation;
    const slug = isRobot && ((_c = agent === null || agent === void 0 ? void 0 : agent.region) === null || _c === void 0 ? void 0 : _c.slug) ? agent.region.slug : regionSlug;
    const handleClick = () => {
        sendMetrics({
            eventType: 'click',
            elementId: AgentMetricHashes.ClickLinkAllAgents,
            elementType: 'Link',
            eventSense: {
                agentCasId: agent.casId,
                regionId
            }
        });
    };
    if (isWebView) {
        return null;
    }
    return _jsx(Paper, { className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgentRecommendations", "data-sentry-source-file": "index.tsx", children: _jsx(Button, { intent: Intent.Transparent, href: generatePath(PATH.AGENTS, {
                regionSlug: slug
            }), text: `Все риелторы ${locationName}`, icon: _jsx(UserIcon, { className: styles.linkIcon }), wrapperClassName: styles.link, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }) });
};
export default AgentRecommendations;
