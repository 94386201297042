import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import CheckCircleFilled24 from '@front-core/icon/dist/v2/CheckCircleFilled24';
import CloseCircleFilled24 from '@front-core/icon/dist/v2/CloseCircleFilled24';
import { Picture } from '@front-core/picture';
import { HeadingLarge, BodyLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { showInSearchList } from '../config/showInSearchList';
import peoplePNG from '../images/people.png';
import peopleWebP from '../images/people.webp';
import peoplePNG2x from '../images/people@2x.png';
import peopleWebP2x from '../images/people@2x.webp';
import styles from './faqWhoShowInSearch.scss';
const FAQWhoShowInSearch = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    return _jsxs(Paper, { tag: "section", className: styles.faqWhoShowInSearch, "data-sentry-element": "Paper", "data-sentry-component": "FAQWhoShowInSearch", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: styles.content, children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Кто отображается в поиске') }), _jsx("ul", { className: styles.list, children: showInSearchList.map((item, index) => _jsxs("li", { className: styles.item, children: [index === showInSearchList.length - 1 ? _jsx(CloseCircleFilled24, {}) : _jsx(CheckCircleFilled24, {}), _jsx(BodyLargeRegular, { tag: "span", wrapper: false, children: StringHelper.addNbsp(item) })] }, index)) })] }), isDesktop ? _jsx(Picture, { src: peoplePNG, src2x: peoplePNG2x, webpSrc: peopleWebP, webpSrc2x: peopleWebP2x, width: 447, height: 289, className: styles.picture, alt: "" }) : null] });
};
export default FAQWhoShowInSearch;
