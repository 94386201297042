import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { Tag, TagSize } from '@front-core/badge';
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useRef } from 'react';
import { SortElementId } from '@features/agency/agentList/sort';
import { agenciesApi, agenciesSlice } from '@entities/agencies';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agencyAgentList.scss';
import AgencyAgentListControls from './components/controls';
import AgencyAgentListEmpty from './components/empty';
import AgencyAgentListResult from './components/result';
import AgencyAgentListSkeleton from './components/skeleton';
const defaultParams = {
    limit: 5,
    offset: 0
};
const AgencyAgentList = () => {
    var _a, _b, _c, _d;
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency; });
    const regionId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region) === null || _b === void 0 ? void 0 : _b.guid; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const searchParams = useAppSelector(state => state.agencies.agency.agents.searchParams);
    const dispatch = useAppDispatch();
    const [triggerAgencyAgents, { isFetching, isLoading }] = agenciesApi.useLazyGetAgenciesAgentsQuery();
    const paginationRef = useRef(agency === null || agency === void 0 ? void 0 : agency.agents.pagination).current;
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const agents = (_a = agency === null || agency === void 0 ? void 0 : agency.agents) === null || _a === void 0 ? void 0 : _a.list;
    const pagination = (_b = agency === null || agency === void 0 ? void 0 : agency.agents) === null || _b === void 0 ? void 0 : _b.pagination;
    const isPending = isLoading || isFetching;
    const isShowEmptyContainer = !agents.length && !isPending;
    const updateAgents = async (params) => {
        try {
            await triggerAgencyAgents({
                ...searchParams,
                ...params
            });
        }
        catch (error) {
            console.info(error);
        }
    };
    const getAgentsCount = () => {
        if (pagination) {
            return StringHelper.getPluralForm(Number(pagination.total), ['риелтор', 'риелтора', 'риелторов'], true);
        }
        return StringHelper.getPluralForm(0, ['риелтор', 'риелтора', 'риелторов'], true);
    };
    const handleUpdateSearch = (value) => {
        const params = {
            search: value,
            offset: 0
        };
        dispatch(agenciesSlice.setAgentsSearchParams(params));
        updateAgents(params).then();
    };
    const handleChangeOrder = (value) => {
        var _a;
        const order = value;
        const params = {
            order,
            offset: 0
        };
        dispatch(agenciesSlice.setAgentsSearchParams(params));
        updateAgents(params).then();
        if (SortElementId[order]) {
            sendMetrics({
                eventType: 'click',
                elementId: SortElementId[order],
                elementType: 'DropdownSelect',
                eventSense: {
                    type: order,
                    agencyId: (_a = agency === null || agency === void 0 ? void 0 : agency.details) === null || _a === void 0 ? void 0 : _a.id,
                    regionId
                }
            });
        }
    };
    const handleChangePage = (page) => {
        var _a;
        const offset = NumberHelper.getOffset(page, searchParams.limit);
        dispatch(agenciesSlice.setAgentsSearchParams({
            offset
        }));
        updateAgents({
            offset
        }).then();
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickAgentListPagination,
            elementType: 'Pagination',
            eventSense: {
                page,
                agencyId: (_a = agency === null || agency === void 0 ? void 0 : agency.details) === null || _a === void 0 ? void 0 : _a.id,
                regionId
            }
        });
    };
    const handleClickResetSearchButton = () => {
        dispatch(agenciesSlice.setAgentsSearchParams({
            ...defaultParams,
            search: null
        }));
        updateAgents(defaultParams).then();
    };
    if (!(paginationRef === null || paginationRef === void 0 ? void 0 : paginationRef.total)) {
        return null;
    }
    return _jsxs(Paper, { className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgencyAgentList", "data-sentry-source-file": "index.tsx", children: [_jsxs(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.titleText, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: ["\u0412 \u043A\u043E\u043C\u0430\u043D\u0434\u0435 ", getAgentsCount()] }), (paginationRef === null || paginationRef === void 0 ? void 0 : paginationRef.total) > 1 || (paginationRef === null || paginationRef === void 0 ? void 0 : paginationRef.total) === 1 && ((_c = searchParams.search) === null || _c === void 0 ? void 0 : _c.length) ? _jsx(AgencyAgentListControls, { isDisabledSort: (agents === null || agents === void 0 ? void 0 : agents.length) === 0, onSearch: handleUpdateSearch, onChangeOrder: handleChangeOrder }) : null, isShowEmptyContainer ? _jsx(AgencyAgentListEmpty, { onClickReset: handleClickResetSearchButton }) : null, !isShowEmptyContainer && isMobile && ((_d = searchParams === null || searchParams === void 0 ? void 0 : searchParams.search) === null || _d === void 0 ? void 0 : _d.length) ? _jsx("div", { className: styles.tags, children: _jsx(Tag, { size: TagSize.Large, text: searchParams.search, onCloseClick: handleClickResetSearchButton }) }) : null, _jsx(AgencyAgentListResult, { agents: agents, isShow: !isPending, onChangePage: handleChangePage, pagination: pagination, "data-sentry-element": "AgencyAgentListResult", "data-sentry-source-file": "index.tsx" }), _jsx(AgencyAgentListSkeleton, { isShowSkeleton: isPending, "data-sentry-element": "AgencyAgentListSkeleton", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyAgentList;
