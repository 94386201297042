import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownButton, Aligns } from '@front-core/dropdown-button';
import SortingArrowsIcon from '@front-core/icon/dist/v2/SortingArrows';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { memo } from 'react';
import { AgentReviewOrder, agentsSlice, agentsApi } from '@entities/agents';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import { Orders } from '../config/orders';
const AgentReviewSort = () => {
    var _a;
    const reviews = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.list; });
    const media = useAppSelector(state => state.ui.media);
    const agentReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.queryParams; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const modals = useAppSelector(state => { var _a; return (_a = state.reviews) === null || _a === void 0 ? void 0 : _a.modals; });
    const dispatch = useAppDispatch();
    const [triggerAgentReviews] = agentsApi.useLazyGetAgentReviewsQuery();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const hasDot = isMobile && agentReviewQueryParams.order !== AgentReviewOrder.Helpful;
    const title = (_a = Orders.find(order => order.value === agentReviewQueryParams.order)) === null || _a === void 0 ? void 0 : _a.title;
    const isReviewListModalVisibility = Boolean(modals === null || modals === void 0 ? void 0 : modals.list);
    const handleChangeValue = async (value) => {
        if (typeof value === 'string') {
            const reviewOrder = value;
            sendMetrics({
                elementId: AgentMetricHashes.ClickOrderReview,
                eventType: 'click',
                elementType: 'button',
                eventSense: {
                    order: reviewOrder
                }
            });
            dispatch(agentsSlice.setAgentReviewsQueryParams({
                order: reviewOrder,
                offset: 0
            }));
            await triggerAgentReviews({
                ...agentReviewQueryParams,
                order: reviewOrder,
                offset: 0
            });
        }
    };
    const handleToggle = (isOpen) => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, isReviewListModalVisibility || isOpen);
        }
    };
    return _jsx("div", { "data-sentry-component": "AgentReviewSort", "data-sentry-source-file": "index.tsx", children: _jsx(DropdownButton, { icon: _jsx(SortingArrowsIcon, {}), withDot: hasDot, defaultBreakpoint: breakpoint, items: Orders, value: agentReviewQueryParams.order, defaultValue: AgentReviewOrder.Helpful, disabled: !reviews.length || reviews.length === 1, onToggle: handleToggle, onValueChange: handleChangeValue, popperProps: {
                width: 225,
                align: Aligns.Right
            }, title: !isMobile ? title : null, "data-sentry-element": "DropdownButton", "data-sentry-source-file": "index.tsx" }) });
};
export default memo(AgentReviewSort);
