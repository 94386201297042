import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelSmallRegular } from '@front-core/typography';
import { AgentReviewPeriodFilter } from '@features/agent/review/periodFilter';
import { AgentReviewSort } from '@features/agent/review/sort';
import { AgentReviewsPeriod } from '@entities/agents';
import { Unicode } from '@shared/config/unicode';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsReviewsControls.scss';
const AgentReviewsControls = () => {
    const totalReview = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.pagination) === null || _b === void 0 ? void 0 : _b.total; });
    const agentReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.queryParams; });
    return _jsxs("div", { className: styles.agentsReviewsControls, "data-sentry-component": "AgentReviewsControls", "data-sentry-source-file": "index.tsx", children: [_jsx(AgentReviewPeriodFilter, { "data-sentry-element": "AgentReviewPeriodFilter", "data-sentry-source-file": "index.tsx" }), _jsx(AgentReviewSort, { "data-sentry-element": "AgentReviewSort", "data-sentry-source-file": "index.tsx" }), totalReview ? _jsxs(LabelSmallRegular, { tag: "p", wrapper: false, className: styles.text, children: [StringHelper.getPluralForm(totalReview, ['отзыв', 'отзыва', 'отзывов'], true), ' ', "\u043E \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u043D\u044B\u0445 \u0441\u0434\u0435\u043B\u043A\u0430\u0445 \u0437\u0430 ", agentReviewQueryParams.period === AgentReviewsPeriod.All ? `всё${Unicode.NoBreakSpace}время` : `12${Unicode.NoBreakSpace}месяцев`] }) : null] });
};
export default AgentReviewsControls;
