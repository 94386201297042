import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertList, AlertStatus } from '@front-core/alert';
import { Button, Size, Intent } from '@front-core/button';
import CallIcon from '@front-core/icon/dist/Call';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment, useRef, useState, useEffect } from 'react';
import { callsApi, CallTypes } from '@entities/calls';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const OfficeCallButton = ({ officeId }) => {
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const agencyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [sendCallRequest, { isLoading, isSuccess, isError }] = callsApi.useSendCallMutation();
    const [alertList, setAlertList] = useState([]);
    const buttonRef = useRef(null);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const sendCall = async () => {
        if (userCasId) {
            await sendCallRequest({
                itemId: officeId,
                itemType: CallTypes.Office
            });
        }
        else {
            window.domclickGlobal.domclickLayout.showLoginForm({
                loginFormProps: {
                    onEntry: () => { var _a; return (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.click(); }
                }
            });
        }
    };
    const handleClick = () => {
        sendCall().then();
        sendMetrics({
            eventType: 'click',
            elementId: AgencyMetricHashes.ClickOfficesListCallButton,
            elementType: 'Button',
            eventSense: {
                agencyId,
                regionId
            }
        });
    };
    useEffect(() => {
        if (isSuccess) {
            setAlertList([{
                    id: '1',
                    showCloseIcon: true,
                    title: 'Позвоним в течение 5 секунд',
                    duration: 4,
                    status: AlertStatus.SUCCESS
                }]);
        }
    }, [isSuccess]);
    useEffect(() => {
        if (isError) {
            setAlertList([{
                    id: '3',
                    showCloseIcon: true,
                    title: 'Не получилось связаться с офисом',
                    description: 'Закажите звонок ещё раз',
                    duration: 4,
                    status: AlertStatus.ERROR
                }]);
        }
    }, [isError]);
    return _jsxs(Fragment, { children: [_jsx(Button, { "aria-label": "\u0417\u0430\u043A\u0430\u0437\u0430\u0442\u044C \u0437\u0432\u043E\u043D\u043E\u043A \u0438\u0437 \u043E\u0444\u0438\u0441\u0430", intent: Intent.Secondary, icon: _jsx(CallIcon, {}), size: isMobile ? Size.Large : Size.Small, buttonRef: buttonRef, loading: isLoading, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" })] });
};
export default OfficeCallButton;
