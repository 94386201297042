import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingLarge } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Unicode } from '@shared/config/unicode';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutFAQBlock from '@shared/ui/layout/faqBlock';
import faqBlockJPG from '../images/faq-block-1.jpg';
import faqBlockWebP from '../images/faq-block-1.webp';
import faqBlock2xJPG from '../images/faq-block-1@2x.jpg';
import faqBlock2xWebP from '../images/faq-block-1@2x.webp';
import faqServicesJPG from '../images/faq-services.jpg';
import faqServicesWebP from '../images/faq-services.webp';
import faqServices2xJPG from '../images/faq-services@2x.jpg';
import faqServices2xWebP from '../images/faq-services@2x.webp';
import styles from './faqHowFindAgent.scss';
const FAQHowFindAgent = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    const primaryImage = isDesktop ? {
        src: faqBlockJPG,
        src2x: faqBlock2xJPG,
        webpSrc: faqBlockWebP,
        webpSrc2x: faqBlock2xWebP,
        width: 480,
        height: 324
    } : null;
    const secondaryImage = {
        src: faqServicesJPG,
        src2x: faqServices2xJPG,
        webpSrc: faqServicesWebP,
        webpSrc2x: faqServices2xWebP,
        width: isDesktop ? 375 : 311,
        height: isDesktop ? 236 : 196
    };
    return _jsxs(Paper, { tag: "section", className: styles.faqHowFindAgent, "data-sentry-element": "Paper", "data-sentry-component": "FAQHowFindAgent", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Как найти раздел с риелторами') }), _jsx(LayoutFAQBlock, { title: `Главная → Сервисы →${Unicode.NextLine}Поиск риелтора`, description: "\u0415\u0441\u043B\u0438 \u0443 \u0432\u0430\u0441 iPhone, \u043E\u0442\u043A\u0440\u043E\u0439\u0442\u0435 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0435, \u0432\u043E\u0439\u0434\u0438\u0442\u0435 \u043D\u0430 \u0414\u043E\u043C\u043A\u043B\u0438\u043A \u2014 \u0438 \u0440\u0430\u0437\u0434\u0435\u043B \u043F\u043E\u044F\u0432\u0438\u0442\u0441\u044F", primaryImage: primaryImage, secondaryImage: secondaryImage, "data-sentry-element": "LayoutFAQBlock", "data-sentry-source-file": "index.tsx" })] });
};
export default FAQHowFindAgent;
