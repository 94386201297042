import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Size } from '@front-core/button';
import { Picture } from '@front-core/picture';
import { HeadingStandard, LabelLargeRegular } from '@front-core/typography';
import { clsx } from 'clsx';
import { StringHelper } from '@shared/lib/helpers/string';
import CustomLink from '@shared/ui/customLink';
import styles from './layoutFAQBlock.scss';
const LayoutFAQBlock = ({ title, description, primaryImage, secondaryImage, isReverse, linkTo, linkText, onClick }) => {
    const renderImages = () => {
        const hasImages = primaryImage && secondaryImage;
        const currentImage = primaryImage || secondaryImage;
        if (hasImages) {
            return _jsxs("div", { className: clsx(styles.images, {
                    [styles.isReverse]: isReverse
                }), children: [_jsx(Picture, { src: secondaryImage.src, src2x: secondaryImage.src2x, webpSrc: secondaryImage.webpSrc, webpSrc2x: secondaryImage.webpSrc2x, width: secondaryImage.width, height: secondaryImage.height, className: clsx(styles.picture, styles.secondaryImage), alt: "" }), _jsx(Picture, { src: primaryImage.src, src2x: primaryImage.src2x, webpSrc: primaryImage.webpSrc, webpSrc2x: primaryImage.webpSrc2x, width: primaryImage.width, height: primaryImage.height, className: clsx(styles.picture, styles.primaryImage), alt: "" })] });
        }
        if (currentImage) {
            return _jsx(Picture, { src: currentImage.src, src2x: currentImage.src2x, webpSrc: currentImage.webpSrc, webpSrc2x: currentImage.webpSrc2x, width: currentImage.width, height: currentImage.height, alt: "", className: clsx(styles.picture, primaryImage ? styles.primaryImage : styles.secondaryImage) });
        }
        return null;
    };
    return _jsxs("article", { className: clsx(styles.layoutFAQBlock, {
            [styles.isReverse]: isReverse
        }), "data-sentry-component": "LayoutFAQBlock", "data-sentry-source-file": "index.tsx", children: [primaryImage || secondaryImage ? renderImages() : null, _jsxs("div", { className: styles.content, children: [_jsx(HeadingStandard, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(title) }), _jsx(LabelLargeRegular, { tag: "p", wrapper: false, "data-sentry-element": "LabelLargeRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(description) }), linkTo && linkText ? _jsx(Button, { fluid: true, text: linkText, size: Size.Large, wrapperClassName: styles.button, routerLink: _jsx(CustomLink, { to: linkTo }), onClick: onClick }) : null] })] });
};
export default LayoutFAQBlock;
