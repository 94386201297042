import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { Fragment } from 'react';
import { AgenciesDealType } from '@features/agencies/dealType';
import { AgenciesSort } from '@features/agencies/sort';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agenciesListControls.scss';
import AgenciesFilters from './components/filters';
import AgenciesSearch from './components/search';
import AgenciesSearchCityDesktop from './components/search/city/desktop';
import AgenciesSearchMobile from './components/search/mobile';
import AgenciesTitle from './components/title';
const AgenciesListControls = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = isWebView || breakpoint === BreakPoints.MOBILE;
    return _jsxs("section", { className: styles.container, "data-sentry-component": "AgenciesListControls", "data-sentry-source-file": "index.tsx", children: [_jsx(AgenciesTitle, { "data-sentry-element": "AgenciesTitle", "data-sentry-source-file": "index.tsx" }), _jsx("div", { className: styles.controlsContainer, children: isMobile ? _jsxs(Fragment, { children: [_jsx(AgenciesFilters, {}), _jsx(AgenciesSearchMobile, {}), _jsx(AgenciesSort, {})] }) : _jsxs(Fragment, { children: [_jsx(AgenciesSearchCityDesktop, {}), _jsx(AgenciesDealType, {}), _jsx(AgenciesSearch, {})] }) })] });
};
export default AgenciesListControls;
