import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import ClampedText from '@shared/ui/clampedText';
import styles from './agencyAboutCompany.scss';
const AgencyAboutCompany = () => {
    const description = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.description; });
    if (!description) {
        return null;
    }
    return _jsxs(Paper, { className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgencyAboutCompany", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h4", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E \u043A\u043E\u043C\u043F\u0430\u043D\u0438\u0438" }), _jsx(ClampedText, { text: description, "data-sentry-element": "ClampedText", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyAboutCompany;
