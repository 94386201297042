import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelSmallMedium, LabelSmallRegular } from '@front-core/typography';
import { clsx } from 'clsx';
import { getDateDifferenceString } from '@shared/lib/getDateDifferenceString';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import styles from './agentInfoProgress.scss';
const AgentInfoProgress = ({ wrapperClassName, dealsCompleted, offersCount, registeredAt }) => _jsxs("ul", { className: clsx(styles.agentInfoProgress, wrapperClassName), "data-sentry-component": "AgentInfoProgress", "data-sentry-source-file": "index.tsx", children: [registeredAt ? _jsxs("li", { className: styles.item, children: [_jsx(LabelSmallMedium, { tag: "strong", wrapper: false, className: styles.title, children: getDateDifferenceString(registeredAt) }), _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.description, children: "\u043D\u0430 \u0414\u043E\u043C\u043A\u043B\u0438\u043A" })] }) : null, _jsxs("li", { className: styles.item, children: [_jsx(LabelSmallMedium, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "LabelSmallMedium", "data-sentry-source-file": "index.tsx", children: dealsCompleted ? `${NumberHelper.getNumberLocaleFormat(dealsCompleted)}  ${StringHelper.getPluralForm(dealsCompleted, ['сделка', 'сделки', 'сделок'])}` : 'Нет сделок' }), _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: clsx(styles.description, {
                        [styles.hasDeals]: !dealsCompleted
                    }), "data-sentry-element": "LabelSmallRegular", "data-sentry-source-file": "index.tsx", children: "\u0437\u0430 12 \u043C\u0435\u0441\u044F\u0446\u0435\u0432" })] }), _jsxs("li", { className: styles.item, children: [_jsx(LabelSmallMedium, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "LabelSmallMedium", "data-sentry-source-file": "index.tsx", children: offersCount ? `${NumberHelper.getNumberLocaleFormat(offersCount)}  ${StringHelper.getPluralForm(offersCount, ['объявление', 'объявления', 'объявлений'])}` : 'Нет объявлений' }), offersCount ? _jsx(LabelSmallRegular, { tag: "span", wrapper: false, className: styles.description, children: "\u0430\u043A\u0442\u0438\u0432\u043D\u043E" }) : null] })] });
export default AgentInfoProgress;
