import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BodyXLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { FAQAppHashes } from '@shared/config/metrics';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import AppAndroid from '@shared/ui/appAndroid';
import AppStore from '@shared/ui/appStore';
import LayoutHero from '@shared/ui/layout/hero';
import desktopAppPNG from '../../../images/desktop.app.png';
import desktopAppWebP from '../../../images/desktop.app.webp';
import desktopAppPNG2x from '../../../images/desktop.app@2x.png';
import desktopAppWebP2x from '../../../images/desktop.app@2x.webp';
import mobileAppPNG from '../../../images/mobile.app.png';
import mobileAppWebP from '../../../images/mobile.app.webp';
import mobileAppPNG2x from '../../../images/mobile.app@2x.png';
import mobileAppWebP2x from '../../../images/mobile.app@2x.webp';
import tabletAppPNG from '../../../images/tablet.app.png';
import tabletAppWebP from '../../../images/tablet.app.webp';
import tabletAppPNG2x from '../../../images/tablet.app@2x.png';
import tabletAppWebP2x from '../../../images/tablet.app@2x.webp';
import styles from './faqAppHero.scss';
const FAQAppHero = () => {
    const media = useAppSelector(state => state.ui.media);
    const regionId = useAppSelector(state => state.geo.region.guid);
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const getImageSize = () => {
        switch (breakpoint) {
            case BreakPoints.MOBILE:
                return {
                    width: 343,
                    height: 203
                };
            case BreakPoints.TABLET_PORTRAIT:
                return {
                    width: 352,
                    height: 311
                };
            default:
                return {
                    width: 526,
                    height: 311
                };
        }
    };
    const getImage = () => {
        const { width, height } = getImageSize();
        return {
            width,
            height,
            src: mobileAppPNG,
            src2x: mobileAppPNG2x,
            sources: [{
                    type: 'image/webp',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopAppWebP} 1x, ${desktopAppWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 1024px)',
                    srcSet: `${desktopAppPNG} 1x, ${desktopAppPNG2x} 2x`
                }, {
                    type: 'image/webp',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletAppWebP} 1x, ${tabletAppWebP2x} 2x`
                }, {
                    type: 'image/png',
                    media: '(min-width: 768px)',
                    srcSet: `${tabletAppPNG} 1x, ${tabletAppPNG2x} 2x`
                }, {
                    type: 'image/webp',
                    srcSet: `${mobileAppWebP} 1x, ${mobileAppWebP2x} 2x`
                }]
        };
    };
    const sendAnalytics = (elementId) => {
        sendMetrics({
            elementId,
            eventType: 'click',
            elementType: 'Link',
            eventSense: {
                regionId
            }
        });
    };
    const handleClickAppStore = () => {
        sendAnalytics(FAQAppHashes.ClickHeadAppStoreButton);
    };
    const handleClickAppAndroid = () => {
        sendAnalytics(FAQAppHashes.ClickHeadAndroidButton);
    };
    return _jsx(LayoutHero, { title: "\u041F\u043E\u0434\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430 \u0432 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0438", image: getImage(), className: styles.faqAppHero, classNameImage: styles.image, "data-sentry-element": "LayoutHero", "data-sentry-component": "FAQAppHero", "data-sentry-source-file": "index.tsx", children: _jsxs(Fragment, { children: [_jsx(BodyXLargeRegular, { tag: "p", wrapper: false, className: styles.text, "data-sentry-element": "BodyXLargeRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Искать со смартфона удобно') }), _jsxs("div", { className: styles.apps, children: [_jsx(AppStore, { onClick: handleClickAppStore, "data-sentry-element": "AppStore", "data-sentry-source-file": "index.tsx" }), _jsx(AppAndroid, { onClick: handleClickAppAndroid, "data-sentry-element": "AppAndroid", "data-sentry-source-file": "index.tsx" })] })] }) });
};
export default FAQAppHero;
