import { setTopAppBarOverlayVisibility } from '@rcf/webview-interfaces';
const VERSION = 9.17;
const VERSION_WITH_BUG = '9.17.0';
export const setWebViewVisibilityTopBar = (platform, isVisible) => {
    const appVersion = platform.split(';')[4] ? parseFloat(platform.split(';')[4]) : VERSION;
    const appVersionBug = platform.split(';')[4] ? platform.split(';')[4].trim() : String(VERSION);
    if (/^Android/.exec(platform)) {
        setTopAppBarOverlayVisibility(isVisible);
    }
    if (/^iOS/.exec(platform)) {
        setTopAppBarOverlayVisibility(VERSION_WITH_BUG === appVersionBug || VERSION > appVersion ? !isVisible : isVisible);
    }
};
