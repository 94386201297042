export default class CustomError extends Error {
    constructor(name, message, ...params) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        super(...params);
        // Поддерживаем соответствующую трассировку стека с указанием места возникновения ошибки (доступно только в V8)
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CustomError);
        }
        this.name = name;
        this.message = message;
    }
}
