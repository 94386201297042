import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { clsx } from 'clsx';
import styles from './agentSkeletonDesktopReviews.scss';
const reviews = Array.from({
    length: 2
}, (_value, index) => index + 1);
const AgentSkeletonDesktopReviews = () => _jsx(Paper, { "data-sentry-element": "Paper", "data-sentry-component": "AgentSkeletonDesktopReviews", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.container, children: [_jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.title, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx("div", { className: styles.list, children: reviews.map(review => _jsxs("div", { className: styles.item, children: [_jsx(Skeleton, { type: SkeletonType.Circle, height: "44px" }), _jsxs("div", { className: styles.itemContent, children: [_jsxs("div", { className: styles.itemHeader, children: [_jsxs("div", { className: styles.itemTitle, children: [_jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemTitleText }), _jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemSubTitle })] }), _jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemRating })] }), _jsxs("div", { className: styles.itemTexts, children: [_jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemText }), _jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemText }), _jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.itemText }), _jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: clsx(styles.itemText, styles.isSmall) })] }), _jsx(Skeleton, { type: SkeletonType.Text, height: "40px", className: styles.itemButton })] })] }, review)) })] }) });
export default AgentSkeletonDesktopReviews;
