import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useState, useEffect } from 'react';
import { getDefaultMapDealsType } from '@shared/lib/getDefaultMapDealsType';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutMap from '@shared/ui/layout/map';
import styles from './agentMap.scss';
import MapFilters from './components/filters';
const AgentMap = () => {
    var _a, _b, _c;
    const agentOffers = useAppSelector(state => state.offers.agent);
    const agentDetails = useAppSelector(state => state.agents.agent.details);
    const salesTotal = ((_a = agentOffers.sales.pagination) === null || _a === void 0 ? void 0 : _a.total) || 0;
    const rentsTotal = ((_b = agentOffers.rents.pagination) === null || _b === void 0 ? void 0 : _b.total) || 0;
    const offersCount = (_c = agentDetails.stats) === null || _c === void 0 ? void 0 : _c.offersCount;
    const agentCasId = agentDetails.casId;
    const [mapParams, setMapParams] = useState({
        casId: String(agentCasId),
        category: 'all',
        dealType: getDefaultMapDealsType({
            salesTotal,
            rentsTotal
        })
    });
    const [isLoading, setIsLoading] = useState(true);
    const handleChangeFilter = (value) => {
        if (value !== mapParams.dealType) {
            setIsLoading(true);
            setMapParams(prevState => ({
                ...prevState,
                dealType: value
            }));
        }
    };
    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isLoading]);
    if (!offersCount || !agentCasId) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", className: styles.agentMap, "data-sentry-element": "Paper", "data-sentry-component": "AgentMap", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h4", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E\u0431\u044A\u0435\u043A\u0442\u044B \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430" }), _jsx(LayoutMap, { mapParams: mapParams, isLoading: isLoading, "data-sentry-element": "LayoutMap", "data-sentry-source-file": "index.tsx" }), _jsx(MapFilters, { salesTotal: salesTotal, rentsTotal: rentsTotal, onChange: handleChangeFilter, "data-sentry-element": "MapFilters", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentMap;
