import { AgentQuickMessage } from '@entities/agents';
import { AgentMetricHashes } from '@shared/config/metrics';
export const quickMessageOptions = [
    {
        title: 'Купить жильё',
        value: AgentQuickMessage.BuyHome
    },
    {
        title: 'Продать жильё',
        value: AgentQuickMessage.SaleHome
    },
    {
        title: 'Сдать жильё',
        value: AgentQuickMessage.LeaseHome
    },
    {
        title: 'Арендовать жильё',
        value: AgentQuickMessage.RentHome
    }
];
export const QuickMessageElementId = {
    [AgentQuickMessage.BuyHome]: AgentMetricHashes.ClickButtonBuyHome,
    [AgentQuickMessage.SaleHome]: AgentMetricHashes.ClickButtonSaleHome,
    [AgentQuickMessage.LeaseHome]: AgentMetricHashes.ClickButtonLeaseHome,
    [AgentQuickMessage.RentHome]: AgentMetricHashes.ClickButtonRentHome
};
