import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarSize, AvatarIntent, AvatarType } from '@front-core/avatar';
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutAvatar from '@shared/ui/layout/avatar';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingFontSize, RatingType } from '@shared/ui/layout/rating/config';
import styles from './agencyInfo.scss';
import AgencyInfoProgress from './components/progress';
const AgentInfo = () => {
    var _a;
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const companyName = (agency === null || agency === void 0 ? void 0 : agency.name) || (agency === null || agency === void 0 ? void 0 : agency.nameLegal) || '';
    const stats = agency === null || agency === void 0 ? void 0 : agency.stats;
    const rating = (_a = agency === null || agency === void 0 ? void 0 : agency.rating) === null || _a === void 0 ? void 0 : _a.avg;
    const getSubTitle = () => {
        var _a;
        let subTitle = '';
        if (agency === null || agency === void 0 ? void 0 : agency.inn) {
            subTitle += `ИНН ${agency.inn}`;
        }
        if (agency === null || agency === void 0 ? void 0 : agency.nameLegal) {
            subTitle += `, ${agency.nameLegal}`;
        }
        if ((_a = agency === null || agency === void 0 ? void 0 : agency.region) === null || _a === void 0 ? void 0 : _a.name) {
            subTitle += `, ${StringHelper.addNbsp(agency.region.name)}`;
        }
        return subTitle;
    };
    return _jsxs(Paper, { className: styles.agencyInfo, "data-sentry-element": "Paper", "data-sentry-component": "AgentInfo", "data-sentry-source-file": "index.tsx", children: [_jsx("div", { className: styles.avatar, children: _jsx(LayoutAvatar, { name: companyName, type: AvatarType.Rect, size: isMobile ? AvatarSize.Large : AvatarSize.ExtraLarge, intent: (agency === null || agency === void 0 ? void 0 : agency.logoUrl) ? AvatarIntent.Tertiary : AvatarIntent.Secondary, imageSrc: (agency === null || agency === void 0 ? void 0 : agency.logoUrl) || undefined, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }) }), _jsxs("div", { className: styles.infoContainer, children: [_jsxs("div", { className: styles.title, children: [_jsx(HeadingXSmall, { tag: "h1", className: styles.titleText, wrapper: false, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: companyName.trim() }), rating && rating > 0 ? _jsx(LayoutRating, { hasGrade: true, rating: rating, fontSize: RatingFontSize.Medium, type: RatingType.Secondary, containerClassName: styles.rating, starClassName: styles.ratingStar }) : null] }), _jsx("span", { className: styles.subTitle, children: getSubTitle() })] }), _jsx(AgencyInfoProgress, { createdAt: agency === null || agency === void 0 ? void 0 : agency.createdAt, offersCount: stats === null || stats === void 0 ? void 0 : stats.offersCount, dealsCompleted: stats === null || stats === void 0 ? void 0 : stats.dealsCount, "data-sentry-element": "AgencyInfoProgress", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentInfo;
