import { jsx as _jsx } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { TOP } from '@shared/config/constants';
import { TopMetricHashes } from '@shared/config/metrics';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import Banner from '@shared/ui/banner';
import agenciesPNG from '../images/mobile.agencies-top.png';
import agenciesWebP from '../images/mobile.agencies-top.webp';
import agencies2XPNG from '../images/mobile.agencies-top@2x.png';
import agencies2XWebP from '../images/mobile.agencies-top@2x.webp';
import developersPNG from '../images/mobile.developers-top.png';
import developersWebP from '../images/mobile.developers-top.webp';
import developers2XPNG from '../images/mobile.developers-top@2x.png';
import developers2XWebP from '../images/mobile.developers-top@2x.webp';
import styles from './topBanners.scss';
const { API_ENV } = vars();
const banners = [{
        text: TOP.DEVELOPERS,
        link: URL.TOP_DEVELOPERS[API_ENV],
        linkText: 'Подробнее',
        elementId: TopMetricHashes.ClickButtonTopDevelopers,
        src: developersPNG,
        src2x: developers2XPNG,
        webpSrc: developersWebP,
        webpSrc2x: developers2XWebP
    }, {
        text: TOP.AGENCIES,
        link: URL.TOP_AGENCIES[API_ENV],
        linkText: 'Подробнее',
        elementId: TopMetricHashes.ClickButtonTopAgencies,
        src: agenciesPNG,
        src2x: agencies2XPNG,
        webpSrc: agenciesWebP,
        webpSrc2x: agencies2XWebP
    }];
const TopBanners = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    if (!isMobile) {
        return null;
    }
    return _jsx("section", { className: styles.container, "data-sentry-component": "TopBanners", "data-sentry-source-file": "index.tsx", children: banners.map(banner => _jsx(Banner, { banner: banner }, banner.text)) });
};
export default TopBanners;
