import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useEffect, useRef } from 'react';
import { agentsApi } from '@entities/agents';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
export const useAgentsCount = (districtIds, queryParams, enabled) => {
    const media = useAppSelector(state => state.ui.media);
    const [trigger, { isLoading, isFetching, data }] = agentsApi.useLazyGetAgentsCountQuery();
    const isFirstRender = useRef(true);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    useEffect(() => {
        if (enabled && isFirstRender.current && !isMobile) {
            trigger(queryParams).finally(() => {
                isFirstRender.current = false;
            });
        }
    }, [
        enabled,
        queryParams,
        trigger,
        isMobile
    ]);
    useEffect(() => {
        if (!isFirstRender.current && enabled && !isMobile) {
            trigger({ ...queryParams, districtIds });
        }
    }, [
        districtIds,
        queryParams,
        trigger,
        enabled,
        isMobile
    ]);
    return {
        agentsCount: (data === null || data === void 0 ? void 0 : data.result.agentsCount) || 0,
        isLoading,
        isFetching
    };
};
