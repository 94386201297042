import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent } from '@front-core/button';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment, useState } from 'react';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import AgentReviewAnswerUpdateBottomSheet from './components/bottomSheet';
import AgentReviewAnswerUpdateForm from './components/form';
import AgentReviewAnswerUpdateModal from './components/modal';
const AgentReviewAnswerUpdate = ({ review }) => {
    const media = useAppSelector(state => state.ui.media);
    const agentCasId = useAppSelector(state => { var _a; return (_a = state.agents.agent.details) === null || _a === void 0 ? void 0 : _a.casId; });
    const regionId = useAppSelector(state => state.geo.region.guid);
    const isWebView = useAppSelector(state => state.ui.isWebView);
    const userAgent = useAppSelector(state => { var _a; return (_a = state.ui.userAgent) === null || _a === void 0 ? void 0 : _a.ua; });
    const hasReviewList = useAppSelector(state => state.reviews.modals.list);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const breakpoints = useBreakpoint(media === 'mobile' ? BreakPoints.MOBILE : BreakPoints.TABLET_LANDSCAPE);
    const isTabletLandscapeAndMore = breakpoints !== BreakPoints.MOBILE && breakpoints !== BreakPoints.TABLET_PORTRAIT;
    const handleOpenModal = () => {
        setIsModalVisible(true);
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, true);
        }
        sendMetrics({
            eventType: 'click',
            elementId: review.answer ? AgentMetricHashes.ClickButtonAgentReviewAnswerEdit : AgentMetricHashes.ClickButtonAgentReviewAnswerCreate,
            elementType: 'Button',
            eventSense: {
                reviewId: review.id,
                agentCasId,
                regionId
            }
        });
    };
    const handleCloseModal = () => {
        setIsModalVisible(false);
        if (isWebView && userAgent && !hasReviewList) {
            setWebViewVisibilityTopBar(userAgent, false);
        }
    };
    return _jsxs(Fragment, { children: [_jsx(Button, { intent: Intent.Secondary, text: "\u041E\u0442\u0432\u0435\u0442\u0438\u0442\u044C", onClick: handleOpenModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewAnswerUpdateModal, { isOpen: isModalVisible, onClose: handleCloseModal, children: _jsx(AgentReviewAnswerUpdateForm, { review: review, onCancel: handleCloseModal, onSubmit: handleCloseModal }) }) : null, !isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewAnswerUpdateBottomSheet, { isOpen: isModalVisible, onClose: handleCloseModal, children: _jsx(AgentReviewAnswerUpdateForm, { review: review, onCancel: handleCloseModal, onSubmit: handleCloseModal }) }) : null] });
};
export default AgentReviewAnswerUpdate;
