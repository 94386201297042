import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent } from '@front-core/button';
import { Size } from '@front-core/dropdown-select';
import { Loader } from '@front-core/loader';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useEffect, useContext, useRef } from 'react';
import { AgentReviewAnswerUpdate } from '@features/agent/review/answer/update';
import { AgentReviewUpdate } from '@features/agent/review/update';
import { agenciesApi, AgencyAgentsReviewsPeriod } from '@entities/agencies';
import { ReviewsCard, ReviewsScope, ReviewsListSkeleton } from '@entities/reviews';
import { ContextActiveReviewDataId } from '@shared/lib/contexts/agent/activeReview';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { useIntersectionObserver } from '@shared/lib/hooks/useIntersectionObserver';
import AgentReviewsAnswerDropDown from '../answerDropDown';
import AgencyReviewsEmptyListForTheYear from '../emptyListForTheYear';
import styles from './agencyReviewsList.scss';
const AgencyReviewsList = () => {
    const agency = useAppSelector(state => state.agencies.agency.details);
    const userCasId = useAppSelector(state => state.user.casId);
    const reviews = useAppSelector(state => state.agencies.agency.reviews.list);
    const pagination = useAppSelector(state => state.agencies.agency.reviews.pagination);
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    const isPendingReview = useAppSelector(state => state.agencies.agency.reviews.isPending);
    const [triggerAgencyReviews, { isFetching, isLoading }] = agenciesApi.useLazyGetAgencyReviewsQuery();
    const activeReviewDataId = useContext(ContextActiveReviewDataId);
    const containerRef = useRef(null);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.TABLET_LANDSCAPE);
    const { observableRef, isIntersecting } = useIntersectionObserver({
        threshold: 0.1
    });
    const isPending = isLoading || isFetching;
    const isMobile = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    const hasSkeleton = !isLoading && isPendingReview;
    const hasMoreButton = (pagination === null || pagination === void 0 ? void 0 : pagination.total) && pagination.total > (reviews === null || reviews === void 0 ? void 0 : reviews.length) && !isMobile && !hasSkeleton;
    const hasReviewsEmptyListForTheYear = !reviews.length && agencyReviewQueryParams.period === AgencyAgentsReviewsPeriod.Year;
    const loadReviews = async () => {
        try {
            if (!isPending && (pagination === null || pagination === void 0 ? void 0 : pagination.total) && pagination.total > (reviews === null || reviews === void 0 ? void 0 : reviews.length)) {
                const offset = pagination.offset === 0 ? pagination === null || pagination === void 0 ? void 0 : pagination.limit : pagination.offset + (pagination === null || pagination === void 0 ? void 0 : pagination.limit);
                await triggerAgencyReviews({
                    ...agencyReviewQueryParams,
                    companyId: String(agency.id),
                    offset
                });
            }
        }
        catch (error) {
            console.error(error);
        }
    };
    const getButtonText = () => {
        const reviewCopy = [...reviews];
        if (pagination) {
            const count = pagination && pagination.total - reviewCopy.length > pagination.limit ? pagination.limit : pagination.total - reviewCopy.length;
            return StringHelper.getPluralForm(count, ['отзыв', 'отзыва', 'отзывов'], true);
        }
        return '';
    };
    const checkCurrentUser = (id) => Boolean(id && userCasId && userCasId === id);
    const scrollIntoViewCard = () => {
        if (!SSR) {
            const card = document.querySelector(`[data-id="${activeReviewDataId}"]`);
            if (card) {
                card.scrollIntoView({
                    block: 'start',
                    behavior: 'smooth'
                });
            }
        }
    };
    const handleLoadReviews = async () => {
        await loadReviews();
    };
    useEffect(() => {
        var _a;
        if (hasSkeleton && containerRef.current && !SSR) {
            (_a = containerRef.current.parentElement) === null || _a === void 0 ? void 0 : _a.scrollTo({
                top: 0
            });
        }
    }, [hasSkeleton]);
    useEffect(() => {
        if (activeReviewDataId) {
            scrollIntoViewCard();
        }
    }, []);
    useEffect(() => {
        if (isIntersecting && isMobile) {
            loadReviews().then();
        }
    }, [isIntersecting]);
    return _jsxs("div", { ref: containerRef, className: styles.agencyReviewsList, "data-sentry-component": "AgencyReviewsList", "data-sentry-source-file": "index.tsx", children: [hasReviewsEmptyListForTheYear ? _jsx(AgencyReviewsEmptyListForTheYear, {}) : null, hasSkeleton ? _jsx(ReviewsListSkeleton, {}) : null, !hasSkeleton && reviews.length ? _jsx("ul", { children: reviews.map((review, index) => {
                    var _a, _b, _c;
                    return _jsx("li", { ref: index === reviews.length - 1 ? observableRef : undefined, className: styles.item, "data-id": review.id, children: _jsx(ReviewsCard, { scope: ReviewsScope.Agency, review: review, answer: review.answer, reviewEditFeature: checkCurrentUser((_a = review.author) === null || _a === void 0 ? void 0 : _a.id) ? _jsx(AgentReviewUpdate, { review: review }) : null, answerToReviewFeature: checkCurrentUser((_b = review.agent) === null || _b === void 0 ? void 0 : _b.casId) && !review.answer ? _jsx(AgentReviewAnswerUpdate, { review: review }) : null, answerToReviewDropDown: checkCurrentUser((_c = review.agent) === null || _c === void 0 ? void 0 : _c.casId) && review.answer ? _jsx(AgentReviewsAnswerDropDown, { review: review }) : null }) }, index);
                }) }) : null, isMobile && isPending && isIntersecting ? _jsx("div", { className: styles.loader, children: _jsx(Loader, {}) }) : null, hasMoreButton ? _jsx(Button, { text: `Показать ещё ${getButtonText()}`, loading: isPending, size: Size.Large, intent: Intent.Secondary, wrapperClassName: styles.moreButton, onClick: handleLoadReviews }) : null] });
};
export default AgencyReviewsList;
