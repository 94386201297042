import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { setTopAppBarTitle } from '@rcf/webview-interfaces';
import clsx from 'clsx';
import { Fragment, useEffect, useRef, useState } from 'react';
import { generatePath, useMatch } from 'react-router-dom';
import { AgencyAboutCompany } from '@widgets/agency/aboutCompany';
import { AgencyAgentList } from '@widgets/agency/agentList';
import { AgencyDeals } from '@widgets/agency/deals';
import { AgencyInfo } from '@widgets/agency/info';
import { AgencyObjectsMap } from '@widgets/agency/objectsMap';
import { AgencyOffersRent } from '@widgets/agency/offers/rent';
import { AgencyOffersSale } from '@widgets/agency/offers/sale';
import { AgencyOffices } from '@widgets/agency/offices';
import { AgencyReviews } from '@widgets/agency/reviews';
import CSIAgency from '@widgets/csi/agency';
import { SEO } from '@widgets/seo';
import { AgencyCreateSubstitutePhoneButton } from '@features/agency/createSubstitutePhone';
import { agenciesApi, agenciesSlice } from '@entities/agencies';
import { AgentsSortScope } from '@shared/config/common';
import { PATH } from '@shared/config/constants';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { UrlHelper } from '@shared/lib/helpers/url';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import Breadcrumbs from '@shared/ui/breadcrumbs';
import ErrorFallback from '@shared/ui/errorFallback';
import LayoutGridWithStickyAside from '@shared/ui/layout/gridWithStickyAside';
import styles from './agency.scss';
import AgencyAside from './components/aside';
import AgencyRecommendations from './components/recommendations';
import AgencyPageSkeleton from './components/skeleton';
const { API_ENV } = vars();
const AgencyPage = () => {
    var _a;
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details; });
    const canonical = useAppSelector(state => { var _a; return (_a = state.url) === null || _a === void 0 ? void 0 : _a.canonical; });
    const regionId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region) === null || _b === void 0 ? void 0 : _b.guid; });
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const geoCity = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const media = useAppSelector(state => state.ui.media);
    const isAgencyUpdatePage = useAppSelector(state => state.agencies.agency.isUpdatePage);
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    const dispatch = useAppDispatch();
    const [triggerAgency, { isError }] = agenciesApi.useLazyGetAgencyQuery();
    const [triggerAgencyAgentsSort] = agenciesApi.useLazyGetAgenciesAgentsSortQuery();
    const [triggerAgencyAgents] = agenciesApi.useLazyGetAgenciesAgentsQuery();
    const [triggerAgencyReviews] = agenciesApi.useLazyGetAgencyReviewsQuery();
    const [triggerAgencyOffices] = agenciesApi.useLazyGetAgenciesOfficesQuery();
    const matchPath = useMatch(PATH.AGENCY);
    const [isLoading, setIsLoading] = useState(false);
    const isFirstRenderRef = useRef(true);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint !== BreakPoints.DESKTOP && breakpoint !== BreakPoints.TABLET_LANDSCAPE;
    const isLoadingContent = isLoading || isAgencyUpdatePage;
    const isShowResult = !isAgencyUpdatePage && !isLoading && (agency === null || agency === void 0 ? void 0 : agency.id);
    const agencyName = (agency === null || agency === void 0 ? void 0 : agency.name) || (agency === null || agency === void 0 ? void 0 : agency.nameLegal) || '';
    const locationName = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}`;
    const isShowError = !((_a = matchPath === null || matchPath === void 0 ? void 0 : matchPath.params) === null || _a === void 0 ? void 0 : _a.companyId) || isError;
    const initialize = async () => {
        var _a, _b;
        const searchParams = UrlHelper.getUrlParams();
        const currentRegionId = ((searchParams === null || searchParams === void 0 ? void 0 : searchParams.regionId) || regionId);
        isFirstRenderRef.current = false;
        setIsLoading(true);
        try {
            const responseAgency = await triggerAgency({
                agencySlug: (_a = matchPath === null || matchPath === void 0 ? void 0 : matchPath.params) === null || _a === void 0 ? void 0 : _a.companyId,
                regionId: currentRegionId
            });
            if (responseAgency === null || responseAgency === void 0 ? void 0 : responseAgency.data) {
                const companyId = Number(responseAgency.data.result.id);
                const responseSort = await triggerAgencyAgentsSort({
                    scope: AgentsSortScope.Agency
                });
                if (responseSort === null || responseSort === void 0 ? void 0 : responseSort.data) {
                    const params = {
                        companyId,
                        regionId: currentRegionId,
                        order: ((_b = responseSort.data.result.order.options[0]) === null || _b === void 0 ? void 0 : _b.value) || null,
                        offset: 0,
                        limit: 5
                    };
                    dispatch(agenciesSlice.setAgentsSearchParams(params));
                    await triggerAgencyAgents(params);
                }
                await triggerAgencyReviews({
                    ...agencyReviewQueryParams,
                    companyId: String(companyId),
                    offset: 0
                });
                await triggerAgencyOffices({
                    companyId,
                    regionId: currentRegionId,
                    offset: 0,
                    limit: 5
                });
                dispatch(agenciesSlice.setAgencyReviewsQueryParams({
                    companyId: String(companyId),
                    offset: 0
                }));
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    };
    useEffect(() => {
        if (isWebView) {
            setTopAppBarTitle('Агентство недвижимости');
        }
        if (!SSR) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0
                });
            }, 0);
        }
    }, []);
    useEffect(() => {
        if ((matchPath === null || matchPath === void 0 ? void 0 : matchPath.params.companyId) !== (agency === null || agency === void 0 ? void 0 : agency.slug) && !SSR && isFirstRenderRef.current) {
            initialize().then();
        }
    }, [matchPath]);
    return _jsxs(Fragment, { children: [_jsx(SEO, { title: `${agencyName} ${locationName} - агентство недвижимости, отзывы, контакты, объекты недвижимости, риэлторы агентства - Домклик`, description: `${agencyName} - агентство недвижимости ${locationName} 🏆 Рейтинг агентства и актуальные данные по совершенным сделкам 💬 Отзывы от проверенных клиентов 📞Контакты и актуальные объекты агентства и риэлторов`, keywords: `${agencyName} агентство недвижимости`, breadcrumbScript: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "item":
                   {
                     "@id": "${URL.MAIN[API_ENV]}",
                     "name": "Домклик"
                   }
              },
              {
                "@type": "ListItem",
                "position": "2",
                "item":
                   {
                     "@id": "${URL.AGENCIES[API_ENV].replace('/:regionSlug', '')}",
                     "name": "🔸 АГЕНТСТВА"
                   }
              },
              {
                "@type": "ListItem",
                "position": "3",
                "item":
                   {
                     "@id": "${generatePath(PATH.AGENCIES, {
                    regionSlug
                })}",
                     "name": "Поиск агентства ${locationName}"
                   }
              },
              {
                "@type": "ListItem",
                "position": "4",
                "item":
                   {
                     "@id": "${canonical}",
                     "name": "${agencyName}"
                   }
              }
            ]
          }`, "data-sentry-element": "SEO", "data-sentry-source-file": "index.tsx" }), _jsx(AgencyPageSkeleton, { isPending: isLoadingContent, "data-sentry-element": "AgencyPageSkeleton", "data-sentry-source-file": "index.tsx" }), _jsx(ErrorFallback, { isShow: isShowError, "data-sentry-element": "ErrorFallback", "data-sentry-source-file": "index.tsx" }), isShowResult ? _jsxs(Fragment, { children: [_jsx(LayoutGridWithStickyAside, { mainContent: _jsxs(Fragment, { children: [_jsx(AgencyInfo, {}), _jsx(AgencyAboutCompany, {}), _jsx(AgencyReviews, {}), _jsx(AgencyAgentList, {}), _jsx(AgencyOffices, {}), _jsx(AgencyObjectsMap, {}), _jsx(AgencyOffersSale, {}), _jsx(AgencyOffersRent, {}), _jsx(AgencyDeals, {}), _jsx(CSIAgency, {}), isMobile ? _jsxs(Fragment, { children: [_jsx(AgencyRecommendations, {}), _jsx(Paper, { className: clsx(styles.stickyButton, {
                                                [styles.isWebView]: isWebView
                                            }), children: _jsx(AgencyCreateSubstitutePhoneButton, { agencyId: agency === null || agency === void 0 ? void 0 : agency.id }) })] }) : null] }), asideContent: _jsx(AgencyAside, {}) }), _jsx("div", { className: styles.breadcrumbs, children: _jsx(Breadcrumbs, {}) })] }) : null] });
};
export default AgencyPage;
