import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelStandardMedium } from '@front-core/typography';
import styles from './achievementsTablet.scss';
import AchievementsTabletItem from './item';
const AchievementsTablet = ({ slots }) => {
    const slicedSlots = slots.length > 6 ? slots.slice(0, 6) : slots;
    if (slicedSlots.length === 0) {
        return null;
    }
    return _jsxs("div", { className: styles.achievementsTablet, "data-sentry-component": "AchievementsTablet", "data-sentry-source-file": "index.tsx", children: [_jsx(LabelStandardMedium, { wrapper: false, className: styles.title, tag: "strong", "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: "\u0414\u0438\u043F\u043B\u043E\u043C\u044B, \u0430\u0442\u0442\u0435\u0441\u0442\u0430\u0442\u044B, \u043D\u0430\u0433\u0440\u0430\u0434\u044B" }), _jsx("ul", { className: styles.list, children: slicedSlots.map((slot, index) => _jsx("li", { className: styles.item, children: _jsx(AchievementsTabletItem, { index: index, slots: slicedSlots, src: slot.src, title: slot.title }) }, index)) })] });
};
export default AchievementsTablet;
