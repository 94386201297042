import { AgentDealType } from '@entities/agents';
export const dealTypeOptions = [
    {
        title: 'Любая',
        value: AgentDealType.Any
    },
    {
        title: 'Покупка и продажа',
        value: AgentDealType.Sale
    },
    {
        title: 'Аренда',
        value: AgentDealType.Rent
    }
];
