import { researchApi } from '@shared/api/research';
import { ApiUrls } from '../config/apiUrls';
export const callsApi = researchApi.injectEndpoints({
    endpoints: builder => ({
        sendCall: builder.mutation({
            query: body => ({
                url: ApiUrls.Calls,
                method: 'POST',
                body
            })
        })
    })
});
export const { useSendCallMutation } = callsApi;
