import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Size, Intent } from '@front-core/button';
import { EmptyScreen } from '@front-core/empty-screen';
import { Illustration, IllustrationSize, Illustrations, IllustrationFormat } from '@front-core/illustration';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agencyAgentListEmpty.scss';
const AgencyAgentListEmpty = ({ onClickReset }) => {
    const agency = useAppSelector(state => state.agencies.agency.details);
    const search = useAppSelector(state => state.agencies.agency.agents.searchParams.search);
    const getTitle = () => {
        if (search && search.length > 0) {
            return `${search} нет в ${agency.name}`;
        }
        return `Такого риелтора нет в  ${agency.name}`;
    };
    return _jsx("div", { className: styles.container, "data-sentry-component": "AgencyAgentListEmpty", "data-sentry-source-file": "index.tsx", children: _jsx(EmptyScreen, { title: getTitle(), text: "\u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435, \u043D\u0435\u0442 \u043B\u0438 \u043E\u0448\u0438\u0431\u043E\u043A \u0432 \u0434\u0430\u043D\u043D\u044B\u0445, \u0438 \u043F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0437\u0430\u043F\u0440\u043E\u0441", image: _jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box" }), buttons: [_jsx(Button, { text: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A", onClick: onClickReset, size: Size.Large, intent: Intent.Secondary }, "search-reset-button")], "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "index.tsx" }) });
};
export default AgencyAgentListEmpty;
