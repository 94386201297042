import { jsx as _jsx } from "react/jsx-runtime";
import { CSI } from '@clic/csi_widget_ssr';
import { useEffect, useRef } from 'react';
import { csiApi } from '@entities/csi';
import { SurveyScopes } from '@entities/csi/config/surveyScopes';
import { SurveyStatuses } from '@entities/csi/config/surveyStatuses';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './csiAgencies.scss';
import '@clic/csi_widget_ssr/dist/main.css';
const { API_ENV } = vars();
const CSIAgencies = () => {
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [csiMutate, { data }] = csiApi.useCreateAgenciesSurveyMutation();
    const isFirstRender = useRef(true);
    const csi = data === null || data === void 0 ? void 0 : data.result;
    useEffect(() => {
        if (!SSR && isFirstRender.current) {
            isFirstRender.current = false;
            csiMutate({
                scope: SurveyScopes.Agencies,
                context: {
                    regionId
                }
            }).then();
        }
    }, []);
    if ((csi === null || csi === void 0 ? void 0 : csi.status) === SurveyStatuses.Completed || !csi) {
        return null;
    }
    return _jsx("div", { className: styles.container, "data-sentry-component": "CSIAgencies", "data-sentry-source-file": "index.tsx", children: _jsx(CSI, { env: API_ENV, hideFinishScreen: true, surveyUID: csi.id, "data-sentry-element": "CSI", "data-sentry-source-file": "index.tsx" }) });
};
export default CSIAgencies;
