import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Checkbox } from '@front-core/checkbox';
import { clsx } from 'clsx';
import { memo } from 'react';
import styles from './agentsSearchDistrictModalCheckBoxList.scss';
const AgentsSearchDistrictCheckBoxList = ({ districts, districtIdsSet, onParentChange, onChildChange }) => _jsx("ul", { className: styles.agentsSearchDistrictModalCheckBoxList, "data-sentry-component": "AgentsSearchDistrictCheckBoxList", "data-sentry-source-file": "index.tsx", children: districts.map(district => {
        var _a;
        const hasChildren = (_a = district.children) === null || _a === void 0 ? void 0 : _a.length;
        return _jsxs("li", { className: clsx(styles.item, {
                [styles.mainItem]: hasChildren
            }), children: [_jsx(Checkbox, { id: String(district.id), className: clsx({
                        [styles.mainCheckBox]: hasChildren
                    }), label: district.name, value: String(district.id), checked: districtIdsSet.has(district.id), onChange: onParentChange }), hasChildren ? _jsx("ul", { className: styles.secondList, children: district.children.map(districtChildren => _jsx("li", { children: _jsx(Checkbox, { id: String(districtChildren.id), label: districtChildren.name, value: String(districtChildren.id), checked: districtIdsSet.has(districtChildren.id), onChange: onChildChange }) }, districtChildren.id)) }) : null] }, district.id);
    }) });
export default memo(AgentsSearchDistrictCheckBoxList);
