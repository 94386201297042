import { Unicode } from '@shared/config/unicode';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import item2JPG from '@shared/images/faq/item-2.jpg';
import item2WebP from '@shared/images/faq/item-2.webp';
import item2JPG2x from '@shared/images/faq/item-2@2x.jpg';
import item2WebP2x from '@shared/images/faq/item-2@2x.webp';
import item3JPG from '@shared/images/faq/item-3.jpg';
import item3WebP from '@shared/images/faq/item-3.webp';
import item3JPG2x from '@shared/images/faq/item-3@2x.jpg';
import item3WebP2x from '@shared/images/faq/item-3@2x.webp';
import item4JPG from '@shared/images/faq/item-4.jpg';
import item4WebP from '@shared/images/faq/item-4.webp';
import item4JPG2x from '@shared/images/faq/item-4@2x.jpg';
import item4WebP2x from '@shared/images/faq/item-4@2x.webp';
import item5PNG from '@shared/images/faq/item-5.png';
import item5WebP from '@shared/images/faq/item-5.webp';
import item5PNG2x from '@shared/images/faq/item-5@2x.png';
import item5WebP2x from '@shared/images/faq/item-5@2x.webp';
import agent1JPG from '../images/agent-1.jpg';
import agent1WebP from '../images/agent-1.webp';
import agent1JPG2x from '../images/agent-1@2x.jpg';
import agent1WebP2x from '../images/agent-1@2x.webp';
import agent2JPG from '../images/agent-2.jpg';
import agent2WebP from '../images/agent-2.webp';
import agent2JPG2x from '../images/agent-2@2x.jpg';
import agent2WebP2x from '../images/agent-2@2x.webp';
import agent3JPG from '../images/agent-3.jpg';
import agent3WebP from '../images/agent-3.webp';
import agent3JPG2x from '../images/agent-3@2x.jpg';
import agent3WebP2x from '../images/agent-3@2x.webp';
import agent4JPG from '../images/agent-4.jpg';
import agent4WebP from '../images/agent-4.webp';
import agent4JPG2x from '../images/agent-4@2x.jpg';
import agent4WebP2x from '../images/agent-4@2x.webp';
const { API_ENV } = vars();
export const howBuildRatingItems = [
    {
        title: 'Профиль',
        description: 'В разделе «Риелторы» в первую очередь отображаются специалисты с подтверждёнными и заполненными профилями. У них добавлено фото и указана информация о себе.',
        primaryImage: {
            src: agent1JPG,
            src2x: agent1JPG2x,
            webpSrc: agent1WebP,
            webpSrc2x: agent1WebP2x
        },
        secondaryImage: {
            src: item5PNG,
            src2x: item5PNG2x,
            webpSrc: item5WebP,
            webpSrc2x: item5WebP2x
        },
        linkTo: URL.PRO_DASHBOARD_PROFILE[API_ENV],
        linkText: 'Редактировать профиль'
    },
    {
        title: 'Отзывы',
        description: 'Чем больше отзывов, тем лучше. Ответы на отзывы выражают заинтересованность и вовлечённость риелтора.',
        primaryImage: {
            src: agent2JPG,
            src2x: agent2JPG2x,
            webpSrc: agent2WebP,
            webpSrc2x: agent2WebP2x
        },
        secondaryImage: {
            src: item2JPG,
            src2x: item2JPG2x,
            webpSrc: item2WebP,
            webpSrc2x: item2WebP2x
        }
    },
    {
        title: 'Сделки',
        description: `На оценку влияют сделки, совершённые за последние 12${Unicode.NoBreakSpace}месяцев. По их количеству можно судить об активности риелтора.`,
        primaryImage: {
            src: agent3JPG,
            src2x: agent3JPG2x,
            webpSrc: agent3WebP,
            webpSrc2x: agent3WebP2x
        },
        secondaryImage: {
            src: item4JPG,
            src2x: item4JPG2x,
            webpSrc: item4WebP,
            webpSrc2x: item4WebP2x
        }
    },
    {
        title: 'Объявления',
        description: `Учитываются объявления, размещённые за последние 12${Unicode.NoBreakSpace}месяцев.${Unicode.NextLine}Клиенты могут открыть информацию о риелторе, посмотреть его объявления и найти подходящее.`,
        primaryImage: {
            src: agent4JPG,
            src2x: agent4JPG2x,
            webpSrc: agent4WebP,
            webpSrc2x: agent4WebP2x
        },
        secondaryImage: {
            src: item3JPG,
            src2x: item3JPG2x,
            webpSrc: item3WebP,
            webpSrc2x: item3WebP2x
        }
    }
];
