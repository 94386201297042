import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CheckCircleFilled24 from '@front-core/icon/dist/v2/CheckCircleFilled24';
import { HeadingStandard, BodyXLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutHero from '@shared/ui/layout/hero';
import desktopDocumentsPNG from '../../../images/desktop.documents.png';
import desktopDocumentsWebP from '../../../images/desktop.documents.webp';
import desktopDocumentsPNG2x from '../../../images/desktop.documents@2x.png';
import desktopDocumentsWebP2x from '../../../images/desktop.documents@2x.webp';
import mobileDocumentsPNG from '../../../images/mobile.documents.png';
import mobileDocumentsWebP from '../../../images/mobile.documents.webp';
import mobileDocumentsPNG2x from '../../../images/mobile.documents@2x.png';
import mobileDocumentsWebP2x from '../../../images/mobile.documents@2x.webp';
import styles from './faqChoiceAgentHero.scss';
const Advantages = ['Выгодно купить или продать недвижимость', 'Проверить недвижимость и документы', 'Организовать и провести сделку'];
const FAQChoiceAgentHero = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.DESKTOP ? BreakPoints.DESKTOP : BreakPoints.MOBILE);
    const isMobile = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    const getImage = () => ({
        src: mobileDocumentsPNG,
        src2x: mobileDocumentsPNG2x,
        width: isMobile ? 343 : 508,
        height: isMobile ? 244 : 312,
        sources: [{
                type: 'image/webp',
                media: '(min-width: 1024px)',
                srcSet: `${desktopDocumentsWebP} 1x, ${desktopDocumentsWebP2x} 2x`
            }, {
                type: 'image/png',
                media: '(min-width: 1024px)',
                srcSet: `${desktopDocumentsPNG} 1x, ${desktopDocumentsPNG2x} 2x`
            }, {
                type: 'image/webp',
                srcSet: `${mobileDocumentsWebP} 1x, ${mobileDocumentsWebP2x} 2x`
            }]
    });
    return _jsx(LayoutHero, { isFullWidthTitle: true, title: "\u041F\u043E\u0434\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430 \u043D\u0430 \u0414\u043E\u043C\u043A\u043B\u0438\u043A", image: getImage(), "data-sentry-element": "LayoutHero", "data-sentry-component": "FAQChoiceAgentHero", "data-sentry-source-file": "index.tsx", children: _jsxs(Fragment, { children: [_jsx(HeadingStandard, { tag: "strong", className: styles.title, wrapper: false, "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Чем специалист может вам помочь') }), _jsx("ul", { className: styles.list, children: Advantages.map((advantage, index) => _jsxs("li", { className: styles.item, children: [_jsx(CheckCircleFilled24, {}), _jsx(BodyXLargeRegular, { tag: "span", wrapper: false, children: StringHelper.addNbsp(advantage) })] }, index)) })] }) });
};
export default FAQChoiceAgentHero;
