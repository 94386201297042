import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { clsx } from 'clsx';
import { Fragment, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { agenciesSlice, agenciesApi } from '@entities/agencies';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { NumberHelper } from '@shared/lib/helpers/number';
import { UrlHelper } from '@shared/lib/helpers/url';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agenciesList.scss';
import AgenciesListEmpty from './components/emptyScreen';
import AgenciesListHeader from './components/header';
import AgenciesListResult from './components/result';
import AgenciesListSkeleton from './components/skeleton';
import AgenciesListStickyHeader from './components/stickyHeader';
const AgenciesList = () => {
    const agencies = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.list; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const isPendingList = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.isPendingList; });
    const pagination = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.pagination; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const [searchParams] = useSearchParams();
    const currentParams = useMemo(() => {
        let params = {
            ...queryParams
        };
        for (const queryParam in queryParams) {
            if (searchParams.has(queryParam)) {
                params = {
                    ...params,
                    [queryParam]: searchParams.get(queryParam)
                };
            }
            else if (searchParams.has('page')) {
                params = {
                    ...params,
                    offset: NumberHelper.getOffset(Number(searchParams.get('page')), pagination.limit)
                };
            }
        }
        return {
            ...params,
            regionId: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.regionId) || regionId || null
        };
    }, []);
    const { isFetching, isLoading } = agenciesApi.useGetAgenciesQuery(currentParams, {
        skip: SSR
    });
    const totalCount = (pagination === null || pagination === void 0 ? void 0 : pagination.total) || 0;
    const isPending = isLoading || isFetching || isPendingList;
    const isShowEmptyContainer = !(agencies === null || agencies === void 0 ? void 0 : agencies.length) && !isPending;
    const isLoadingList = !SSR && isPending;
    const handleChangePage = (page) => {
        const offset = NumberHelper.getOffset(page, queryParams.limit);
        sendMetrics({
            eventType: 'click',
            elementId: AgenciesMetricHashes.ClickAgenciesListPagination,
            elementType: 'Pagination',
            eventSense: {
                page
            }
        });
        dispatch(agenciesSlice.setQueryParams({
            offset
        }));
        if (!SSR) {
            setTimeout(() => {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
            }, 0);
        }
    };
    const handlePopState = () => {
        const urlParams = UrlHelper.getUrlParams();
        if (urlParams === null || urlParams === void 0 ? void 0 : urlParams.page) {
            const offset = NumberHelper.getOffset(Number(urlParams.page), queryParams.limit);
            dispatch(agenciesSlice.setQueryParams({
                offset
            }));
        }
        else {
            dispatch(agenciesSlice.setQueryParams({
                offset: 0
            }));
        }
    };
    useEffect(() => {
        if (!SSR && !isWebView) {
            window.addEventListener('popstate', handlePopState);
        }
        return () => {
            if (!SSR && !isWebView) {
                window.removeEventListener('popstate', handlePopState);
            }
        };
    }, []);
    return _jsxs(Paper, { tag: "section", className: clsx(styles.container, {
            [styles.isEmpty]: isShowEmptyContainer
        }, {
            [styles.isWebView]: isWebView
        }), "data-sentry-element": "Paper", "data-sentry-component": "AgenciesList", "data-sentry-source-file": "index.tsx", children: [isShowEmptyContainer ? _jsx(AgenciesListEmpty, { regionId: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.regionId) || regionId }) : _jsxs(Fragment, { children: [_jsx(AgenciesListHeader, { isLoading: isLoadingList, totalCount: totalCount }), _jsx(AgenciesListStickyHeader, {})] }), _jsx(AgenciesListResult, { agencies: agencies, isShow: !isLoadingList && !isShowEmptyContainer, onChangePage: handleChangePage, pagination: pagination, "data-sentry-element": "AgenciesListResult", "data-sentry-source-file": "index.tsx" }), _jsx(AgenciesListSkeleton, { isShowSkeleton: !isShowEmptyContainer && isLoadingList, "data-sentry-element": "AgenciesListSkeleton", "data-sentry-source-file": "index.tsx" })] });
};
export default AgenciesList;
