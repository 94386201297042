import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import styles from './skeleton.scss';
const cards = Array.from({
    length: 5
}, (_value, index) => index + 1);
const AgencyOfficeListSkeleton = ({ isShowSkeleton }) => {
    const breakpoint = useBreakpoint(BreakPoints.MOBILE);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    if (!isShowSkeleton) {
        return null;
    }
    return _jsxs("div", { className: styles.container, "data-sentry-component": "AgencyOfficeListSkeleton", "data-sentry-source-file": "index.tsx", children: [_jsx("div", { className: styles.list, children: cards.map(card => _jsxs("div", { className: styles.cardContainer, children: [_jsxs("div", { className: styles.cardInner, children: [_jsx(Skeleton, { type: SkeletonType.Text, width: "70%", height: "16px" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "70%", height: "16px" }), _jsxs("div", { className: styles.partnerShip, children: [_jsx(Skeleton, { type: SkeletonType.Circle, width: "24px", height: "24px" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "70px", height: "16px" })] })] }), _jsx("div", { className: styles.button, children: _jsx(Skeleton, { type: SkeletonType.Text, width: isMobile ? '56px' : '32px', height: isMobile ? '56px' : '32px' }) })] }, card)) }), _jsxs("div", { className: styles.pagination, children: [_jsx(Skeleton, { type: SkeletonType.Circle, width: "20px", height: "20px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Text, width: "32px", height: "40px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx(Skeleton, { type: SkeletonType.Circle, width: "20px", height: "20px", "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" })] })] });
};
export default AgencyOfficeListSkeleton;
