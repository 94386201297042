import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useState, useEffect } from 'react';
import { getDefaultMapDealsType } from '@shared/lib/getDefaultMapDealsType';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutMap from '@shared/ui/layout/map';
import styles from './agencyObjectMap.scss';
import AgencyObjectsMapFilters from './components/filters';
const AgencyObjectsMap = () => {
    var _a, _b, _c;
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details; });
    const agencyOffers = useAppSelector(state => state.offers.agency);
    const offersCount = (_a = agency === null || agency === void 0 ? void 0 : agency.stats) === null || _a === void 0 ? void 0 : _a.offersCount;
    const salesTotal = ((_b = agencyOffers.sales.pagination) === null || _b === void 0 ? void 0 : _b.total) || 0;
    const rentsTotal = ((_c = agencyOffers.rents.pagination) === null || _c === void 0 ? void 0 : _c.total) || 0;
    const [mapParams, setMapParams] = useState({
        companyId: (agency === null || agency === void 0 ? void 0 : agency.id) ? String(agency.id) : '',
        companyName: (agency === null || agency === void 0 ? void 0 : agency.name) || (agency === null || agency === void 0 ? void 0 : agency.nameLegal) || '',
        companyHoldingId: (agency === null || agency === void 0 ? void 0 : agency.holdingId) ? String(agency === null || agency === void 0 ? void 0 : agency.holdingId) : '',
        dealType: getDefaultMapDealsType({
            salesTotal,
            rentsTotal
        }),
        category: 'all'
    });
    const [isLoading, setIsLoading] = useState(true);
    const handleChangeFilter = (value) => {
        setIsLoading(true);
        setMapParams(prevState => ({
            ...prevState,
            dealType: value
        }));
    };
    useEffect(() => {
        if (isLoading) {
            setIsLoading(false);
        }
    }, [isLoading]);
    if (!offersCount || !(agency === null || agency === void 0 ? void 0 : agency.id)) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", className: styles.agencyObjectMap, "data-sentry-element": "Paper", "data-sentry-component": "AgencyObjectsMap", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h4", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430" }), _jsx(LayoutMap, { mapParams: mapParams, isLoading: isLoading, "data-sentry-element": "LayoutMap", "data-sentry-source-file": "index.tsx" }), _jsx(AgencyObjectsMapFilters, { salesTotal: salesTotal, rentsTotal: rentsTotal, onChange: handleChangeFilter, "data-sentry-element": "AgencyObjectsMapFilters", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyObjectsMap;
