import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
export const useIntersectionObserver = ({ targetId, threshold = 0, root = null, rootMargin = '0%', freezeOnceVisible = false, initialIsIntersecting = false, onChange } = {}) => {
    var _a;
    const location = useLocation();
    const [ref, setRef] = useState(null);
    const [state, setState] = useState(() => ({
        isIntersecting: initialIsIntersecting,
        entry: undefined
    }));
    const callbackRef = useRef();
    callbackRef.current = onChange;
    const frozen = ((_a = state.entry) === null || _a === void 0 ? void 0 : _a.isIntersecting) && freezeOnceVisible;
    let unobserve;
    const updateIntersection = (entries, thresholds) => {
        entries.forEach(entry => {
            const isIntersecting = entry.isIntersecting
                && thresholds.some(currentThreshold => entry.intersectionRatio >= currentThreshold);
            setState({
                isIntersecting,
                entry
            });
            if (callbackRef.current) {
                callbackRef.current(isIntersecting, entry);
            }
            if (isIntersecting && freezeOnceVisible && unobserve) {
                unobserve();
                unobserve = undefined;
            }
        });
    };
    useEffect(() => {
        if (!ref && !targetId) {
            return undefined;
        }
        if (!('IntersectionObserver' in window)) {
            return undefined;
        }
        if (frozen) {
            return undefined;
        }
        const observer = new IntersectionObserver((entries) => {
            const thresholds = Array.isArray(observer.thresholds)
                ? observer.thresholds
                : [observer.thresholds];
            updateIntersection(entries, thresholds);
        }, {
            threshold,
            root,
            rootMargin
        });
        if (targetId) {
            const target = document === null || document === void 0 ? void 0 : document.getElementById(targetId);
            if (target) {
                observer.observe(target);
            }
        }
        else if (ref) {
            observer.observe(ref);
        }
        return () => {
            observer.disconnect();
        };
    }, [
        ref,
        targetId,
        JSON.stringify(threshold),
        root,
        rootMargin,
        frozen,
        freezeOnceVisible,
        location.pathname
    ]);
    const prevRef = useRef(null);
    useEffect(() => {
        var _a;
        const target = targetId ? document === null || document === void 0 ? void 0 : document.getElementById(targetId) : null;
        const hasElement = ref || target;
        if (!hasElement
            && ((_a = state.entry) === null || _a === void 0 ? void 0 : _a.target)
            && !freezeOnceVisible
            && !frozen
            && prevRef.current !== state.entry.target) {
            prevRef.current = state.entry.target;
            setState({
                isIntersecting: initialIsIntersecting,
                entry: undefined
            });
        }
    }, [
        targetId,
        ref,
        state.entry,
        freezeOnceVisible,
        frozen,
        initialIsIntersecting
    ]);
    return {
        observableRef: setRef,
        isIntersecting: Boolean(state.isIntersecting),
        entry: state.entry
    };
};
