import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertList, AlertStatus } from '@front-core/alert';
import { Button, Size } from '@front-core/button';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment, useState, useEffect, memo } from 'react';
import { AgentMetricHashes } from '@shared/config/metrics';
import { YandexMetricaSections } from '@shared/config/yandexMetrica/sections';
import { YandexMetricaTargets } from '@shared/config/yandexMetrica/targets';
import { YandexMetricaHelper } from '@shared/lib/helpers/yandexMetrica';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { useAgentSubstitutePhone } from '../../lib/useAgentSubstitutePhone';
import CreateSubstituteModal from './modal';
var AlertListIds;
(function (AlertListIds) {
    AlertListIds["SubstitutePhoneError"] = "1";
})(AlertListIds || (AlertListIds = {}));
const AgentCreateSubstitutePhoneModal = ({ agentCasId }) => {
    const districtIds = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams) === null || _b === void 0 ? void 0 : _b.districtIds; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const media = useAppSelector(state => state.ui.media);
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const [alertList, setAlertList] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const { errorMessage, isPending, substitutePhone, phoneNumber } = useAgentSubstitutePhone({
        agentCasId
    });
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const handleToggleModal = () => {
        setIsModalOpen(prevState => !prevState);
    };
    const handleClick = () => {
        substitutePhone().then(() => {
            setIsModalOpen(true);
            sendMetrics({
                eventType: 'click',
                elementId: AgentMetricHashes.ClickButtonCreateSubstitute,
                elementType: 'button',
                eventSense: {
                    agentCasId,
                    regionId,
                    districtGuid: districtIds ? JSON.stringify(districtIds) : ''
                }
            });
        });
        if (userCasId) {
            YandexMetricaHelper.reachGoal({
                targetName: YandexMetricaTargets.AgentsCall,
                params: {
                    casId: userCasId,
                    section: YandexMetricaSections.Agents
                }
            });
        }
    };
    useEffect(() => {
        if (errorMessage) {
            setAlertList([{
                    id: AlertListIds.SubstitutePhoneError,
                    showCloseIcon: true,
                    title: errorMessage,
                    description: 'Попробуйте ещё раз',
                    duration: 3,
                    status: AlertStatus.ERROR
                }]);
        }
    }, [errorMessage]);
    return _jsxs(Fragment, { children: [_jsx(Button, { fluid: true, size: isMobile ? Size.Medium : Size.Small, text: isMobile ? 'Позвонить' : 'Показать телефон', loading: isPending, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" }), isModalOpen && phoneNumber ? _jsx(CreateSubstituteModal, { agentCasId: agentCasId, isModalOpen: isModalOpen, phoneNumber: phoneNumber, onClose: handleToggleModal }) : null] });
};
export default memo(AgentCreateSubstitutePhoneModal);
