import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { AgenciesAgentCard } from '@entities/agencies';
import AgencyAgentListPagination from '../pagination';
import styles from './agenciesAgentListResult.scss';
const AgencyAgentListResult = ({ agents, isShow, pagination, onChangePage }) => {
    if (!isShow) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: styles.agenciesAgentListResult, children: agents.map(agent => _jsx(AgenciesAgentCard, { agent: agent }, agent.casId)) }), _jsx(AgencyAgentListPagination, { pagination: pagination, onChange: onChangePage, "data-sentry-element": "AgencyAgentListPagination", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyAgentListResult;
