import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { AgencyAgentListSort } from '@features/agency/agentList/sort';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentListControls.scss';
import AgencyAgentsSearchDesktop from './search';
import AgencyAgentsSearchMobile from './search/mobile';
const AgencyAgentListControls = ({ isDisabledSort, onSearch, onChangeOrder }) => {
    const searchParams = useAppSelector(state => state.agencies.agency.agents.searchParams);
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsxs("div", { className: styles.container, "data-sentry-component": "AgencyAgentListControls", "data-sentry-source-file": "index.tsx", children: [_jsx(AgencyAgentListSort, { isDisabled: isDisabledSort, onChangeOrder: onChangeOrder, orderValue: (searchParams === null || searchParams === void 0 ? void 0 : searchParams.order) || undefined, "data-sentry-element": "AgencyAgentListSort", "data-sentry-source-file": "index.tsx" }), isMobile ? _jsx(AgencyAgentsSearchMobile, { defaultValue: searchParams.search, onSubmit: onSearch }) : _jsx(AgencyAgentsSearchDesktop, { defaultValue: searchParams.search, onChange: onSearch })] });
};
export default AgencyAgentListControls;
