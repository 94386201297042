import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Size, Intent } from '@front-core/button';
import { EmptyScreen } from '@front-core/empty-screen';
import { Illustration, Illustrations, IllustrationSize, IllustrationFormat } from '@front-core/illustration';
import { usePrevious, useBreakpoint, BreakPoints } from '@front-core/utils';
import { useEffect, Fragment } from 'react';
import { shallowEqual } from 'react-redux';
import { AgentsSearchRegion } from '@features/agents/search/region';
import { agentsSlice, agentsDefaultQueryParams } from '@entities/agents';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './emptyScreen.scss';
const AgentsListEmpty = ({ regionId }) => {
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const dispatch = useAppDispatch();
    const prevRegionId = usePrevious(regionId);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const isChangedRegionId = String(prevRegionId) !== String(regionId);
    const isEmptySearchParams = shallowEqual(queryParams, agentsDefaultQueryParams);
    const getTitle = () => {
        var _a;
        if (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) {
            return `${queryParams.search} нет на Домклик`;
        }
        if ((_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.districtIds) === null || _a === void 0 ? void 0 : _a.length) {
            return 'Нет риелторов в выбранном районе';
        }
        return 'Такого риелтора нет на Домклик';
    };
    const getText = () => {
        var _a;
        if ((_a = queryParams === null || queryParams === void 0 ? void 0 : queryParams.districtIds) === null || _a === void 0 ? void 0 : _a.length) {
            return 'Попробуйте перевыбрать район или сбросить все данные поиска';
        }
        return 'Проверьте, нет ли ошибок в данных, и попробуйте изменить запрос';
    };
    const handleClickResetSearchButton = () => {
        dispatch(agentsSlice.resetQueryParams());
    };
    useEffect(() => {
        if (!isChangedRegionId && !isEmptySearchParams) {
            sendMetrics({
                eventType: 'screen_view',
                elementId: AgentsMetricHashes.ScreenViewNotFoundNameOrPhone,
                eventSense: {
                    type: 'name or phone'
                }
            });
        }
        if (isChangedRegionId && isEmptySearchParams || isEmptySearchParams) {
            dispatch(agentsSlice.setIsRegionChangedEmptyList(true));
            sendMetrics({
                eventType: 'screen_view',
                elementId: AgentsMetricHashes.ScreenViewNotFoundCity,
                eventSense: {
                    type: 'city'
                }
            });
        }
        return () => {
            dispatch(agentsSlice.setIsRegionChangedEmptyList(false));
        };
    }, []);
    if (isChangedRegionId && isEmptySearchParams || isEmptySearchParams) {
        return _jsxs(Fragment, { children: [_jsx("div", { className: styles.container, children: _jsx(EmptyScreen, { title: "\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u043D\u0430\u0439\u0442\u0438 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432", text: "\u041F\u043E\u043F\u0440\u043E\u0431\u0443\u0439\u0442\u0435 \u0438\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", image: _jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box" }), buttons: isMobile && !isWebView ? [_jsx(AgentsSearchRegion, { buttonText: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", buttonIntent: Intent.Secondary, buttonSize: Size.Large }, "empty-screen-change-region")] : undefined }) }), isWebView ? _jsx("div", { className: styles.webViewButton, children: _jsx(AgentsSearchRegion, { buttonText: "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C \u0433\u043E\u0440\u043E\u0434", buttonIntent: Intent.Secondary, buttonSize: Size.Large }, "empty-screen-change-region") }) : null] });
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: styles.container, children: _jsx(EmptyScreen, { title: StringHelper.addNbsp(getTitle()), text: StringHelper.addNbsp(getText()), image: _jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box" }), buttons: isWebView ? undefined : [_jsx(Button, { text: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A", onClick: handleClickResetSearchButton, size: Size.Large, intent: Intent.Secondary }, "search-reset-button")], "data-sentry-element": "EmptyScreen", "data-sentry-source-file": "index.tsx" }) }), isWebView ? _jsx("div", { className: styles.webViewButton, children: _jsx(Button, { fluid: true, text: "\u0421\u0431\u0440\u043E\u0441\u0438\u0442\u044C \u043F\u043E\u0438\u0441\u043A", onClick: handleClickResetSearchButton, size: Size.Large, intent: Intent.Secondary }, "search-reset-button") }) : null] });
};
export default AgentsListEmpty;
