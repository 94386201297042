import { jsx as _jsx } from "react/jsx-runtime";
import { Pagination } from '@front-core/pagination';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import qs from 'qs';
import { Link, generatePath, useSearchParams } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { ObjectHelper } from '@shared/lib/helpers/object';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agenciesListPagination.scss';
const AgenciesListPagination = ({ regionSlug, pagination, onChange }) => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const urlQueryParams = useAppSelector(state => { var _a; return (_a = state.url) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const [searchParams] = useSearchParams();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const currentUrl = generatePath(PATH.AGENCIES, {
        regionSlug
    });
    const total = (pagination === null || pagination === void 0 ? void 0 : pagination.total) || 1;
    const limit = (pagination === null || pagination === void 0 ? void 0 : pagination.limit) || 10;
    const count = Math.ceil(total / limit);
    const currentPage = Number((urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams.page) ? Number(urlQueryParams === null || urlQueryParams === void 0 ? void 0 : urlQueryParams.page) : searchParams.get('page') || 1);
    const isHidePagination = pagination ? Boolean(limit >= total) : false;
    const isMobileDevice = breakpoint === BreakPoints.MOBILE;
    const getCloneParams = () => {
        const params = urlQueryParams ? structuredClone(urlQueryParams) : {};
        if (ObjectHelper.isEmpty(params)) {
            searchParams.forEach((value, key) => {
                params[key] = value;
            });
        }
        if ('page' in params) {
            delete params.page;
        }
        return params;
    };
    const getUrl = (url) => {
        const params = getCloneParams();
        if (Object.values(params).length) {
            return `${url}?${qs.stringify(params)}`;
        }
        return url;
    };
    const getSuffix = () => {
        const params = getCloneParams();
        if (Object.values(params).length) {
            return '&page=';
        }
        return '?page=';
    };
    if (isHidePagination) {
        return null;
    }
    return _jsx("div", { className: styles.pagination, "data-sentry-component": "AgenciesListPagination", "data-sentry-source-file": "index.tsx", children: _jsx(Pagination, { linkComponent: Link, linkTo: isWebView ? undefined : getUrl(currentUrl), linkToSuffix: isWebView ? undefined : getSuffix(), visibleCount: isMobileDevice ? 3 : 7, count: count, currentPage: currentPage, onChange: onChange, "data-sentry-element": "Pagination", "data-sentry-source-file": "index.tsx" }) });
};
export default AgenciesListPagination;
