import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Picture } from '@front-core/picture';
import { HeadingStandard, LabelLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './faqChoiceAgentItem.scss';
const FAQChoiceAgentItem = ({ title, description, image }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const imageSizes = {
        width: isMobile ? 297 : 375,
        height: isMobile ? 231 : 292
    };
    return _jsxs("div", { className: styles.faqChoiceAgentItem, "data-sentry-component": "FAQChoiceAgentItem", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingStandard, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(title) }), _jsx(LabelLargeRegular, { tag: "p", wrapper: false, className: styles.description, "data-sentry-element": "LabelLargeRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(description) }), _jsx(Picture, { alt: "", src: image.src, src2x: image.src2x, webpSrc: image.webpSrc, webpSrc2x: image.webpSrc2x, width: imageSizes.width, height: imageSizes.height, className: styles.picture, loading: "lazy", "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" })] });
};
export default FAQChoiceAgentItem;
