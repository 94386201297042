import { jsx as _jsx } from "react/jsx-runtime";
import { clsx } from 'clsx';
import { useMemo, useCallback } from 'react';
import { useLocation, generatePath, useMatch } from 'react-router-dom';
import { PATH } from '@shared/config/constants';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './breadcrumbs.scss';
const { API_ENV } = vars();
const Breadcrumbs = ({ className }) => {
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details; });
    const geoCity = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.city; });
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const urlPathPattern = useAppSelector(state => state.url.path.pattern);
    const isRobot = useAppSelector(state => state.ui.isRobot);
    const { pathname } = useLocation();
    const matchPath = useMatch(urlPathPattern);
    const seoAgentLocation = useMemo(() => {
        const geoLocation = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}`;
        const agentRegion = agent === null || agent === void 0 ? void 0 : agent.region;
        const robotLocation = (agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.nameLoc) || `в городе ${agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.name}`;
        return {
            name: isRobot && agentRegion ? robotLocation : geoLocation,
            slug: isRobot && (agentRegion === null || agentRegion === void 0 ? void 0 : agentRegion.slug) ? agentRegion.slug : regionSlug
        };
    }, [agent, geoCity, isRobot]);
    const locationName = useMemo(() => (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}` || '', [geoCity]);
    const currentPathPattern = useMemo(() => SSR || urlPathPattern === (matchPath === null || matchPath === void 0 ? void 0 : matchPath.pattern.path) ? urlPathPattern : matchPath === null || matchPath === void 0 ? void 0 : matchPath.pattern.path, [urlPathPattern, matchPath]);
    const fullName = (agent === null || agent === void 0 ? void 0 : agent.name) || '';
    const agencyName = (agency === null || agency === void 0 ? void 0 : agency.name) || (agency === null || agency === void 0 ? void 0 : agency.nameLegal);
    const getBreadcrumbs = useCallback(() => {
        const breadcrumbs = [{
                text: 'Главная',
                href: URL.MAIN[API_ENV]
            }];
        if (currentPathPattern === null || currentPathPattern === void 0 ? void 0 : currentPathPattern.length) {
            switch (currentPathPattern) {
                case PATH.AGENTS:
                    breadcrumbs.push({
                        text: `Поиск риелтора ${seoAgentLocation.name}`,
                        href: null
                    });
                    break;
                case PATH.AGENT:
                    breadcrumbs.push({
                        text: `Поиск риелтора ${seoAgentLocation.name}`,
                        href: generatePath(PATH.AGENTS, {
                            regionSlug: seoAgentLocation.slug
                        })
                    });
                    breadcrumbs.push({
                        text: `Риелтор ${fullName}`,
                        href: null
                    });
                    break;
                case PATH.AGENCIES:
                    breadcrumbs.push({
                        text: `Поиск агентства недвижимости ${locationName}`,
                        href: null
                    });
                    break;
                case PATH.AGENCY:
                    breadcrumbs.push({
                        text: `Поиск агентства недвижимости ${locationName}`,
                        href: generatePath(PATH.AGENCIES, {
                            regionSlug
                        })
                    });
                    breadcrumbs.push({
                        text: `Агентство недвижимости ${agencyName}`,
                        href: null
                    });
                    break;
                case PATH.FAQ_CHOICE_AGENT:
                    breadcrumbs.push({
                        text: `Поиск риелтора ${seoAgentLocation.name}`,
                        href: generatePath(PATH.AGENTS, {
                            regionSlug: seoAgentLocation.slug
                        })
                    });
                    breadcrumbs.push({
                        text: 'Как выбрать риелтора',
                        href: null
                    });
                    break;
                case PATH.FAQ_AGENT:
                    breadcrumbs.push({
                        text: `Поиск риелтора ${seoAgentLocation.name}`,
                        href: generatePath(PATH.AGENTS, {
                            regionSlug: seoAgentLocation.slug
                        })
                    });
                    breadcrumbs.push({
                        text: 'Для риелторов',
                        href: null
                    });
                    break;
                case PATH.FAQ_APP:
                    breadcrumbs.push({
                        text: `Поиск риелтора ${seoAgentLocation.name}`,
                        href: generatePath(PATH.AGENTS, {
                            regionSlug: seoAgentLocation.slug
                        })
                    });
                    breadcrumbs.push({
                        text: 'Как скачать приложение',
                        href: null
                    });
                    break;
                default:
                    break;
            }
        }
        else {
            breadcrumbs.push({
                text: `Поиск риелтора ${seoAgentLocation.name}`,
                href: null
            });
        }
        return breadcrumbs;
    }, [urlPathPattern, pathname, matchPath, agent, agency, geoCity]);
    const breadcrumbs = getBreadcrumbs();
    if (isWebView) {
        return null;
    }
    return _jsx("ol", { className: clsx(styles.breadcrumbs, className), "data-sentry-component": "Breadcrumbs", "data-sentry-source-file": "index.tsx", children: breadcrumbs.map(({ text, href }, key) => _jsx("li", { className: styles.item, children: href ? _jsx("a", { href: href, className: styles.link, children: text }) : _jsx("span", { children: text }) }, key)) });
};
export default Breadcrumbs;
