import { jsx as _jsx } from "react/jsx-runtime";
import { HeadingStandard } from '@front-core/typography';
import { setTopAppBarTitle } from '@rcf/webview-interfaces';
import { useEffect } from 'react';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentsTitle.scss';
const AgentsTitle = () => {
    const cityNameLocIn = useAppSelector(state => state.geo.city.nameLocIn);
    const cityName = useAppSelector(state => state.geo.city.name);
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const title = `Поиск риелторов ${StringHelper.addNbsp(cityNameLocIn || cityName)}`;
    useEffect(() => {
        if (isWebView) {
            setTopAppBarTitle('Поиск риелтора');
        }
    }, []);
    if (isWebView) {
        return null;
    }
    return _jsx("div", { className: styles.agentsTitle, "data-sentry-component": "AgentsTitle", "data-sentry-source-file": "index.tsx", children: _jsx(HeadingStandard, { tag: "h1", wrapper: false, className: styles.title, "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: title }) });
};
export default AgentsTitle;
