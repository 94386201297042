import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownSelect } from '@front-core/dropdown-select';
import { agentsSlice } from '@entities/agents';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { dealTypeOptions } from '../config/dealTypeOptions';
const AgentsDealType = () => {
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const handleChangeValue = (optionValue) => {
        const dealType = (typeof optionValue === 'string' && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.dealType) !== optionValue ? optionValue : null);
        if (dealType) {
            sendMetrics({
                eventType: 'click',
                elementId: AgentsMetricHashes.ClickButtonDealType,
                elementType: 'DropdownSelect',
                eventSense: {
                    regionId
                }
            });
            dispatch(agentsSlice.setQueryParams({
                dealType,
                offset: 0
            }));
        }
    };
    return _jsx(DropdownSelect, { fluid: true, disabled: isRegionChangedEmptyList, label: "\u0422\u0438\u043F \u0441\u0434\u0435\u043B\u043A\u0438", items: dealTypeOptions, value: queryParams === null || queryParams === void 0 ? void 0 : queryParams.dealType, defaultValue: queryParams === null || queryParams === void 0 ? void 0 : queryParams.dealType, onValueChange: handleChangeValue, "data-sentry-element": "DropdownSelect", "data-sentry-component": "AgentsDealType", "data-sentry-source-file": "index.tsx" });
};
export default AgentsDealType;
