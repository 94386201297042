import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import StarIcon from '@front-core/icon/dist/Star';
import { clsx } from 'clsx';
import { Fragment, useState, memo } from 'react';
import styles from './inputRating.scss';
const MAX_STARS = 5;
const stars = Array.from({
    length: MAX_STARS
}, (_value, index) => index + 1);
const InputRating = ({ name = 'rating', legend, defaultValue = 0, isError, onChange }) => {
    const [activeStar, setActiveStar] = useState(defaultValue);
    const [hoverStar, setHoverStar] = useState(0);
    const [isCurrentError, setIsCurrentError] = useState(isError);
    const getLabel = (star) => {
        switch (star) {
            case 1:
                return 'Ужасно';
            case 2:
                return 'Плохо';
            case 3:
                return 'Так себе';
            case 4:
                return 'Хорошо';
            default:
                return 'Отлично';
        }
    };
    const resetError = () => {
        if (isError) {
            setIsCurrentError(false);
        }
    };
    const handleChange = (event) => {
        const currentActiveValue = Number(event.currentTarget.value);
        setHoverStar(0);
        setActiveStar(currentActiveValue);
        resetError();
        if (onChange) {
            onChange(event);
        }
    };
    const handleMouseMove = (event) => {
        const target = event.target;
        if (target.tagName === 'INPUT') {
            const inputElement = target;
            const value = Number(inputElement.value);
            if (value !== hoverStar) {
                setHoverStar(value);
            }
        }
    };
    const handleMouseLeave = () => {
        setHoverStar(0);
        resetError();
    };
    return _jsxs("fieldset", { className: styles.fieldset, "data-sentry-component": "InputRating", "data-sentry-source-file": "index.tsx", children: [legend || null, _jsx("div", { onMouseMove: handleMouseMove, onMouseLeave: handleMouseLeave, className: styles.inputs, children: stars.map(star => _jsxs(Fragment, { children: [_jsx("input", { type: "radio", name: name, value: star, defaultChecked: star === defaultValue, className: clsx(styles.input, {
                                [styles.isActive]: activeStar >= star
                            }, {
                                [styles.isHover]: hoverStar >= star
                            }), "aria-label": getLabel(star), onChange: handleChange }), _jsx(StarIcon, { className: clsx(styles.starIcon, {
                                [styles.isError]: isCurrentError
                            }) })] }, star)) })] });
};
export default memo(InputRating);
