import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertList, AlertStatus } from '@front-core/alert';
import { Button, Size } from '@front-core/button';
import { Fragment, useRef, useState } from 'react';
import { PATH } from '@shared/config/constants';
import { YandexMetricaSections } from '@shared/config/yandexMetrica/sections';
import { YandexMetricaTargets } from '@shared/config/yandexMetrica/targets';
import { YandexMetricaHelper } from '@shared/lib/helpers/yandexMetrica';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { useCreateChat } from '../../lib/useCreateChat';
const AgentChatCreateButton = ({ agentCasId, text, buttonIntent, buttonSize }) => {
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const userCasIdSigned = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casIdSigned; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const pathPattern = useAppSelector(state => { var _a; return (_a = state.url.path) === null || _a === void 0 ? void 0 : _a.pattern; });
    const queryParams = useAppSelector(state => state.agents.queryParams);
    const [alertList, setAlertList] = useState([]);
    const buttonRef = useRef(null);
    const { isLoading, isFetching, triggerChatOptions } = useCreateChat({
        agentCasId
    });
    const isAuthUser = userCasId || userCasIdSigned;
    const isPending = isLoading || isFetching;
    const updateAlertList = error => {
        setAlertList([{
                id: '1',
                showCloseIcon: true,
                title: error === null || error === void 0 ? void 0 : error.message,
                duration: 4,
                status: AlertStatus.ERROR
            }]);
    };
    const showLoginForm = () => {
        if (!isAuthUser && !isWebView) {
            window === null || window === void 0 ? void 0 : window.domclickGlobal.domclickLayout.showLoginForm({
                loginFormProps: {
                    onEntry: () => setTimeout(() => { var _a; return (_a = buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current) === null || _a === void 0 ? void 0 : _a.click(); }, 500)
                }
            });
        }
    };
    const handleClick = () => {
        const metricsParams = {
            eventType: 'click',
            eventSense: {
                agentCasId,
                regionId,
                queryParams
            }
        };
        if (userCasId) {
            YandexMetricaHelper.reachGoal({
                targetName: YandexMetricaTargets.AgentsChat,
                params: {
                    casId: userCasId,
                    section: pathPattern === PATH.AGENT ? YandexMetricaSections.Agent : YandexMetricaSections.Agents
                }
            });
        }
        if (!SSR && triggerChatOptions) {
            triggerChatOptions({
                metricsParams
            }).then(() => {
                showLoginForm();
            }).catch(error => {
                updateAlertList(error);
            });
        }
    };
    return _jsxs(Fragment, { children: [_jsx(Button, { text: text, size: buttonSize || Size.Large, fluid: true, buttonRef: buttonRef, intent: buttonIntent, onClick: handleClick, loading: isPending, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentChatCreateButton;
