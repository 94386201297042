import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { DropdownButton } from '@front-core/dropdown-button';
import MoreIcon from '@front-core/icon/dist/More';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useState, Fragment } from 'react';
import { agentsApi } from '@entities/agents';
import { AnswerToReviewDropdownItems, AnswerToReviewDropdownItemValues, ReviewEventTypes, reviewsApi } from '@entities/reviews';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import AgentReviewAnswerUpdateBottomSheet from '../bottomSheet';
import AgentReviewAnswerUpdateForm from '../form';
import AgentReviewAnswerUpdateModal from '../modal';
import styles from './reviewsAnswerDropDown.scss';
const AgentReviewAnswerUpdateDropDown = ({ review }) => {
    const agentCasId = useAppSelector(state => { var _a, _b, _c; return (_c = (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent) === null || _b === void 0 ? void 0 : _b.details) === null || _c === void 0 ? void 0 : _c.casId; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const [mutateAnswer] = reviewsApi.useDeleteReviewsAnswerMutation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const breakpoints = useBreakpoint(media === 'mobile' ? BreakPoints.MOBILE : BreakPoints.TABLET_LANDSCAPE);
    const isTabletLandscapeAndMore = breakpoints !== BreakPoints.MOBILE && breakpoints !== BreakPoints.TABLET_PORTRAIT;
    const deleteAnswer = async () => {
        try {
            const response = await mutateAnswer(review.id);
            if ('data' in response) {
                await dispatch(agentsApi.endpoints.getAgentReviews.initiate({
                    agentCasId,
                    offset: 0
                }, {
                    forceRefetch: true
                }));
            }
        }
        catch (error) {
            console.info(error);
        }
    };
    const handleChangeItemValue = (value) => {
        const item = value;
        if (item === AnswerToReviewDropdownItemValues.Edit) {
            setIsModalVisible(prevState => !prevState);
            sendMetrics({
                eventType: 'click',
                elementId: AgentMetricHashes.ClickButtonAgentReviewAnswerEdit,
                elementType: 'Button',
                eventSense: {
                    reviewId: review.id,
                    agentCasId,
                    regionId
                }
            });
        }
        else {
            sendMetrics({
                eventType: 'click',
                elementId: AgentMetricHashes.ClickButtonAgentReviewAnswerDelete,
                elementType: 'Button',
                eventSense: {
                    reviewId: review.id,
                    agentCasId,
                    regionId
                }
            });
            deleteAnswer().then(() => {
                sendMetrics({
                    eventType: ReviewEventTypes.AnswerDeleted,
                    elementId: AgentMetricHashes.SuccessResponseReview,
                    eventSense: {
                        agentCasId,
                        regionId,
                        reviewId: review.id
                    }
                });
            });
        }
    };
    const handleToggleModal = () => {
        setIsModalVisible(prevState => !prevState);
    };
    return _jsxs(Fragment, { children: [_jsx(DropdownButton, { wrapperClassName: styles.reviewsAnswerDropDown, value: AnswerToReviewDropdownItemValues.Default, icon: _jsx(MoreIcon, {}), items: AnswerToReviewDropdownItems, onValueChange: handleChangeItemValue, "data-sentry-element": "DropdownButton", "data-sentry-source-file": "index.tsx" }), isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewAnswerUpdateModal, { isOpen: isModalVisible, onClose: handleToggleModal, children: _jsx(AgentReviewAnswerUpdateForm, { review: review, onCancel: handleToggleModal, onSubmit: handleToggleModal }) }) : null, !isTabletLandscapeAndMore && isModalVisible ? _jsx(AgentReviewAnswerUpdateBottomSheet, { isOpen: isModalVisible, onClose: handleToggleModal, children: _jsx(AgentReviewAnswerUpdateForm, { review: review, onCancel: handleToggleModal, onSubmit: handleToggleModal }) }) : null] });
};
export default AgentReviewAnswerUpdateDropDown;
