import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelStandardRegular } from '@front-core/typography';
import { AgentMetricHashes } from '@shared/config/metrics';
import { sendMetrics } from '@shared/lib/sendMetrics';
import Faq from '@shared/ui/faq';
import styles from './agentFaq.scss';
const AgentFaq = ({ cityNameLocIn, regionId, agentCasId }) => {
    const items = [{
            id: 3001,
            title: `Какие услуги предоставляют риелторы ${cityNameLocIn}?`,
            content: _jsxs("div", { className: styles.text, children: [_jsx(LabelStandardRegular, { tag: "p", children: "\u0420\u0438\u0435\u043B\u0442\u043E\u0440 \u043E\u043A\u0430\u0437\u044B\u0432\u0430\u0435\u0442 \u0443\u0441\u043B\u0443\u0433\u0438 \u043F\u043E \u043F\u0440\u043E\u0434\u0430\u0436\u0435 \u0438 \u0430\u0440\u0435\u043D\u0434\u0435:" }), _jsxs("ul", { className: styles.list, children: [_jsx("li", { children: "\u043F\u043E\u0438\u0441\u043A \u0438 \u043F\u043E\u0434\u0431\u043E\u0440 \u043E\u0431\u044A\u0435\u043A\u0442\u043E\u0432 \u0436\u0438\u043B\u043E\u0439 \u0438 \u043A\u043E\u043C\u043C\u0435\u0440\u0447\u0435\u0441\u043A\u043E\u0439 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438" }), _jsx("li", { children: "\u043E\u0446\u0435\u043D\u043A\u0430 \u0441\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u0438" }), _jsx("li", { children: "\u044E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u0441\u043E\u043F\u0440\u043E\u0432\u043E\u0436\u0434\u0435\u043D\u0438\u0435" }), _jsx("li", { children: "\u043F\u043E\u043A\u0430\u0437 \u043E\u0431\u044A\u0435\u043A\u0442\u043E\u0432" }), _jsx("li", { children: "\u0441\u043E\u043F\u0440\u043E\u0432\u043E\u0436\u0434\u0435\u043D\u0438\u0435 \u0441\u0434\u0435\u043B\u043E\u043A" })] })] })
        }, {
            id: 3002,
            title: `Где найти и как выбрать риелтора ${cityNameLocIn}?`,
            content: _jsx(LabelStandardRegular, { tag: "p", children: `Если вам нужно найти агента по недвижимости ${cityNameLocIn} для продажи или покупки квартиры, 
             воспользуйтесь поиском на Домклик. Ориентируйтесь на опыт работы агента, количество совершённых сделок и отзывы.` })
        }, {
            id: 3003,
            title: 'Как взаимодействуют клиент и риелтор?',
            content: _jsxs("div", { className: styles.text, children: [_jsx(LabelStandardRegular, { tag: "p", children: "\u041E\u0431\u0449\u0438\u0439 \u043F\u043E\u0440\u044F\u0434\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u0439 \u0441\u043B\u0435\u0434\u0443\u044E\u0449\u0438\u0439:" }), _jsxs("ul", { className: styles.list, children: [_jsx("li", { children: "\u043F\u0435\u0440\u0432\u0438\u0447\u043D\u0430\u044F \u043A\u043E\u043D\u0441\u0443\u043B\u044C\u0442\u0430\u0446\u0438\u044F" }), _jsx("li", { children: "\u0437\u0430\u043A\u043B\u044E\u0447\u0435\u043D\u0438\u0435 \u0434\u043E\u0433\u043E\u0432\u043E\u0440\u0430" }), _jsx("li", { children: "\u043F\u043E\u0434\u0431\u043E\u0440 \u0438 \u043F\u043E\u043A\u0430\u0437 \u043E\u0431\u044A\u0435\u043A\u0442\u0430" }), _jsx("li", { children: "\u0441\u043E\u0433\u043B\u0430\u0441\u043E\u0432\u0430\u043D\u0438\u0435 \u0443\u0441\u043B\u043E\u0432\u0438\u0439" }), _jsx("li", { children: "\u0441\u043E\u043F\u0440\u043E\u0432\u043E\u0436\u0434\u0435\u043D\u0438\u0435 \u0441\u0434\u0435\u043B\u043A\u0438" })] })] })
        }, {
            id: 3004,
            title: `Сколько стоят услуги риелтора ${cityNameLocIn}?`,
            content: _jsx(LabelStandardRegular, { tag: "p", children: "\u0412\u043E\u0437\u043D\u0430\u0433\u0440\u0430\u0436\u0434\u0435\u043D\u0438\u0435 \u0440\u0430\u0441\u0441\u0447\u0438\u0442\u044B\u0432\u0430\u0435\u0442\u0441\u044F \u0438\u043D\u0434\u0438\u0432\u0438\u0434\u0443\u0430\u043B\u044C\u043D\u043E \u0438 \u0437\u0430\u0432\u0438\u0441\u0438\u0442 \u0432 \u0442\u043E\u043C \u0447\u0438\u0441\u043B\u0435 \u043E\u0442 \u0441\u043F\u043E\u0441\u043E\u0431\u0430 \u043E\u043F\u043B\u0430\u0442\u044B: \u043A\u043E\u043C\u0438\u0441\u0441\u0438\u044F \u043E\u0442 \u0441\u0434\u0435\u043B\u043A\u0438 \u0438\u043B\u0438 \u0444\u0438\u043A\u0441\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u0430\u044F \u0446\u0435\u043D\u0430. \u0422\u0430\u043A\u0436\u0435 \u0432\u0430\u0436\u0435\u043D \u043E\u0431\u044A\u0435\u043C \u0440\u0430\u0431\u043E\u0442 \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430: \u043D\u0430\u043F\u0440\u0438\u043C\u0435\u0440, \u044E\u0440\u0438\u0434\u0438\u0447\u0435\u0441\u043A\u043E\u0435 \u0441\u043E\u043F\u0440\u043E\u0432\u043E\u0436\u0434\u0435\u043D\u0438\u0435 \u0441\u0434\u0435\u043B\u043A\u0438 \u0438 \u0434\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u0443\u0441\u043B\u0443\u0433\u0438, \u0441\u0440\u0435\u0434\u0438 \u043A\u043E\u0442\u043E\u0440\u044B\u0445 \u2014 \u043F\u043E\u043C\u043E\u0449\u044C \u0432 \u043F\u043E\u043B\u0443\u0447\u0435\u043D\u0438\u0438 \u0438\u043F\u043E\u0442\u0435\u043A\u0438." })
        }];
    const sendAnalytics = (questionId) => {
        sendMetrics({
            eventType: 'click',
            elementId: AgentMetricHashes.ClickOpenFaqAccordion,
            elementType: 'Accordion',
            eventSense: {
                questionId,
                agentCasId,
                regionId
            }
        });
    };
    return _jsx(Faq, { items: items, sendAnalytics: sendAnalytics, "data-sentry-element": "Faq", "data-sentry-component": "AgentFaq", "data-sentry-source-file": "index.tsx" });
};
export default AgentFaq;
