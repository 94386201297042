import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent } from '@front-core/button';
import SearchIcon from '@front-core/icon/dist/Search';
import { Illustration, Illustrations, IllustrationSize, IllustrationFormat } from '@front-core/illustration';
import { Size } from '@front-core/input';
import { Picture } from '@front-core/picture';
import { HeadingSmall } from '@front-core/typography';
import { useState, useCallback } from 'react';
import { StringHelper } from '@shared/lib/helpers/string';
import { AgentsSearchDistrictImages } from '../../../config/images';
import AgentsSearchDistrictModal from '../modal';
import styles from './agentsSearchDistrictPrimary.scss';
const AgentsSearchDistrictPrimary = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleToggleModal = useCallback(() => {
        setIsModalOpen(prevState => !prevState);
    }, []);
    return _jsx(Paper, { tag: "section", className: styles.agentsSearchDistrictPrimary, "data-sentry-element": "Paper", "data-sentry-component": "AgentsSearchDistrictPrimary", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.content, children: [_jsx(HeadingSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingSmall", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Найдите эксперта в нужном районе') }), _jsx(Illustration, { className: styles.icon, name: Illustrations.MapMark, size: IllustrationSize.Medium, format: IllustrationFormat.WEBP, alt: "Map-mark", "data-sentry-element": "Illustration", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.modal, children: [_jsx("div", { className: styles.button, children: _jsx(Button, { fluid: true, text: "\u0412\u044B\u0431\u0435\u0440\u0438\u0442\u0435 \u0440\u0430\u0439\u043E\u043D", size: Size.Large, intent: Intent.Secondary, icon: _jsx(SearchIcon, {}), onClick: handleToggleModal, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }) }), isModalOpen ? _jsx(AgentsSearchDistrictModal, { onToggleModal: handleToggleModal, isModalOpen: isModalOpen }) : null] }), _jsx("div", { className: styles.images, children: AgentsSearchDistrictImages.map((image, index) => _jsx(Picture, { className: styles.image, width: 95, height: 95, src: image.src, src2x: image.src2x, alt: "", loading: "lazy" }, index)) })] }) });
};
export default AgentsSearchDistrictPrimary;
