import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import LayoutGridWithStickyAside from '@shared/ui/layout/gridWithStickyAside';
import AgentSkeletonDesktopAbout from './about';
import AgentSkeletonDesktopAside from './aside';
import AgentSkeletonDesktopInfo from './info';
import AgentSkeletonDesktopReviews from './reviews';
import AgentSkeletonDesktopSpecializations from './specializations';
const AgentSkeletonDesktop = () => _jsx(LayoutGridWithStickyAside, { mainContent: _jsxs(Fragment, { children: [_jsx(AgentSkeletonDesktopInfo, {}), _jsx(AgentSkeletonDesktopAbout, {}), _jsx(AgentSkeletonDesktopSpecializations, {}), _jsx(AgentSkeletonDesktopReviews, {})] }), asideContent: _jsx(AgentSkeletonDesktopAside, {}), "data-sentry-element": "LayoutGridWithStickyAside", "data-sentry-component": "AgentSkeletonDesktop", "data-sentry-source-file": "index.tsx" });
export default AgentSkeletonDesktop;
