import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Button, Intent, Size } from '@front-core/button';
import { Picture } from '@front-core/picture';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './banner.scss';
const Banner = ({ banner }) => {
    const handleClick = () => {
        sendMetrics({
            eventType: 'click',
            elementId: banner.elementId,
            elementType: 'Button'
        });
    };
    return _jsx(Paper, { "data-sentry-element": "Paper", "data-sentry-component": "Banner", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.container, children: [_jsxs("div", { className: styles.content, children: [_jsx("strong", { className: styles.text, children: banner.text }), _jsx(Button, { wrapperClassName: styles.button, intent: Intent.Secondary, size: Size.Small, target: "_blank", href: banner.link, text: banner.linkText, onClick: handleClick, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" })] }), _jsx(Picture, { src: banner.src, src2x: banner.src2x, webpSrc: banner.webpSrc, webpSrc2x: banner.webpSrc2x, alt: "", width: 60, height: 60, "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" })] }) });
};
export default Banner;
