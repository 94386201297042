import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { OfficeCallButton } from '@features/office/call';
import { AgenciesOfficeCard } from '@entities/agencies';
import AgencyOfficeListPagination from '../pagination';
const AgencyOfficeListResult = ({ offices, isShow, pagination, onChangePage }) => {
    if (!isShow) {
        return null;
    }
    return _jsxs(Fragment, { children: [offices.map(office => _jsx(AgenciesOfficeCard, { office: office, featureElement: _jsx(OfficeCallButton, { officeId: office.id }) }, office.id)), _jsx(AgencyOfficeListPagination, { pagination: pagination, onChange: onChangePage, "data-sentry-element": "AgencyOfficeListPagination", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyOfficeListResult;
