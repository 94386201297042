import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Intent, Size } from '@front-core/button';
import SearchIcon from '@front-core/icon/dist/Search';
import { useState, Fragment } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import AgenciesSearchModal from './modal';
const AgenciesSearchMobile = () => {
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const handleTriggerModal = () => {
        setIsModalOpen(prevState => {
            if (isWebView && userAgent) {
                setWebViewVisibilityTopBar(userAgent, !prevState);
            }
            return !prevState;
        });
    };
    return _jsxs(Fragment, { children: [_jsx(Button, { icon: _jsx(SearchIcon, {}), size: Size.Large, intent: Intent.Secondary, onClick: handleTriggerModal, disabled: isRegionChangedEmptyList, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isModalOpen ? _jsx(AgenciesSearchModal, { isModalOpen: isModalOpen, onClose: handleTriggerModal }) : null] });
};
export default AgenciesSearchMobile;
