import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { agentsApi } from '@entities/agents';
import { UrlHelper } from '@shared/lib/helpers/url';
export const useAgentSubstitutePhone = ({ agentCasId }) => {
    const [triggerAgentSubstitutePhone, { data, isFetching, isLoading, isSuccess }] = agentsApi.useLazyGetAgentsSubstitutePhoneQuery();
    const [errorMessage, setErrorMessage] = useState(null);
    const isPending = isLoading || isFetching;
    const phoneNumber = data === null || data === void 0 ? void 0 : data.result.phone;
    const substitutePhoneResponse = async () => {
        var _a;
        setErrorMessage(null);
        if (isSuccess) {
            return;
        }
        try {
            const params = UrlHelper.getUTMParams();
            const response = await triggerAgentSubstitutePhone({
                ...params,
                agentCasId
            });
            if (response.error && 'status' in response.error) {
                setErrorMessage(((_a = response.error.data.errors[0]) === null || _a === void 0 ? void 0 : _a.message) || 'Не удалось загрузить данные');
            }
        }
        catch (error) {
            Sentry.captureException(`useAgencySubstitutePhone: ${JSON.stringify(error)}`);
            setErrorMessage('Не удалось загрузить данные');
        }
    };
    const substitutePhone = async () => {
        try {
            await substitutePhoneResponse();
        }
        catch (error) {
            Sentry.captureException(`useAgentSubstitutePhone: ${JSON.stringify(error)}`);
            throw new Error(` ${JSON.stringify(error)}`);
        }
    };
    return {
        phoneNumber,
        errorMessage,
        isPending,
        isSuccess,
        substitutePhone
    };
};
