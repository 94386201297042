import { jsx as _jsx } from "react/jsx-runtime";
import { DropdownSelect, Size } from '@front-core/dropdown-select';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { agenciesSlice } from '@entities/agencies';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import { SortElementId, sortOptions } from '../config/sortOptions';
const AgenciesSort = () => {
    const agencies = useAppSelector(state => state.agencies.list);
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const dispatch = useAppDispatch();
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = !isWebView && breakpoint !== BreakPoints.MOBILE;
    const handleChangeValue = (optionValue) => {
        const order = (typeof optionValue === 'string' && (queryParams === null || queryParams === void 0 ? void 0 : queryParams.order) !== optionValue ? optionValue : null);
        if (order) {
            dispatch(agenciesSlice.setQueryParams({
                order,
                offset: 0
            }));
            sendMetrics({
                eventType: 'click',
                elementId: SortElementId[order],
                elementType: 'DropdownSelect',
                eventSense: {
                    type: order
                }
            });
        }
    };
    const handleToggleDropDown = (isOpen) => {
        if (isOpen) {
            sendMetrics({
                eventType: 'click',
                elementId: AgenciesMetricHashes.ClickSort,
                elementType: 'DropdownSelect'
            });
        }
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, isOpen);
        }
    };
    return _jsx(DropdownSelect, { fluid: true, disabled: isRegionChangedEmptyList || agencies.length === 0, value: queryParams === null || queryParams === void 0 ? void 0 : queryParams.order, items: sortOptions, defaultValue: queryParams === null || queryParams === void 0 ? void 0 : queryParams.order, size: isDesktop ? Size.Medium : Size.Large, onToggle: handleToggleDropDown, onValueChange: handleChangeValue, "data-sentry-element": "DropdownSelect", "data-sentry-component": "AgenciesSort", "data-sentry-source-file": "index.tsx" });
};
export default AgenciesSort;
