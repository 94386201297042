import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { AgencyCreateSubstitutePhoneModal } from '@features/agency/createSubstitutePhone';
import { AgenciesCard } from '@entities/agencies';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgenciesListPagination from '../pagination';
import styles from './agenciesListResult.scss';
const AgenciesListResult = ({ agencies, pagination, isShow, onChangePage }) => {
    const regionSlug = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.slug; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    if (!isShow) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: styles.container, children: agencies.map(agency => _jsx(AgenciesCard, { agency: agency, featureElement: _jsx(AgencyCreateSubstitutePhoneModal, { agencyId: agency.id }) }, agency.id)) }), pagination ? _jsx(AgenciesListPagination, { regionSlug: regionSlug || regionId || '', pagination: pagination, onChange: onChangePage }) : null] });
};
export default AgenciesListResult;
