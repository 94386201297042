const clearInput = (ref) => {
    if (typeof window !== 'undefined') {
        const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
        const nativeInputValueSetter = nativeInputValue === null || nativeInputValue === void 0 ? void 0 : nativeInputValue.set;
        const inputEvent = new Event('input', { bubbles: true });
        if (ref.current && nativeInputValueSetter) {
            nativeInputValueSetter.call(ref.current, '');
            ref.current.dispatchEvent(inputEvent);
        }
    }
};
const setInputValue = (ref, value) => {
    if (typeof window !== 'undefined') {
        const nativeInputValue = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value');
        const nativeInputValueSetter = nativeInputValue === null || nativeInputValue === void 0 ? void 0 : nativeInputValue.set;
        const inputEvent = new Event('input', { bubbles: true });
        if (ref.current && nativeInputValueSetter) {
            nativeInputValueSetter.call(ref.current, value);
            ref.current.dispatchEvent(inputEvent);
        }
    }
};
export const EventHelper = {
    clearInput,
    setInputValue
};
