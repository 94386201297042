import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { AgentReviewsPeriod } from '@entities/agents';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentReviews.scss';
import AgentReviewsEmptyList from './components/emptyList';
import AgentReviewsList from './components/list';
import AgentReviewsMobile from './components/mobile';
import AgentReviewsTitle from './components/title';
const AgentReviews = () => {
    const reviews = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.list; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const rating = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.rating; });
    const agentReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.queryParams; });
    const isPendingReview = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.reviews.isPending; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const hasReviewsEmptyList = !isPendingReview && !reviews.length && agentReviewQueryParams.period === AgentReviewsPeriod.All;
    return _jsx(Paper, { tag: "section", className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgentReviews", "data-sentry-source-file": "index.tsx", children: hasReviewsEmptyList ? _jsx(AgentReviewsEmptyList, {}) : _jsxs(Fragment, { children: [_jsx(AgentReviewsTitle, { activeCount: rating === null || rating === void 0 ? void 0 : rating.votes, rating: rating === null || rating === void 0 ? void 0 : rating.avg }), isMobile ? _jsx(AgentReviewsMobile, {}) : _jsx(AgentReviewsList, {})] }) });
};
export default AgentReviews;
