import item1JPG from '@shared/images/faq/item-1.png';
import item1WebP from '@shared/images/faq/item-1.webp';
import item1JPG2x from '@shared/images/faq/item-1@2x.png';
import item1WebP2x from '@shared/images/faq/item-1@2x.webp';
import item2JPG from '@shared/images/faq/item-2.jpg';
import item2WebP from '@shared/images/faq/item-2.webp';
import item2JPG2x from '@shared/images/faq/item-2@2x.jpg';
import item2WebP2x from '@shared/images/faq/item-2@2x.webp';
import item3JPG from '@shared/images/faq/item-3.jpg';
import item3WebP from '@shared/images/faq/item-3.webp';
import item3JPG2x from '@shared/images/faq/item-3@2x.jpg';
import item3WebP2x from '@shared/images/faq/item-3@2x.webp';
import item4JPG from '@shared/images/faq/item-4.jpg';
import item4WebP from '@shared/images/faq/item-4.webp';
import item4JPG2x from '@shared/images/faq/item-4@2x.jpg';
import item4WebP2x from '@shared/images/faq/item-4@2x.webp';
export const choiceAgentItems = [
    {
        title: 'Свяжитесь с тем, кто подходит',
        description: 'При поиске обращайте внимание на оценку риелтора, количество сделок, объявлений и то, как заполнен профиль',
        image: {
            src: item1JPG,
            src2x: item1JPG2x,
            webpSrc: item1WebP,
            webpSrc2x: item1WebP2x
        }
    },
    {
        title: 'Опирайтесь на оценку и отзывы',
        description: 'Изучите отзывы клиентов СберБанка, которые уже воспользовались услугами риелтора',
        image: {
            src: item2JPG,
            src2x: item2JPG2x,
            webpSrc: item2WebP,
            webpSrc2x: item2WebP2x
        }
    },
    {
        title: 'Посмотрите объявления риелтора',
        description: 'Можно проверить, какие объекты продаёт риелтор, и подобрать вариант для покупки',
        image: {
            src: item3JPG,
            src2x: item3JPG2x,
            webpSrc: item3WebP,
            webpSrc2x: item3WebP2x
        }
    },
    {
        title: 'Проверьте историю сделок',
        description: 'Чем больше сделок провёл риелтор за год, тем больше у него опыта и знаний о рынке недвижимости',
        image: {
            src: item4JPG,
            src2x: item4JPG2x,
            webpSrc: item4WebP,
            webpSrc2x: item4WebP2x
        }
    }
];
