import { usePrevious } from '@front-core/utils';
import { useState, useMemo, useCallback } from 'react';
import { geoSlice } from '@entities/geo';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
export const useDistrictSelection = (initialIds, districtList) => {
    const flatDistrictList = useAppSelector(geoSlice.selectFlatDistricts);
    const [districtIds, setDistrictIds] = useState(initialIds);
    const [searchQuery, setSearchQuery] = useState('');
    const prevDistrictIds = usePrevious(districtIds);
    const districtIdsSet = useMemo(() => new Set(districtIds), [districtIds]);
    const filteredDistricts = useMemo(() => {
        if (!searchQuery || searchQuery.length < 2) {
            return districtList;
        }
        const searchLower = searchQuery.toLowerCase();
        return flatDistrictList.filter(district => district.name.toLowerCase().includes(searchLower));
    }, [searchQuery, districtList, flatDistrictList]);
    const updateParentDistrict = useCallback((parentId, checked) => {
        var _a;
        const parent = districtList.find(district => district.id === parentId);
        const childIds = ((_a = parent === null || parent === void 0 ? void 0 : parent.children) === null || _a === void 0 ? void 0 : _a.map(children => children.id)) || [];
        setDistrictIds(prevState => {
            const newSet = new Set(prevState);
            if (checked) {
                newSet.add(parentId);
                childIds.forEach(id => newSet.add(id));
            }
            else {
                newSet.delete(parentId);
                childIds.forEach(id => newSet.delete(id));
            }
            return Array.from(newSet);
        });
    }, [districtList]);
    const updateChildDistrict = useCallback((childId, checked) => {
        const parent = districtList.find(district => { var _a; return (_a = district.children) === null || _a === void 0 ? void 0 : _a.some(children => children.id === childId); });
        setDistrictIds(prevState => {
            var _a;
            const newSet = new Set(prevState);
            if (checked) {
                newSet.add(childId);
                const allChildrenChecked = (_a = parent === null || parent === void 0 ? void 0 : parent.children) === null || _a === void 0 ? void 0 : _a.every(children => newSet.has(children.id));
                if (allChildrenChecked && parent) {
                    newSet.add(parent.id);
                }
            }
            else {
                newSet.delete(childId);
                if (parent) {
                    newSet.delete(parent.id);
                }
            }
            return Array.from(newSet);
        });
    }, [districtList]);
    const resetSelection = useCallback(() => {
        setDistrictIds([]);
        setSearchQuery('');
    }, []);
    return {
        districtIds,
        prevDistrictIds,
        districtIdsSet,
        searchQuery,
        filteredDistricts,
        setSearchQuery,
        updateParentDistrict,
        updateChildDistrict,
        resetSelection
    };
};
