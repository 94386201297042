import { jsx as _jsx } from "react/jsx-runtime";
import { Button } from '@front-core/button';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useMemo, memo } from 'react';
import { NumberHelper } from '@shared/lib/helpers/number';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAnimatedNumber } from '@shared/lib/hooks/useAnimatedNumber';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
const AgentsSearchDistrictModalFooterSubmitButton = ({ agentsCount, onSubmit, isLoading }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const animatedNumber = useAnimatedNumber(agentsCount);
    const submitButtonText = useMemo(() => isMobile ? 'Сохранить' : `Показать ${NumberHelper.getNumberLocaleFormat(animatedNumber)} ${StringHelper.getPluralForm(animatedNumber, ['предложение', 'предложения', 'предложений'])}`, [isMobile, animatedNumber]);
    return _jsx(Button, { type: "submit", text: submitButtonText, onClick: onSubmit, loading: isLoading, "data-sentry-element": "Button", "data-sentry-component": "AgentsSearchDistrictModalFooterSubmitButton", "data-sentry-source-file": "submitButton.tsx" });
};
export default memo(AgentsSearchDistrictModalFooterSubmitButton);
