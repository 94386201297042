import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Intent, Size } from '@front-core/button';
import { LabelStandardMedium } from '@front-core/typography';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { AgentChatCreateButton } from '@features/agent/chat';
import { AgentCreateSubstitutePhoneModal } from '@features/agent/createSubstitutePhone';
import { AgentsSearchDistrict } from '@features/agents/search/district';
import { AgentsCard, agentsSlice } from '@entities/agents';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgentsListPagination from '../pagination';
import styles from './agentsListResult.scss';
const MSK_GEO = '1d1463ae-c80f-4d19-9331-a1b68a85b553';
const AgentsListResult = ({ agents, isFirstInRegion, pagination, isShow, onChangePage }) => {
    const regionGuid = useAppSelector(state => state.geo.region.guid);
    const geoSlug = useAppSelector(state => state.geo.slug);
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const isSearchDistrict = useAppSelector(agentsSlice.selectIsSearchDistrict);
    const isShowDistricts = regionGuid === MSK_GEO && !isSearchDistrict;
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const firstInRegion = isFirstInRegion ? agents.find(agent => !agent.inSelectedRegion) : null;
    const lastInCurrentRegionIndex = isFirstInRegion ? agents.findLast(agent => agent.inSelectedRegion) : null;
    const firstInRegionCasId = firstInRegion === null || firstInRegion === void 0 ? void 0 : firstInRegion.casId;
    const lastInCurrentRegionCasId = lastInCurrentRegionIndex === null || lastInCurrentRegionIndex === void 0 ? void 0 : lastInCurrentRegionIndex.casId;
    const isBottomRoundings = (pagination === null || pagination === void 0 ? void 0 : pagination.total) && pagination.total < 10;
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const topAgents = isShowDistricts ? agents.slice(0, 1) : agents;
    const bottomAgents = isShowDistricts ? agents.slice(1) : null;
    const renderAgents = (currentAgents) => currentAgents.map(currentAgent => _jsxs(Fragment, { children: [currentAgent.casId === firstInRegionCasId ? _jsx(LabelStandardMedium, { tag: "h2", className: styles.headerDescription, wrapper: false, children: "\u0420\u0438\u0435\u043B\u0442\u043E\u0440\u044B \u0432 \u0434\u0440\u0443\u0433\u0438\u0445 \u0440\u0435\u0433\u0438\u043E\u043D\u0430\u0445" }) : null, _jsx(AgentsCard, { isSearchDistrict: isSearchDistrict, lastInCurrentRegion: currentAgent.casId === lastInCurrentRegionCasId, agent: currentAgent, primaryFeatureElement: _jsx(AgentCreateSubstitutePhoneModal, { agentCasId: currentAgent.casId }), secondaryFeatureElement: _jsx(AgentChatCreateButton, { text: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u0447\u0430\u0442", agentCasId: currentAgent.casId, buttonIntent: Intent.Secondary, buttonSize: isMobile ? Size.Medium : Size.Small }) })] }, currentAgent.casId));
    if (!isShow) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsx("div", { className: styles.container, children: _jsx("div", { className: clsx(styles.agents, {
                        [styles.isBottomRoundings]: isBottomRoundings
                    }), children: isShowDistricts ? _jsxs(Fragment, { children: [renderAgents(topAgents), _jsx(AgentsSearchDistrict, {}), (bottomAgents === null || bottomAgents === void 0 ? void 0 : bottomAgents.length) ? renderAgents(bottomAgents) : null] }) : renderAgents(agents) }) }), pagination ? _jsx(AgentsListPagination, { regionSlug: geoSlug || regionGuid || '', pagination: pagination, onChange: onChangePage }) : null] });
};
export default AgentsListResult;
