import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingLarge } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { FAQAgentHashes } from '@shared/config/metrics';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import LayoutFAQBlock from '@shared/ui/layout/faqBlock';
import { howBuildRatingItems } from '../config/items';
import styles from './faqHowBuildRating.scss';
const FAQHowBuildRating = () => {
    const media = useAppSelector(state => state.ui.media);
    const regionId = useAppSelector(state => state.geo.region.guid);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const handleClickButton = () => {
        sendMetrics({
            elementId: FAQAgentHashes.ClickEditProfileButton,
            eventType: 'click',
            elementType: 'Button',
            eventSense: {
                regionId
            }
        });
    };
    return _jsxs(Paper, { tag: "section", className: styles.faqHowBuildRating, "data-sentry-element": "Paper", "data-sentry-component": "FAQHowBuildRating", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Что влияет на оценку') }), _jsx("div", { className: styles.list, children: howBuildRatingItems.map((item, index) => _jsx(LayoutFAQBlock, { isReverse: index % 2 !== 0, title: item.title, description: item.description, primaryImage: isDesktop ? {
                        ...item.primaryImage,
                        width: 480,
                        height: 324
                    } : null, secondaryImage: {
                        ...item.secondaryImage,
                        width: isMobile ? 311 : 375,
                        height: isMobile ? 242 : 292
                    }, linkTo: item.linkTo, linkText: item.linkText, onClick: handleClickButton }, index)) })] });
};
export default FAQHowBuildRating;
