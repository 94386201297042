import { jsx as _jsx } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { Button, Intent, Size as ButtonSize } from '@front-core/button';
import { FormField, FormFieldIndent } from '@front-core/form-field';
import { Input, Size as InputSize, Type } from '@front-core/input';
import { HeadingXSmall } from '@front-core/typography';
import { useState } from 'react';
import { agenciesSlice } from '@entities/agencies';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { isInvalidField } from '@shared/lib/isInvalidField';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgenciesSearchModal = ({ isModalOpen, onClose }) => {
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const dispatch = useAppDispatch();
    const [hasError, setHasError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const handleClickCloseModal = () => {
        onClose();
    };
    const handleSubmit = () => {
        if (inputValue.length === 0) {
            setHasError(true);
            return;
        }
        const numberValue = Number(inputValue);
        const isNumber = !isNaN(numberValue);
        sendMetrics({
            eventType: 'input',
            elementId: AgenciesMetricHashes.InputSearchNameOrINN,
            elementType: 'Input',
            eventSense: {
                type: isNumber ? 'inn' : 'name'
            }
        });
        dispatch(agenciesSlice.setQueryParams({
            search: inputValue,
            offset: 0
        }));
        onClose();
    };
    const handleChangeInput = (value) => {
        const numberValue = Number(value);
        const isNumber = !isNaN(numberValue);
        setHasError(value.length === 0);
        sendMetrics({
            eventType: 'input',
            elementId: AgenciesMetricHashes.InputSearchNameOrINN,
            elementType: 'Input',
            eventSense: {
                type: isNumber ? 'inn' : 'name'
            }
        });
        setInputValue(value);
    };
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgenciesMetricHashes.ClickSearchNameOrINN,
            elementType: 'Input'
        });
    };
    return _jsx(BottomSheet, { header: _jsx(HeadingXSmall, { tag: "h3", children: "\u041F\u043E\u0438\u0441\u043A \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430" }), footerButtons: [_jsx(Button, { disabled: hasError, text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430", size: ButtonSize.Large, intent: Intent.Primary, onClick: handleSubmit, fluid: true }, "showAgencies")], open: isModalOpen, onClose: handleClickCloseModal, "data-sentry-element": "BottomSheet", "data-sentry-component": "AgenciesSearchModal", "data-sentry-source-file": "index.tsx", children: _jsx(FormField, { errorMessage: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u043D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0438\u043B\u0438 \u0418\u041D\u041D \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430", validity: isInvalidField(hasError), indent: FormFieldIndent.None, "data-sentry-element": "FormField", "data-sentry-source-file": "index.tsx", children: _jsx(Input, { fluid: true, showClearIcon: true, label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430 \u0438\u043B\u0438 \u0418\u041D\u041D", size: InputSize.Large, type: Type.Search, defaultValue: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) || undefined, value: inputValue, validity: isInvalidField(hasError), onValueChange: handleChangeInput, onFocus: handleFocusInput, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" }) }) });
};
export default AgenciesSearchModal;
