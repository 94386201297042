import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { Skeleton, SkeletonType } from '@front-core/skeleton';
import { clsx } from 'clsx';
import styles from './agencySkeletonDesktopSpecializations.scss';
const specializations = Array.from({
    length: 8
}, (_value, index) => index + 1);
const AgencySkeletonDesktopSpecializations = () => _jsx(Paper, { "data-sentry-element": "Paper", "data-sentry-component": "AgencySkeletonDesktopSpecializations", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.container, children: [_jsx(Skeleton, { type: SkeletonType.Text, height: "16px", className: styles.title, "data-sentry-element": "Skeleton", "data-sentry-source-file": "index.tsx" }), _jsx("div", { className: styles.list, children: specializations.map(specialization => _jsx(Skeleton, { type: SkeletonType.Text, height: "32px", className: clsx(styles.item, {
                        [styles.isSmall]: specialization > 4
                    }) }, specialization)) })] }) });
export default AgencySkeletonDesktopSpecializations;
