import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { generatePath } from 'react-router-dom';
import { FAQHowBuildRating } from '@widgets/faq/howBuildRating';
import { FAQHowMatchFindAgent } from '@widgets/faq/howMatchFindAgent';
import { FAQWhoShowInSearch } from '@widgets/faq/whoShowInSearch';
import { SEO } from '@widgets/seo';
import { PATH } from '@shared/config/constants';
import { FAQAgentHashes } from '@shared/config/metrics';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import BackButton from '@shared/ui/backButton';
import Breadcrumbs from '@shared/ui/breadcrumbs';
import FAQAgentHero from './components/hero';
import styles from './faqAgentPage.scss';
const { API_ENV } = vars();
const FAQAgentPage = () => {
    const canonical = useAppSelector(state => state.url.canonical);
    const geo = useAppSelector(state => state.geo);
    const { city, region: { guid: regionId }, slug: regionSlug } = geo;
    const defaultRoute = generatePath(PATH.AGENTS, {
        regionSlug
    });
    const locationName = (city === null || city === void 0 ? void 0 : city.nameLocIn) || `в городе ${city === null || city === void 0 ? void 0 : city.name}`;
    const handleClickBackButton = () => {
        sendMetrics({
            elementId: FAQAgentHashes.ClickBackButton,
            eventType: 'click',
            elementType: 'Button',
            eventSense: {
                regionId
            }
        });
    };
    return _jsxs(Fragment, { children: [_jsx(SEO, { title: "\u041A\u0430\u043A \u0441\u0442\u0430\u0442\u044C \u0443\u0441\u043F\u0435\u0448\u043D\u044B\u043C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u043C", breadcrumbScript: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "item":
                   {
                     "@id": "${URL.MAIN[API_ENV]}",
                     "name": "Домклик"
                   }
              },
              {
                "@type": "ListItem",
                "position": "2",
                "item":
                   {
                     "@id": "${URL.AGENTS[API_ENV].replace('/:regionSlug', '')}",
                     "name": "🔸 АГЕНТЫ"
                   }
              },
              {
                "@type": "ListItem",
                "position": "3",
                "item":
                   {
                     "@id": "${defaultRoute}",
                     "name": "Поиск риелтора ${locationName}"
                   }
              },
              {
                "@type": "ListItem",
                "position": "4",
                "item":
                   {
                     "@id": "${canonical}",
                     "name": "Для риелторов"
                   }
              }
            ]
          }`, "data-sentry-element": "SEO", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.faqAgentPage, children: [_jsx(BackButton, { defaultRoute: defaultRoute, onClick: handleClickBackButton, "data-sentry-element": "BackButton", "data-sentry-source-file": "index.tsx" }), _jsxs("div", { className: styles.container, children: [_jsx(FAQAgentHero, { "data-sentry-element": "FAQAgentHero", "data-sentry-source-file": "index.tsx" }), _jsx(FAQWhoShowInSearch, { "data-sentry-element": "FAQWhoShowInSearch", "data-sentry-source-file": "index.tsx" }), _jsx(FAQHowBuildRating, { "data-sentry-element": "FAQHowBuildRating", "data-sentry-source-file": "index.tsx" }), _jsx(FAQHowMatchFindAgent, { "data-sentry-element": "FAQHowMatchFindAgent", "data-sentry-source-file": "index.tsx" })] }), _jsx("div", { className: styles.breadcrumbs, children: _jsx(Breadcrumbs, { "data-sentry-element": "Breadcrumbs", "data-sentry-source-file": "index.tsx" }) })] })] });
};
export default FAQAgentPage;
