import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Input } from '@front-core/input';
import { Region } from '@front-core/region';
import { Fragment, useState } from 'react';
import { agenciesSlice } from '@entities/agencies';
import { geoApi } from '@entities/geo';
import { GEO } from '@shared/config/constants';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import vars from '@shared/config/vars';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const { API_ENV } = vars();
const AgenciesSearchCityMobile = () => {
    const cityName = useAppSelector(state => state.geo.city.name);
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const dispatch = useAppDispatch();
    const [triggerRegion] = geoApi.useLazyGetRegionQuery();
    const [isOpen, setIsOpen] = useState(false);
    const updateRegion = async (guid) => {
        try {
            const response = await triggerRegion(guid);
            if ('data' in response && response.data) {
                dispatch(agenciesSlice.putQueryParams({
                    regionId: response.data.result.uid
                }));
            }
        }
        catch (error) {
            console.info(error);
        }
    };
    const handleChangeRegion = options => {
        updateRegion(options.localityGuid || options.regionGuid || GEO.GUID).then();
    };
    const handleTriggerModal = () => {
        if (!SSR) {
            sendMetrics({
                eventType: 'click',
                elementId: AgenciesMetricHashes.ClickSearchCity,
                elementType: 'Button'
            });
            if (isWebView && userAgent) {
                setIsOpen(true);
            }
            else {
                window.domclickGlobal.domclickLayout.showRegion();
            }
        }
    };
    const handleCloseModal = () => {
        setIsOpen(false);
    };
    return _jsxs(Fragment, { children: [_jsx(Input, { label: "\u0413\u043E\u0440\u043E\u0434", fluid: true, value: cityName, onFocus: handleTriggerModal, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" }), isWebView ? _jsx(Region, { env: API_ENV, open: isOpen, onChangeRegion: handleChangeRegion, callBackCloseRegion: handleCloseModal }) : null] });
};
export default AgenciesSearchCityMobile;
