import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { Fragment } from 'react';
import { AgencyCreateSubstitutePhoneButton } from '@features/agency/createSubstitutePhone';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgencyRecommendations from '../recommendations';
const AgencyAside = () => {
    const agency = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    if (!isDesktop) {
        return null;
    }
    return _jsxs(Fragment, { children: [_jsx(Paper, { "data-sentry-element": "Paper", "data-sentry-source-file": "index.tsx", children: _jsx(AgencyCreateSubstitutePhoneButton, { agencyId: agency === null || agency === void 0 ? void 0 : agency.id, "data-sentry-element": "AgencyCreateSubstitutePhoneButton", "data-sentry-source-file": "index.tsx" }) }), _jsx(AgencyRecommendations, { "data-sentry-element": "AgencyRecommendations", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyAside;
