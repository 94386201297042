import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { PATH } from '@shared/config/constants';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { Unicode } from '@shared/config/unicode';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import desktopAppPNG from '../../../images/desktop.app.png';
import desktopAppWebP from '../../../images/desktop.app.webp';
import desktopApp2xPNG from '../../../images/desktop.app@2x.png';
import desktopApp2xWebP from '../../../images/desktop.app@2x.webp';
import desktopApprovePNG from '../../../images/desktop.approve.png';
import desktopApproveWebP from '../../../images/desktop.approve.webp';
import desktopApprove2xPNG from '../../../images/desktop.approve@2x.png';
import desktopApprove2xWebP from '../../../images/desktop.approve@2x.webp';
import desktopSearchPNG from '../../../images/desktop.search.png';
import desktopSearchWebP from '../../../images/desktop.search.webp';
import desktopSearch2xPNG from '../../../images/desktop.search@2x.png';
import desktopSearch2xWebP from '../../../images/desktop.search@2x.webp';
import mobileAppPNG from '../../../images/mobile.app.png';
import mobileAppWebP from '../../../images/mobile.app.webp';
import mobileApp2xPNG from '../../../images/mobile.app@2x.png';
import mobileApp2xWebP from '../../../images/mobile.app@2x.webp';
import mobileApprovePNG from '../../../images/mobile.approve.png';
import mobileApproveWebP from '../../../images/mobile.approve.webp';
import mobileApprove2xPNG from '../../../images/mobile.approve@2x.png';
import mobileApprove2xWebP from '../../../images/mobile.approve@2x.webp';
import mobileSearchPNG from '../../../images/mobile.search.png';
import mobileSearchWebP from '../../../images/mobile.search.webp';
import mobileSearch2xPNG from '../../../images/mobile.search@2x.png';
import mobileSearch2xWebP from '../../../images/mobile.search@2x.webp';
import styles from './agentsListControlsFAQ.scss';
import AgentsListControlsFAQItem from './item';
const AgentsListControlsFAQ = () => {
    const media = useAppSelector(state => state.ui.media);
    const regionId = useAppSelector(state => state.geo.region.guid);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    const getAnalyticsElementId = (path) => {
        switch (path) {
            case PATH.FAQ_CHOICE_AGENT:
                return AgentsMetricHashes.ClickHowChoiceAgentBanner;
            case PATH.FAQ_AGENT:
                return AgentsMetricHashes.ClickAgentHelpBanner;
            case PATH.FAQ_APP:
                return AgentsMetricHashes.ClickAppBanner;
            default:
                break;
        }
        return null;
    };
    const sendAnalytics = (path) => {
        const elementId = getAnalyticsElementId(path);
        sendMetrics({
            elementId,
            eventType: 'click',
            elementType: 'Button',
            eventSense: {
                regionId
            }
        });
    };
    const handleClickButton = (path) => {
        sendAnalytics(path);
    };
    return _jsxs("div", { className: styles.agentsListControlsFAQ, "data-sentry-component": "AgentsListControlsFAQ", "data-sentry-source-file": "index.tsx", children: [_jsx(AgentsListControlsFAQItem, { text: `Как выбрать${Unicode.NextLine}риелтора`, href: PATH.FAQ_CHOICE_AGENT, icon: {
                    src: isMobile ? mobileApprovePNG : desktopApprovePNG,
                    src2x: isMobile ? mobileApprove2xPNG : desktopApprove2xPNG,
                    webpSrc: isMobile ? mobileApproveWebP : desktopApproveWebP,
                    webpSrc2x: isMobile ? mobileApprove2xWebP : desktopApprove2xWebP
                }, onClick: () => handleClickButton(PATH.FAQ_CHOICE_AGENT), "data-sentry-element": "AgentsListControlsFAQItem", "data-sentry-source-file": "index.tsx" }), _jsx(AgentsListControlsFAQItem, { text: "\u0427\u0442\u043E \u043D\u0443\u0436\u043D\u043E \u0437\u043D\u0430\u0442\u044C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0443", href: PATH.FAQ_AGENT, icon: {
                    src: isMobile ? mobileSearchPNG : desktopSearchPNG,
                    src2x: isMobile ? mobileSearch2xPNG : desktopSearch2xPNG,
                    webpSrc: isMobile ? mobileSearchWebP : desktopSearchWebP,
                    webpSrc2x: isMobile ? mobileSearch2xWebP : desktopSearch2xWebP
                }, onClick: () => handleClickButton(PATH.FAQ_AGENT), "data-sentry-element": "AgentsListControlsFAQItem", "data-sentry-source-file": "index.tsx" }), _jsx(AgentsListControlsFAQItem, { text: "\u0412 \u043F\u0440\u0438\u043B\u043E\u0436\u0435\u043D\u0438\u0438 \u0435\u0449\u0451 \u0443\u0434\u043E\u0431\u043D\u0435\u0435", href: PATH.FAQ_APP, icon: {
                    src: isMobile ? mobileAppPNG : desktopAppPNG,
                    src2x: isMobile ? mobileApp2xPNG : desktopApp2xPNG,
                    webpSrc: isMobile ? mobileAppWebP : desktopAppWebP,
                    webpSrc2x: isMobile ? mobileApp2xWebP : desktopApp2xWebP
                }, onClick: () => handleClickButton(PATH.FAQ_APP), "data-sentry-element": "AgentsListControlsFAQItem", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentsListControlsFAQ;
