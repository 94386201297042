import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarIntent, AvatarSize, AvatarType } from '@front-core/avatar';
import { Link } from '@front-core/link';
import { LabelStandardRegular } from '@front-core/typography';
import { clsx } from 'clsx';
import { generatePath } from 'react-router-dom';
import { AgentTypes, AgentSubtypesLabel } from '@entities/agents';
import { AgentTypesLabel } from '@entities/agents/config/agentTypes';
import { PATH } from '@shared/config/constants';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import CustomLink from '@shared/ui/customLink';
import LayoutAvatar from '@shared/ui/layout/avatar';
import styles from './agentInfoWork.scss';
const SBER = 'Центр реализации залогового имущества Сбербанка';
const AgentInfoWork = ({ company, agentType, agentSubtype }) => {
    var _a;
    const isWebView = useAppSelector(state => state.ui.isWebView);
    const agencySlug = String(company === null || company === void 0 ? void 0 : company.slug);
    const agencyUrl = generatePath(PATH.AGENCY, {
        companyId: agencySlug
    });
    const isFreelancerOrIndividual = agentType === AgentTypes.Freelancer || agentType === AgentTypes.Individual;
    const hasLink = (company === null || company === void 0 ? void 0 : company.name) && !isFreelancerOrIndividual && company.name.toLowerCase() !== SBER.toLowerCase();
    if (!isFreelancerOrIndividual && !((_a = company === null || company === void 0 ? void 0 : company.name) === null || _a === void 0 ? void 0 : _a.length)) {
        return null;
    }
    return _jsxs("div", { className: clsx(styles.agentInfoWork, {
            [styles.onlyText]: !(company === null || company === void 0 ? void 0 : company.logoUrl)
        }), "data-sentry-component": "AgentInfoWork", "data-sentry-source-file": "index.tsx", children: [_jsx(Link, { disableUnderline: true, text: (company === null || company === void 0 ? void 0 : company.name) || AgentTypesLabel[agentType], className: clsx(styles.company, {
                    [styles.isText]: !hasLink
                }), routerLink: hasLink ? _jsx(CustomLink, { target: isWebView ? undefined : '_blank', to: agencyUrl }) : undefined, "data-sentry-element": "Link", "data-sentry-source-file": "index.tsx" }), agentSubtype && !isFreelancerOrIndividual ? _jsx(LabelStandardRegular, { tag: "p", wrapper: false, className: styles.position, children: AgentSubtypesLabel[agentSubtype] }) : null, (company === null || company === void 0 ? void 0 : company.logoUrl) ? _jsx("div", { className: styles.avatar, children: _jsx(LayoutAvatar, { name: company.name, intent: AvatarIntent.Secondary, imageSrc: company.logoUrl, type: AvatarType.Rect, size: AvatarSize.Small }) }) : null] });
};
export default AgentInfoWork;
