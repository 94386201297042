import { jsx as _jsx } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import AgencySkeletonDesktop from './desktop';
import AgencySkeletonMobile from './mobile';
const AgencyPageSkeleton = ({ isPending }) => {
    const breakpoint = useBreakpoint(BreakPoints.MOBILE);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    if (!isPending) {
        return null;
    }
    return isMobile ? _jsx(AgencySkeletonMobile, { "data-sentry-element": "AgencySkeletonMobile", "data-sentry-component": "AgencyPageSkeleton", "data-sentry-source-file": "index.tsx" }) : _jsx(AgencySkeletonDesktop, { "data-sentry-element": "AgencySkeletonDesktop", "data-sentry-component": "AgencyPageSkeleton", "data-sentry-source-file": "index.tsx" });
};
export default AgencyPageSkeleton;
