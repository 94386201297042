import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingLarge } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import LayoutFAQBlock from '@shared/ui/layout/faqBlock';
import { faqClientAttentions } from '../config/attentions';
import styles from './faqClientAttention.scss';
const FAQClientAttention = () => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.DESKTOP || breakpoint === BreakPoints.TABLET_LANDSCAPE;
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsxs(Paper, { tag: "section", className: styles.faqClientAttention, "data-sentry-element": "Paper", "data-sentry-component": "FAQClientAttention", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingLarge, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingLarge", "data-sentry-source-file": "index.tsx", children: "\u041D\u0430 \u0447\u0442\u043E \u043E\u0431\u0440\u0430\u0442\u0438\u0442\u044C \u0432\u043D\u0438\u043C\u0430\u043D\u0438\u0435" }), _jsx("div", { className: styles.list, children: faqClientAttentions.map((attention, index) => _jsx(LayoutFAQBlock, { isReverse: index % 2 !== 0, title: attention.title, description: attention.description, primaryImage: isDesktop ? {
                        ...attention.primaryImage,
                        width: 480,
                        height: 324
                    } : null, secondaryImage: {
                        ...attention.secondaryImage,
                        width: isMobile ? 311 : 375,
                        height: isMobile ? 242 : 292
                    } }, index)) })] });
};
export default FAQClientAttention;
