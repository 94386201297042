import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import LayoutGridWithStickyAside from '@shared/ui/layout/gridWithStickyAside';
import AgencySkeletonAgents from '../agents';
import AgencySkeletonOffices from '../offices';
import AgencySkeletonDesktopAbout from './about';
import AgencySkeletonDesktopAside from './aside';
import AgencySkeletonDesktopInfo from './info';
import AgencySkeletonDesktopSpecializations from './specializations';
const AgencySkeletonDesktop = () => _jsx(LayoutGridWithStickyAside, { mainContent: _jsxs(Fragment, { children: [_jsx(AgencySkeletonDesktopInfo, {}), _jsx(AgencySkeletonDesktopAbout, {}), _jsx(AgencySkeletonDesktopSpecializations, {}), _jsx(AgencySkeletonAgents, {}), _jsx(AgencySkeletonOffices, {})] }), asideContent: _jsx(AgencySkeletonDesktopAside, {}), "data-sentry-element": "LayoutGridWithStickyAside", "data-sentry-component": "AgencySkeletonDesktop", "data-sentry-source-file": "index.tsx" });
export default AgencySkeletonDesktop;
