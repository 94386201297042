import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Size } from '@front-core/button';
import { Illustration, Illustrations, IllustrationSize, IllustrationFormat } from '@front-core/illustration';
import { HeadingSmall, LabelStandardRegular } from '@front-core/typography';
import { AgentReviewCreate } from '@features/agent/review/create';
import { Unicode } from '@shared/config/unicode';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentReviewsEmptyListForTheYear.scss';
const AgentReviewsEmptyListForTheYear = ({ hasBottomSheetListContainer }) => {
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const agentCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const isUser = Number(userCasId) !== Number(agentCasId);
    return _jsxs("div", { className: styles.agentReviewsEmptyListForTheYear, "data-sentry-component": "AgentReviewsEmptyListForTheYear", "data-sentry-source-file": "index.tsx", children: [_jsx(Illustration, { name: Illustrations.Box, size: IllustrationSize.Large, format: IllustrationFormat.WEBP, alt: "Box", "data-sentry-element": "Illustration", "data-sentry-source-file": "index.tsx" }), _jsx(HeadingSmall, { tag: "strong", wrapper: false, className: styles.title, "data-sentry-element": "HeadingSmall", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(`Нет отзывов за последние 12${Unicode.NoBreakSpace}месяцев`) }), _jsx(LabelStandardRegular, { tag: "p", wrapper: false, className: styles.description, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp('Посмотрите отзывы за всё остальное время') }), isUser && !hasBottomSheetListContainer ? _jsx("div", { className: styles.buttonContainer, children: _jsx(AgentReviewCreate, { fluidButton: true, sizeButton: Size.Large }) }) : null] });
};
export default AgentReviewsEmptyListForTheYear;
