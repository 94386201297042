import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelStandardRegular } from '@front-core/typography';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { clsx } from 'clsx';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agenciesListStickyHeader.scss';
const AgenciesListStickyHeader = () => {
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isDesktop = breakpoint === BreakPoints.TABLET_LANDSCAPE || breakpoint === BreakPoints.DESKTOP;
    return _jsx("div", { className: styles.agenciesListStickyHeader, "data-sentry-component": "AgenciesListStickyHeader", "data-sentry-source-file": "index.tsx", children: _jsxs("div", { className: styles.row, children: [_jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0410\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u043E \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438" }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: `Рейтинг${isDesktop ? ', отзывы' : ''}` }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: clsx(styles.header, styles.textCenter), "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0421\u0434\u0435\u043B\u043A\u0438" }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: clsx(styles.header, styles.textCenter), "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u041E\u0431\u044A\u044F\u0432\u043B\u0435\u043D\u0438\u044F" }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.header, "data-sentry-element": "LabelStandardRegular", "data-sentry-source-file": "index.tsx", children: "\u0414\u0435\u0439\u0441\u0442\u0432\u0438\u044F" })] }) });
};
export default AgenciesListStickyHeader;
