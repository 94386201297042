import { AgencyDealType } from '@entities/agencies';
export const dealTypeOptions = [
    {
        title: 'Любая',
        value: AgencyDealType.Any
    },
    {
        title: 'Покупка и продажа',
        value: AgencyDealType.Sale
    },
    {
        title: 'Аренда',
        value: AgencyDealType.Rent
    }
];
