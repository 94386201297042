import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Button, Size, Intent } from '@front-core/button';
import ArrowDownIcon from '@front-core/icon/dist/ArrowDown';
import { LabelStandardMedium } from '@front-core/typography';
import { BreakPoints, useBreakpoint } from '@front-core/utils';
import { clsx } from 'clsx';
import { useMemo, useRef, useState, useEffect } from 'react';
import { generatePath } from 'react-router-dom';
import { agenciesApi, agenciesSlice } from '@entities/agencies';
import { geoApi } from '@entities/geo';
import { RegionScope } from '@shared/config/common';
import { PATH } from '@shared/config/constants';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { ArrayHelper } from '@shared/lib/helpers/array';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import styles from './agenciesRegions.scss';
const AgenciesRegions = () => {
    const regions = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.regions; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const dispatch = useAppDispatch();
    const [triggerAgenciesRegions] = agenciesApi.useLazyGetAgenciesRegionsQuery();
    const [triggerRegion] = geoApi.useLazyGetRegionQuery();
    const [isShowItem, setIsShowItem] = useState(false);
    const titleRef = useRef(null);
    const breakpoints = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoints === BreakPoints.MOBILE;
    const isTablet = breakpoints === BreakPoints.TABLET_PORTRAIT;
    const getLink = (regionSlug) => generatePath(PATH.AGENCIES, {
        regionSlug
    });
    const getColumnCount = () => {
        if (isMobile) {
            return 2;
        }
        if (isTablet) {
            return 4;
        }
        return 6;
    };
    const columnizeRegions = useMemo(() => ArrayHelper.columnize(regions, getColumnCount()), [breakpoints, regions]);
    const updateRegion = async (guid) => {
        dispatch(agenciesSlice.putQueryParams({
            regionId: guid
        }));
        try {
            await triggerRegion(guid);
        }
        catch (error) {
            console.info(error);
        }
    };
    const handleClickLink = (event, id, regionSlug) => {
        if (!SSR) {
            event.preventDefault();
            if (id) {
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth'
                });
                history.replaceState({}, '', getLink(regionSlug));
                updateRegion(id).finally();
            }
            sendMetrics({
                eventType: 'click',
                elementId: AgenciesMetricHashes.ClickLinkRegions,
                elementType: 'Link'
            });
        }
    };
    const handleClickButton = (event) => {
        event.preventDefault();
        setIsShowItem(prevState => {
            if (titleRef.current) {
                setTimeout(() => {
                    var _a;
                    (_a = titleRef.current) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
                        behavior: 'smooth'
                    });
                }, 50);
            }
            return !prevState;
        });
    };
    useEffect(() => {
        if (!SSR && !(regions === null || regions === void 0 ? void 0 : regions.length)) {
            triggerAgenciesRegions({
                scope: RegionScope.Agencies
            }).then();
        }
    }, []);
    if (!(regions === null || regions === void 0 ? void 0 : regions.length)) {
        return null;
    }
    return _jsxs("section", { className: styles.container, "data-sentry-component": "AgenciesRegions", "data-sentry-source-file": "index.tsx", children: [_jsx("h2", { className: styles.title, ref: titleRef, children: "\u0410\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430 \u043D\u0435\u0434\u0432\u0438\u0436\u0438\u043C\u043E\u0441\u0442\u0438 \u0432 \u0434\u0440\u0443\u0433\u0438\u0445 \u0440\u0435\u0433\u0438\u043E\u043D\u0430\u0445" }), _jsxs("div", { className: styles.body, children: [_jsx("div", { className: styles.list, children: columnizeRegions.map((columnRegion, index) => _jsx("div", { className: styles.column, children: columnRegion.map(region => _jsx("div", { className: clsx(styles.item, {
                                    [styles.isShow]: isShowItem
                                }), children: _jsx(Button, { size: Size.Small, intent: Intent.Transparent, text: region.name, href: getLink(region.slug), onClick: event => handleClickLink(event, region.uid, region.slug) }) }, region.uid)) }, index)) }), _jsxs("button", { className: styles.itemButton, onClick: handleClickButton, children: [_jsx(LabelStandardMedium, { wrapper: false, tag: "span", className: styles.itemButtonText, "data-sentry-element": "LabelStandardMedium", "data-sentry-source-file": "index.tsx", children: isShowItem ? 'Свернуть' : 'Показать всё' }), _jsx(ArrowDownIcon, { className: clsx(styles.icon, {
                                    [styles.isShow]: isShowItem
                                }), "data-sentry-element": "ArrowDownIcon", "data-sentry-source-file": "index.tsx" })] })] })] });
};
export default AgenciesRegions;
