export const getSearchDistrictResultIds = (districts, selectedIds) => districts.reduce((acc, district) => {
    var _a;
    const childrenIds = ((_a = district.children) === null || _a === void 0 ? void 0 : _a.map(child => child.id)) || [];
    const isParentWithChildren = childrenIds.length > 0;
    if (isParentWithChildren) {
        const allChildrenSelected = childrenIds.every(id => selectedIds.includes(id));
        if (allChildrenSelected) {
            acc.push(district.id);
        }
        else {
            const selectedChildren = childrenIds.filter(id => selectedIds.includes(id));
            acc.push(...selectedChildren);
        }
    }
    else if (selectedIds.includes(district.id)) {
        acc.push(district.id);
    }
    return acc;
}, []);
