import { jsx as _jsx } from "react/jsx-runtime";
import SearchIcon from '@front-core/icon/dist/Search';
import { Input, Size, Type } from '@front-core/input';
import { debounce, usePrevious } from '@front-core/utils';
import { useRef, useEffect } from 'react';
import { agenciesSlice } from '@entities/agencies';
import { AgenciesMetricHashes } from '@shared/config/metrics';
import { EventHelper } from '@shared/lib/helpers/event';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
const DELAY_IN_MS = 500;
const AgenciesSearch = () => {
    const isRegionChangedEmptyList = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.isRegionChangedEmptyList; });
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const dispatch = useAppDispatch();
    const prevSearch = usePrevious(queryParams === null || queryParams === void 0 ? void 0 : queryParams.search);
    const inputRef = useRef(null);
    const handleChangeValue = debounce(DELAY_IN_MS, (value) => {
        const search = value.length > 0 ? value : null;
        const numberValue = Number(value);
        const isNumber = !isNaN(numberValue);
        sendMetrics({
            eventType: 'input',
            elementId: AgenciesMetricHashes.InputSearchNameOrINN,
            elementType: 'Input',
            eventSense: {
                type: isNumber ? 'inn' : 'name'
            }
        });
        if ((queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) !== search) {
            dispatch(agenciesSlice.setQueryParams({
                search,
                offset: 0
            }));
        }
    });
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgenciesMetricHashes.ClickSearchNameOrINN,
            elementType: 'Input'
        });
    };
    useEffect(() => {
        if ((prevSearch === null || prevSearch === void 0 ? void 0 : prevSearch.length) && !(queryParams === null || queryParams === void 0 ? void 0 : queryParams.search)) {
            EventHelper.clearInput(inputRef);
        }
    }, [queryParams.search]);
    return _jsx(Input, { fluid: true, showClearIcon: true, disabled: isRegionChangedEmptyList, inputRef: inputRef, defaultValue: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) || undefined, leftSection: _jsx(SearchIcon, {}), label: "\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435 \u0430\u0433\u0435\u043D\u0442\u0441\u0442\u0432\u0430 \u0438\u043B\u0438 \u0418\u041D\u041D", type: Type.Search, size: Size.Large, onValueChange: handleChangeValue, onFocus: handleFocusInput, "data-sentry-element": "Input", "data-sentry-component": "AgenciesSearch", "data-sentry-source-file": "index.tsx" });
};
export default AgenciesSearch;
