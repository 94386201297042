import { jsx as _jsx } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { Button, Intent, Size as ButtonSize } from '@front-core/button';
import { FormField, FormFieldIndent } from '@front-core/form-field';
import { Input, Size as InputSize, Type } from '@front-core/input';
import { HeadingXSmall } from '@front-core/typography';
import { useState } from 'react';
import { agentsSlice } from '@entities/agents';
import { AgentsMetricHashes } from '@shared/config/metrics';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { isInvalidField } from '@shared/lib/isInvalidField';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgentsSearchModal = ({ isModalOpen, onClose }) => {
    const queryParams = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.queryParams; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const dispatch = useAppDispatch();
    const [hasError, setHasError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const handleClickCloseModal = () => {
        onClose();
    };
    const handleSubmit = () => {
        if (inputValue.length === 0) {
            setHasError(true);
            return;
        }
        sendMetrics({
            eventType: 'input',
            elementId: AgentsMetricHashes.InputSearchNameOrPhone,
            elementType: 'Input',
            eventSense: {
                regionId,
                type: StringHelper.isPhoneNumber(inputValue) ? 'phone' : 'name'
            }
        });
        dispatch(agentsSlice.setQueryParams({
            search: inputValue,
            includeOtherRegions: Boolean(inputValue.length),
            offset: 0
        }));
        onClose();
    };
    const handleChangeInput = (value) => {
        setHasError(value.length === 0);
        setInputValue(value);
    };
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgentsMetricHashes.ClickSearchNameOrPhone,
            elementType: 'Input'
        });
    };
    return _jsx(BottomSheet, { header: _jsx(HeadingXSmall, { tag: "h3", children: "\u041F\u043E\u0438\u0441\u043A \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430" }), footerButtons: [_jsx(Button, { disabled: hasError, text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432", size: ButtonSize.Large, intent: Intent.Primary, onClick: handleSubmit, fluid: true }, "showAgents")], open: isModalOpen, onClose: handleClickCloseModal, "data-sentry-element": "BottomSheet", "data-sentry-component": "AgentsSearchModal", "data-sentry-source-file": "index.tsx", children: _jsx(FormField, { errorMessage: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", validity: isInvalidField(hasError), indent: FormFieldIndent.None, "data-sentry-element": "FormField", "data-sentry-source-file": "index.tsx", children: _jsx(Input, { fluid: true, autoFocus: true, showClearIcon: true, label: "\u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", size: InputSize.Large, type: Type.Search, defaultValue: (queryParams === null || queryParams === void 0 ? void 0 : queryParams.search) || undefined, value: inputValue, validity: isInvalidField(hasError), onValueChange: handleChangeInput, onFocus: handleFocusInput, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" }) }) });
};
export default AgentsSearchModal;
