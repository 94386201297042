import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { clsx } from 'clsx';
import { Fragment } from 'react';
import { AgentsList } from '@widgets/agents/list';
import { AgentsListControls } from '@widgets/agents/listControls';
import { AgentsRegions } from '@widgets/agents/regions';
import CSIAgents from '@widgets/csi/agents';
import { SEO } from '@widgets/seo';
import { TopBanners } from '@widgets/topBanners';
import { URL } from '@shared/config/urls';
import vars from '@shared/config/vars';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import Breadcrumbs from '@shared/ui/breadcrumbs';
import styles from './agentsPage.scss';
import AgentsFaq from './components/faq';
const { API_ENV } = vars();
const AgentsPage = () => {
    const canonical = useAppSelector(state => { var _a; return (_a = state.url) === null || _a === void 0 ? void 0 : _a.canonical; });
    const geoCity = useAppSelector(state => state.geo.city);
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const hasWebBlocks = !isWebView;
    const locationName = (geoCity === null || geoCity === void 0 ? void 0 : geoCity.nameLocIn) || `в городе ${geoCity === null || geoCity === void 0 ? void 0 : geoCity.name}`;
    return _jsxs("div", { className: clsx(styles.page, {
            [styles.isWebView]: isWebView
        }), "data-sentry-component": "AgentsPage", "data-sentry-source-file": "index.tsx", children: [_jsx(SEO, { title: `Риэлторы ${locationName} - услуги агентов по недвижимости и рейтинг риэлторов: контакты, отзывы клиентов - Домклик`, description: `Подберите лучшего риэлтора по недвижимости ${locationName} с Домклик  🏆 Рейтинг риэлторов 💬 Отзывы от проверенных клиентов  📞 Контакты агентов и агентств`, keywords: `Риэлторы ${locationName}, найти риэлтора ${locationName}. Риелторы ${locationName}, найти риелтора ${locationName}.`, breadcrumbScript: `{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
              {
                "@type": "ListItem",
                "position": "1",
                "item":
                   {
                     "@id": "${URL.MAIN[API_ENV]}",
                     "name": "Домклик"
                   }
              },
              {
                "@type": "ListItem",
                "position": "2",
                "item":
                   {
                     "@id": "${URL.AGENTS[API_ENV].replace('/:regionSlug', '')}",
                     "name": "🔸 АГЕНТЫ"
                   }
              },
              {
                "@type": "ListItem",
                "position": "3",
                "item":
                   {
                     "@id": "${canonical}",
                     "name": "🏡 Риелторы ${locationName}"
                   }
              }
            ]
          }`, "data-sentry-element": "SEO", "data-sentry-source-file": "index.tsx" }), _jsx(AgentsListControls, { "data-sentry-element": "AgentsListControls", "data-sentry-source-file": "index.tsx" }), _jsx(AgentsList, { "data-sentry-element": "AgentsList", "data-sentry-source-file": "index.tsx" }), hasWebBlocks ? _jsxs(Fragment, { children: [_jsx(TopBanners, {}), _jsx(CSIAgents, {}), _jsx(AgentsFaq, { cityNameLocIn: locationName }), _jsx(AgentsRegions, {})] }) : null, _jsx(Breadcrumbs, { "data-sentry-element": "Breadcrumbs", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentsPage;
