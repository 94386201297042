import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { LabelLargeMedium } from '@front-core/typography';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import AgencyReviewsControls from '../controls';
import AgencyReviewsList from '../list';
import styles from './reviewBottomSheetList.scss';
const ReviewBottomSheetList = ({ isOpen, onClose }) => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    return _jsx(BottomSheet, { fixedHeight: true, topIndent: isWebView ? 0 : 8, open: isOpen, onClose: onClose, header: _jsxs("div", { className: styles.reviewBottomSheetList, children: [_jsx(LabelLargeMedium, { tag: "strong", wrapper: false, children: "\u041E\u0442\u0437\u044B\u0432\u044B \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u0421\u0431\u0435\u0440\u0411\u0430\u043D\u043A\u0430" }), _jsx(AgencyReviewsControls, {})] }), "data-sentry-element": "BottomSheet", "data-sentry-component": "ReviewBottomSheetList", "data-sentry-source-file": "reviewBottomSheetList.tsx", children: _jsx(AgencyReviewsList, { "data-sentry-element": "AgencyReviewsList", "data-sentry-source-file": "reviewBottomSheetList.tsx" }) });
};
export default ReviewBottomSheetList;
