import { jsx as _jsx } from "react/jsx-runtime";
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import AgentSkeletonDesktop from './desktop';
import AgentSkeletonMobile from './mobile';
const AgentPageSkeleton = ({ isPending }) => {
    const breakpoint = useBreakpoint(BreakPoints.MOBILE);
    const isMobile = breakpoint === BreakPoints.MOBILE || breakpoint === BreakPoints.TABLET_PORTRAIT;
    if (!isPending) {
        return null;
    }
    return isMobile ? _jsx(AgentSkeletonMobile, { "data-sentry-element": "AgentSkeletonMobile", "data-sentry-component": "AgentPageSkeleton", "data-sentry-source-file": "index.tsx" }) : _jsx(AgentSkeletonDesktop, { "data-sentry-element": "AgentSkeletonDesktop", "data-sentry-component": "AgentPageSkeleton", "data-sentry-source-file": "index.tsx" });
};
export default AgentPageSkeleton;
