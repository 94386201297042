import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Picture } from '@front-core/picture';
import { HeadingStandard, LabelLargeRegular } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './faqAgentAdvantagesItem.scss';
const FAQAgentAdvantagesItem = ({ title, description, image }) => {
    const media = useAppSelector(state => state.ui.media);
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.DESKTOP);
    const isMobile = breakpoint === BreakPoints.MOBILE;
    return _jsxs("article", { className: styles.faqAgentAdvantagesItem, "data-sentry-component": "FAQAgentAdvantagesItem", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: styles.container, children: [_jsx(HeadingStandard, { tag: "h3", wrapper: false, "data-sentry-element": "HeadingStandard", "data-sentry-source-file": "index.tsx", children: title }), _jsx(LabelLargeRegular, { tag: "p", wrapper: false, "data-sentry-element": "LabelLargeRegular", "data-sentry-source-file": "index.tsx", children: StringHelper.addNbsp(description) })] }), _jsx(Picture, { src: image.src, src2x: image.src2x, webpSrc: image.webpSrc, webpSrc2x: image.webpSrc2x, className: styles.image, width: isMobile ? 60 : 134, height: isMobile ? 60 : 134, loading: "lazy", alt: "", "data-sentry-element": "Picture", "data-sentry-source-file": "index.tsx" })] });
};
export default FAQAgentAdvantagesItem;
