export const Orders = [
    {
        title: 'Сначала полезные',
        value: 'helpful'
    },
    {
        title: 'Сначала новые',
        value: 'new'
    },
    {
        title: 'Сначала положительные',
        value: 'positive'
    },
    {
        title: 'Сначала отрицательные',
        value: 'negative'
    }
];
