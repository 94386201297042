import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LabelSmallRegular } from '@front-core/typography';
import { AgencyReviewPeriodFilter } from '@features/agency/review/periodFilter';
import { AgencyReviewSort } from '@features/agency/review/sort';
import { AgencyAgentsReviewsPeriod } from '@entities/agencies';
import { Unicode } from '@shared/config/unicode';
import { StringHelper } from '@shared/lib/helpers/string';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agencyReviewsControls.scss';
const AgencyReviewsControls = () => {
    const totalReview = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.pagination) === null || _b === void 0 ? void 0 : _b.total; });
    const agencyReviewQueryParams = useAppSelector(state => { var _a; return (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.reviews.queryParams; });
    return _jsxs("div", { className: styles.agencyReviewsControls, "data-sentry-component": "AgencyReviewsControls", "data-sentry-source-file": "index.tsx", children: [_jsx(AgencyReviewPeriodFilter, { "data-sentry-element": "AgencyReviewPeriodFilter", "data-sentry-source-file": "index.tsx" }), _jsx(AgencyReviewSort, { "data-sentry-element": "AgencyReviewSort", "data-sentry-source-file": "index.tsx" }), totalReview ? _jsxs(LabelSmallRegular, { tag: "p", wrapper: false, className: styles.text, children: [StringHelper.getPluralForm(totalReview, ['отзыв', 'отзыва', 'отзывов'], true), ' ', "\u043E \u043F\u0440\u043E\u0432\u0435\u0434\u0435\u043D\u043D\u044B\u0445 \u0441\u0434\u0435\u043B\u043A\u0430\u0445 \u0437\u0430 ", agencyReviewQueryParams.period === AgencyAgentsReviewsPeriod.All ? `всё${Unicode.NoBreakSpace}время` : `12${Unicode.NoBreakSpace}месяцев`] }) : null] });
};
export default AgencyReviewsControls;
