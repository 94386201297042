import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Paper } from '@front-core/box';
import { HeadingXSmall } from '@front-core/typography';
import { useBreakpoint, BreakPoints } from '@front-core/utils';
import { useCallback } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import ClampedText from '@shared/ui/clampedText';
import styles from './agentAboutMe.scss';
import AchievementsMobile from './components/achievements/mobile';
import AchievementsTablet from './components/achievements/tablet';
const AgentAboutMe = () => {
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const media = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.media; });
    const breakpoint = useBreakpoint(media === BreakPoints.MOBILE ? BreakPoints.MOBILE : BreakPoints.TABLET_LANDSCAPE);
    const isTablet = breakpoint !== BreakPoints.MOBILE && breakpoint !== BreakPoints.TABLET_PORTRAIT;
    const getSlots = useCallback(() => {
        if (agent.certificates && agent.certificates.length > 0) {
            return agent.certificates.map(certificate => ({
                src: certificate.fileUrl,
                title: ''
            }));
        }
        return [];
    }, [agent.certificates]);
    const slots = getSlots();
    if (!(agent === null || agent === void 0 ? void 0 : agent.description) && !(agent === null || agent === void 0 ? void 0 : agent.certificates)) {
        return null;
    }
    return _jsxs(Paper, { tag: "section", className: styles.container, "data-sentry-element": "Paper", "data-sentry-component": "AgentAboutMe", "data-sentry-source-file": "index.tsx", children: [_jsx(HeadingXSmall, { tag: "h4", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E \u0441\u0435\u0431\u0435" }), (agent === null || agent === void 0 ? void 0 : agent.description) ? _jsx(ClampedText, { text: agent.description }) : null, isTablet ? _jsx(AchievementsTablet, { slots: slots }) : _jsx(AchievementsMobile, { slots: slots })] });
};
export default AgentAboutMe;
