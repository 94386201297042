import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { HeadingXSmall, LabelStandardRegular } from '@front-core/typography';
import LayoutRating from '@shared/ui/layout/rating';
import { RatingType, RatingFontSize } from '@shared/ui/layout/rating/config';
import AgencyReviewsControls from '../controls';
import styles from './agencyReviewsTitle.scss';
const AgencyReviewsTitle = ({ activeCount, rating }) => {
    const hasActiveReviews = activeCount && activeCount > 0;
    return _jsxs("div", { className: styles.agencyReviewsTitle, "data-sentry-component": "AgencyReviewsTitle", "data-sentry-source-file": "index.tsx", children: [_jsxs("div", { className: styles.container, children: [_jsx(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u041E\u0442\u0437\u044B\u0432\u044B \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 \u0421\u0431\u0435\u0440\u0411\u0430\u043D\u043A\u0430" }), hasActiveReviews ? _jsxs("div", { className: styles.rating, children: [_jsx(LayoutRating, { hasGrade: true, fontSize: RatingFontSize.Medium, type: RatingType.Secondary, rating: rating || 0 }), _jsx(LabelStandardRegular, { tag: "span", wrapper: false, className: styles.totalRating, children: "\u0438\u0437 5" })] }) : null] }), _jsx(AgencyReviewsControls, { "data-sentry-element": "AgencyReviewsControls", "data-sentry-source-file": "index.tsx" })] });
};
export default AgencyReviewsTitle;
