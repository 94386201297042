import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Intent, Size } from '@front-core/button';
import { clsx } from 'clsx';
import { memo } from 'react';
import { AgentChatCreateButton } from '@features/agent/chat';
import { AgentCreateSubstitutePhoneButton } from '@features/agent/createSubstitutePhone';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentActionButtons.scss';
const AgentActionButtons = () => {
    const agent = useAppSelector(state => { var _a; return (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details; });
    const isWebView = useAppSelector(state => state.ui.isWebView);
    return _jsxs("div", { className: clsx(styles.container, {
            [styles.isWebView]: isWebView
        }), "data-sentry-component": "AgentActionButtons", "data-sentry-source-file": "index.tsx", children: [_jsx(AgentCreateSubstitutePhoneButton, { agentCasId: agent.casId, "data-sentry-element": "AgentCreateSubstitutePhoneButton", "data-sentry-source-file": "index.tsx" }), _jsx(AgentChatCreateButton, { text: "\u041D\u0430\u043F\u0438\u0441\u0430\u0442\u044C \u0432 \u0447\u0430\u0442", agentCasId: agent.casId, buttonSize: Size.Large, buttonIntent: Intent.Secondary, "data-sentry-element": "AgentChatCreateButton", "data-sentry-source-file": "index.tsx" })] });
};
export default memo(AgentActionButtons);
