import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Tag } from '@front-core/badge';
import { Paper } from '@front-core/box';
import ArrowDownIcon from '@front-core/icon/dist/ArrowDown';
import { HeadingXSmall, LabelLargeMedium } from '@front-core/typography';
import { debounce } from '@front-core/utils';
import { clsx } from 'clsx';
import { useState, useRef, useEffect, Fragment } from 'react';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import styles from './agentSpecializations.scss';
const DEFAULT_MAX_HEIGHT = '72px';
const DEBOUNCE_INTERVAL_IN_MS = 300;
const AgentSpecializations = () => {
    const specializations = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents.agent) === null || _a === void 0 ? void 0 : _a.details) === null || _b === void 0 ? void 0 : _b.specializations; });
    const [isShowList, setIsShowList] = useState(false);
    const [hasButton, setHasButton] = useState(false);
    const containerRef = useRef(null);
    const updateListStyles = () => {
        if (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) {
            const container = containerRef.current;
            const hasHiddenContent = container.scrollHeight > container.offsetHeight;
            container.style.maxHeight = hasHiddenContent && !isShowList ? `${container.scrollHeight}px` : DEFAULT_MAX_HEIGHT;
        }
    };
    const updateButtonVisibility = () => {
        if (containerRef === null || containerRef === void 0 ? void 0 : containerRef.current) {
            const container = containerRef.current;
            const hasHiddenContent = container.scrollHeight > container.offsetHeight;
            setHasButton(hasHiddenContent);
        }
    };
    const handleResize = debounce(DEBOUNCE_INTERVAL_IN_MS, () => {
        updateButtonVisibility();
    });
    const handleToggleList = () => {
        setIsShowList(prevState => !prevState);
        updateListStyles();
    };
    useEffect(() => {
        updateButtonVisibility();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    if (!(specializations === null || specializations === void 0 ? void 0 : specializations.length)) {
        return null;
    }
    return _jsx(Paper, { tag: "section", className: styles.agentSpecializations, "data-sentry-element": "Paper", "data-sentry-component": "AgentSpecializations", "data-sentry-source-file": "index.tsx", children: _jsxs("section", { children: [_jsx(HeadingXSmall, { tag: "h2", wrapper: false, className: styles.title, "data-sentry-element": "HeadingXSmall", "data-sentry-source-file": "index.tsx", children: "\u0421\u043F\u0435\u0446\u0438\u0430\u043B\u0438\u0437\u0430\u0446\u0438\u044F" }), _jsx("div", { ref: containerRef, className: styles.tags, children: specializations.map((specialization, index) => _jsx(Tag, { disabled: true, text: _jsxs(Fragment, { children: [_jsx(LabelLargeMedium, { tag: "span", wrapper: false, className: styles.tag, children: specialization.name }), specialization.count ? _jsx(LabelLargeMedium, { tag: "span", wrapper: false, className: styles.tagCount, children: specialization.count }) : null] }) }, index)) }), hasButton ? _jsxs("button", { className: styles.button, onClick: handleToggleList, children: [_jsx("span", { children: isShowList ? 'Скрыть' : 'Показать всё' }), _jsx(ArrowDownIcon, { className: clsx(styles.buttonIcon, {
                                [styles.isShow]: isShowList
                            }) })] }) : null] }) });
};
export default AgentSpecializations;
