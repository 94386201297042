import { jsx as _jsx } from "react/jsx-runtime";
import { Button, Intent } from '@front-core/button';
import ArrowLeftIcon from '@front-core/icon/dist/ArrowLeft';
import useGoBack from '@shared/lib/hooks/useGoBack';
import styles from './backButton.scss';
const BackButton = ({ defaultRoute, onClick }) => {
    const goBack = useGoBack(defaultRoute);
    const handleClickGoBack = () => {
        if (onClick) {
            onClick();
        }
        goBack();
    };
    return _jsx(Button, { text: "\u041D\u0430\u0437\u0430\u0434", icon: _jsx(ArrowLeftIcon, {}), wrapperClassName: styles.backButton, onClick: handleClickGoBack, intent: Intent.SecondaryAlternative, "data-sentry-element": "Button", "data-sentry-component": "BackButton", "data-sentry-source-file": "index.tsx" });
};
export default BackButton;
