import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AlertStatus, AlertList } from '@front-core/alert';
import { Button, Intent, Size } from '@front-core/button';
import { AuthType, openAuth } from '@rcf/webview-interfaces';
import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { agentsApi, agentsSlice } from '@entities/agents';
import { CSIAgentReview } from '@entities/csi';
import { ReviewEventTypes } from '@entities/reviews';
import { userSlice } from '@entities/user';
import { PATH } from '@shared/config/constants';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppDispatch } from '@shared/lib/hooks/useAppDispatch';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import { webViewAuth } from '@shared/lib/webViewAuth';
import { URLParams } from '../config/urlParams';
const AgentReviewCreate = ({ textButton, fluidButton, sizeButton, intentButton = Intent.Secondary }) => {
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const agentCasId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agents) === null || _a === void 0 ? void 0 : _a.agent.details) === null || _b === void 0 ? void 0 : _b.casId; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const userCasId = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casId; });
    const userCasIdSigned = useAppSelector(state => { var _a; return (_a = state.user) === null || _a === void 0 ? void 0 : _a.casIdSigned; });
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const [mutateAgentReview, { data: agentReviewData, error: mutateError, isLoading, isError }] = agentsApi.useCreateAgentReviewMutation();
    const dispatch = useAppDispatch();
    const [alertList, setAlertList] = useState([]);
    const [isShowModal, setIsShowModal] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const agentReview = agentReviewData === null || agentReviewData === void 0 ? void 0 : agentReviewData.result;
    const currentError = mutateError && 'status' in mutateError ? mutateError.data.errors[0] : null;
    const hasQuerySurveyUID = searchParams.has(URLParams.SurveyId);
    const surveyUID = hasQuerySurveyUID ? searchParams.get(URLParams.SurveyId) : agentReview === null || agentReview === void 0 ? void 0 : agentReview.id;
    const updateCSI = async () => {
        try {
            if (agentCasId && (userCasId || userCasIdSigned)) {
                await mutateAgentReview({
                    agentCasId
                });
            }
            else if (isWebView && !userCasIdSigned) {
                openAuth(AuthType.STANDARD, PATH.AGENT);
                webViewAuth(casIdSigned => {
                    dispatch(userSlice.setCasIdSigned(casIdSigned));
                });
            }
            else {
                window.domclickGlobal.domclickLayout.showLoginForm();
            }
        }
        catch (_a) {
            dispatch(agentsSlice.resetAgentCreateReview());
        }
    };
    const removeQuerySurveyId = () => {
        searchParams.delete(URLParams.SurveyId);
        setSearchParams(searchParams);
    };
    const closeModal = () => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, false);
        }
        if (!SSR && (window === null || window === void 0 ? void 0 : window.__DOMCLICK_CSI_WIDGET_OPEN__)) {
            window.__DOMCLICK_CSI_WIDGET_OPEN__ = false;
        }
        setIsShowModal(false);
        dispatch(agentsSlice.resetAgentCreateReview());
    };
    const handleSubmit = () => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, false);
        }
        sendMetrics({
            eventType: ReviewEventTypes.ReviewAdded,
            elementId: AgentMetricHashes.SuccessResponseReview,
            eventSense: {
                agentCasId,
                regionId
            }
        });
        if (isError) {
            return;
        }
        if (agentReview || surveyUID) {
            dispatch(agentsSlice.finishAgentCreateReview({
                surveyUID: surveyUID,
                status: (agentReview === null || agentReview === void 0 ? void 0 : agentReview.status) || 'pending'
            }));
        }
        if (hasQuerySurveyUID) {
            removeQuerySurveyId();
        }
        setIsShowModal(false);
    };
    const handleCloseCSIWidget = () => {
        closeModal();
        if (hasQuerySurveyUID) {
            removeQuerySurveyId();
        }
    };
    const handleClickButton = () => {
        if (!SSR) {
            if (userCasId) {
                sendMetrics({
                    eventType: 'click',
                    elementId: AgentMetricHashes.ClickButtonAgentReviewCreate,
                    elementType: 'Button',
                    eventSense: {
                        agentCasId,
                        regionId
                    }
                });
            }
            updateCSI().then(() => {
                if (isWebView && userAgent && userCasIdSigned) {
                    setWebViewVisibilityTopBar(userAgent, true);
                }
                setIsShowModal(true);
            });
        }
    };
    useEffect(() => {
        if (isError && mutateError && !(currentError === null || currentError === void 0 ? void 0 : currentError.message)) {
            Sentry.captureException(JSON.stringify(mutateError));
        }
        if (isError && (currentError === null || currentError === void 0 ? void 0 : currentError.message) && userAgent) {
            setWebViewVisibilityTopBar(userAgent, false);
            setAlertList([{
                    id: '1',
                    showCloseIcon: true,
                    title: currentError.message,
                    duration: 4,
                    status: AlertStatus.ERROR
                }]);
        }
    }, [isError, mutateError]);
    useEffect(() => {
        if (hasQuerySurveyUID) {
            setIsShowModal(true);
        }
    }, [hasQuerySurveyUID]);
    useEffect(() => () => {
        closeModal();
    }, []);
    return _jsxs(Fragment, { children: [_jsx(Button, { fluid: fluidButton, loading: isLoading, intent: intentButton, size: sizeButton || Size.Medium, text: textButton || 'Написать отзыв', onClick: handleClickButton, "data-sentry-element": "Button", "data-sentry-source-file": "index.tsx" }), isShowModal && surveyUID ? _jsx(CSIAgentReview, { isOpen: isShowModal, onSubmit: handleSubmit, onFinish: handleCloseCSIWidget, surveyUID: surveyUID }) : null, _jsx(AlertList, { list: alertList, onChangeAlertList: setAlertList, "data-sentry-element": "AlertList", "data-sentry-source-file": "index.tsx" })] });
};
export default AgentReviewCreate;
