import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AvatarSize, AvatarIntent } from '@front-core/avatar';
import { GalleryViewOverlay } from '@front-core/gallery-view';
import { clsx } from 'clsx';
import { useState, Fragment } from 'react';
import { AgentMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { sendMetrics } from '@shared/lib/sendMetrics';
import { setWebViewVisibilityTopBar } from '@shared/lib/setWebViewVisibilityTopBar';
import LayoutAvatar from '@shared/ui/layout/avatar';
import styles from './agentInfoAvatar.scss';
const regExp = new RegExp(/[s|c](?:\d+|-)x(?:\d+|-)(?:q\d+)?/);
const AgentInfoAvatar = ({ name, agentCasId, isMobile, avatarUrl }) => {
    const isWebView = useAppSelector(state => { var _a; return (_a = state.ui) === null || _a === void 0 ? void 0 : _a.isWebView; });
    const userAgent = useAppSelector(state => { var _a, _b; return (_b = (_a = state.ui) === null || _a === void 0 ? void 0 : _a.userAgent) === null || _b === void 0 ? void 0 : _b.ua; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [isAvatarError, setIsAvatarError] = useState(false);
    const isAvatarAvailable = !isAvatarError && avatarUrl;
    const currentAvatar = avatarUrl === null || avatarUrl === void 0 ? void 0 : avatarUrl.replace(regExp, 's1920x1080q80');
    const avatarSlots = [{
            src: currentAvatar,
            title: ''
        }];
    const handleOpenGallery = () => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, true);
        }
        if (isAvatarAvailable) {
            setIsShowGallery(true);
            sendMetrics({
                eventType: 'click',
                elementId: AgentMetricHashes.ClickButtonAgentAvatar,
                elementType: 'button',
                eventSense: {
                    agentCasId,
                    regionId
                }
            });
        }
    };
    const handleCloseGallery = () => {
        if (isWebView && userAgent) {
            setWebViewVisibilityTopBar(userAgent, false);
        }
        setIsShowGallery(false);
    };
    const handleAvatarError = () => {
        setIsAvatarError(true);
    };
    return _jsxs(Fragment, { children: [_jsx("button", { className: clsx(styles.agentInfoAvatar, {
                    [styles.isImageEmpty]: isAvatarError || !avatarUrl
                }), onClick: handleOpenGallery, children: _jsx(LayoutAvatar, { name: name, size: isMobile ? AvatarSize.Large : AvatarSize.ExtraLarge, intent: AvatarIntent.Secondary, imageSrc: currentAvatar || undefined, onError: handleAvatarError, "data-sentry-element": "LayoutAvatar", "data-sentry-source-file": "index.tsx" }) }), isShowGallery && isAvatarAvailable ? _jsx(GalleryViewOverlay, { slots: avatarSlots, open: isShowGallery, onClose: handleCloseGallery, showCounter: false }) : null] });
};
export default AgentInfoAvatar;
