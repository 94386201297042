import { jsx as _jsx } from "react/jsx-runtime";
import { BottomSheet } from '@front-core/bottom-sheet';
import { Button, Intent, Size as ButtonSize } from '@front-core/button';
import { FormField, FormFieldIndent } from '@front-core/form-field';
import { Input, Size as InputSize, Type } from '@front-core/input';
import { HeadingSmall } from '@front-core/typography';
import { useState } from 'react';
import { AgencyMetricHashes } from '@shared/config/metrics';
import { useAppSelector } from '@shared/lib/hooks/useAppSelector';
import { isInvalidField } from '@shared/lib/isInvalidField';
import { sendMetrics } from '@shared/lib/sendMetrics';
const AgencyAgentsSearchModal = ({ isModalOpen, onClose, onSubmit, defaultValue }) => {
    const agencyId = useAppSelector(state => { var _a, _b; return (_b = (_a = state.agencies) === null || _a === void 0 ? void 0 : _a.agency.details) === null || _b === void 0 ? void 0 : _b.id; });
    const regionId = useAppSelector(state => { var _a; return (_a = state.geo) === null || _a === void 0 ? void 0 : _a.region.guid; });
    const [hasError, setHasError] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const handleClickCloseModal = () => {
        onClose();
    };
    const handleSubmit = () => {
        if (inputValue.length === 0) {
            setHasError(true);
            return;
        }
        onSubmit(inputValue);
        onClose();
    };
    const handleChangeInput = (value) => {
        setHasError(value.length === 0);
        setInputValue(value);
    };
    const handleFocusInput = () => {
        sendMetrics({
            eventType: 'focus',
            elementId: AgencyMetricHashes.ClickInputSearchName,
            elementType: 'Input',
            eventSense: {
                agencyId,
                regionId
            }
        });
    };
    return _jsx(BottomSheet, { header: _jsx(HeadingSmall, { tag: "h3", children: "\u041F\u043E\u0438\u0441\u043A \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430" }), footerButtons: [_jsx(Button, { disabled: hasError, text: "\u041F\u043E\u043A\u0430\u0437\u0430\u0442\u044C \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u043E\u0432", size: ButtonSize.Large, intent: Intent.Primary, onClick: handleSubmit, fluid: true }, "showAgents")], open: isModalOpen, onClose: handleClickCloseModal, "data-sentry-element": "BottomSheet", "data-sentry-component": "AgencyAgentsSearchModal", "data-sentry-source-file": "index.tsx", children: _jsx(FormField, { errorMessage: "\u0423\u043A\u0430\u0436\u0438\u0442\u0435 \u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", validity: isInvalidField(hasError), indent: FormFieldIndent.None, "data-sentry-element": "FormField", "data-sentry-source-file": "index.tsx", children: _jsx(Input, { fluid: true, showClearIcon: true, label: "\u0424\u0418\u041E \u0440\u0438\u0435\u043B\u0442\u043E\u0440\u0430", size: InputSize.Large, type: Type.Search, defaultValue: defaultValue, value: inputValue, onFocus: handleFocusInput, validity: isInvalidField(hasError), onValueChange: handleChangeInput, "data-sentry-element": "Input", "data-sentry-source-file": "index.tsx" }) }) });
};
export default AgencyAgentsSearchModal;
